export const getDataset = (dataParam) => {
    if(dataParam !== undefined && dataParam.aggregations !== undefined && dataParam.aggregations.date !== undefined && dataParam.aggregations.date.buckets !== undefined){

        const colors = ["#2196f3", "#FFDE03", "#48b174", "#bc5038", "#000000", "#e876fe", "#4ed7fe", "#fe9700"]
        const buckets = dataParam.aggregations.date.buckets

        let datasets = []
        let dates = []
        let maxValue = 0
        buckets.forEach(it => {
            const date = new Date(it.key_as_string)
            dates.push(date)


            const services = it.services
            services.buckets.forEach(service => {
                const key = service.key.replace(".fastpoint.media", "")
                let associatedDataset = datasets.find(dataset => { return dataset.label === key})
                if(associatedDataset === undefined){
                    associatedDataset = {
                        backgroundColor: colors[datasets.length],
                        label: key,
                        data: [],
                        categoryPercentage: 1.0,
                        barPercentage: 0.90,
                    }
                    datasets.push(associatedDataset)
                }
                associatedDataset.data.push({
                    x: date,
                    y: service.doc_count
                })
            })
        })

        return {
            datasets: datasets,
            dates: dates,
            maxValue: maxValue
        }
    }

    return {
        error: "invalid input to generate dataset"
    }
}
