import React, {Component} from "react"
import propTypes from "prop-types"
import {InfiniteScroll} from 'react-simple-infinite-scroll';
import {getMeasures} from "../../requests/elastic_search/GetAllMeasures";
import classes from "./MeasuresList.module.css"
import {secToMin} from "../../helpers/DateHelper";

import i18n from "../../i18n"
import {Trans} from "react-i18next"
import {getPathString} from "../../helpers/FactoriesHelper";

class MeasuresList extends Component {

    state = {
        measures: [],
        numberOfItemsPerPage: 1000,
        isLoading: true,
        totalCount: 0,
        cursor: 0,
        hasMore: false,

        allMeasures: []
    }

    async componentDidMount() {
        await this.loadMore()
    }

    async componentDidUpdate(prevProps, prevState, snapshot) {
        if(prevProps.startDate !== this.props.startDate || prevProps.endDate !== this.props.endDate || prevProps.selectedAreaPath !== this.props.selectedAreaPath || this.props.factories !== prevProps.factories){
            this.setState({
                measures: [],
                numberOfItemsPerPage: 200,
                isLoading: true,
                totalCount: 0,
                cursor: 0,
                hasMore: false,
            }, async() => {
                await this.loadMore()
            })
        }
    }

    loadMore = async() => {
        let deviceSerial = undefined;
        let modacMacAddr = undefined;
        if(this.props.selectedArea !== undefined && this.props.selectedArea.type === "modac"){
            modacMacAddr =this.props.selectedArea.macaddr
        }else if(this.props.selectedArea !== undefined && this.props.selectedArea.mediaspot !== undefined){
            deviceSerial = this.props.selectedArea.mediaspot.serial
        }


        this.setState({isLoading: true }, async() => {
            const measuresResponse = await getMeasures(this.state.cursor, this.state.numberOfItemsPerPage, this.props.startDate, this.props.endDate, deviceSerial, modacMacAddr, this.props.selectedAreaPath)
            if(measuresResponse.error === undefined){
                this.setState({
                    allMeasures: this.state.allMeasures.concat(measuresResponse.data.measures).sort((a,b) => {
                        return Date.parse(a["logdate"]) <= Date.parse(b["logdate"])
                    }),
                }, () => {
                    let measures = []

                    const modacs = {}
                    for (let i = 0 ; i < this.state.allMeasures.length ; i++) {
                        const key = this.state.allMeasures[i].modac_macaddr
                        if(modacs[key] === undefined){
                            modacs[key] = []
                        }
                        modacs[key].unshift(this.state.allMeasures[i])
                    }

                    Object.keys(modacs).forEach(modacMacAddr => {
                        let valuesInCurrentMeasures = []

                        for (let i = 0 ; i < modacs[modacMacAddr].length ; i++) {

                            // If no values in array, add first measure value to current measures array
                            if(valuesInCurrentMeasures.length === 0 && modacs[modacMacAddr][i].p1_measure_status.toString() === "2"){
                                valuesInCurrentMeasures.push(modacs[modacMacAddr][i])
                            }

                            // Measure in progress = 1 => add measure info to current measure values
                            if(valuesInCurrentMeasures.length > 0 && modacs[modacMacAddr][i].p1_measure_status.toString() === "1"){
                                valuesInCurrentMeasures.push(modacs[modacMacAddr][i])
                            }


                            if(valuesInCurrentMeasures.length > 0 && modacs[modacMacAddr][i].p1_measure_status.toString() === "3"){
                                valuesInCurrentMeasures.push(modacs[modacMacAddr][i])
                                // Compute average, max here
                                const newMeasure = {...modacs[modacMacAddr][i]}
                                let values = valuesInCurrentMeasures.map(it => {
                                    return it.p1_ch1_smooth
                                })
                                if(values.length > 0){
                                    const averageMeasure = values.reduce((accumulator, currentValue) => parseFloat(accumulator) + parseFloat(currentValue)) / values.length
                                    const maxMeasure = Math.max.apply(Math, values.map(Number))

                                    const hasLowAlarm = valuesInCurrentMeasures.find(it => it.p1_ch1_alarm === "LOW") !== undefined
                                    const hasHighAlarm = valuesInCurrentMeasures.find(it => it.p1_ch1_alarm === "HIGH") !== undefined
                                    const hasSaturateAlarm = valuesInCurrentMeasures.find(it => it.p1_ch1_alarm === "SATURATE") !== undefined

                                    newMeasure["average_measure"] = averageMeasure
                                    newMeasure["has_low_alarm"] = hasLowAlarm
                                    newMeasure["has_high_alarm"] = hasHighAlarm
                                    newMeasure["has_saturate_alarm"] = hasSaturateAlarm
                                    newMeasure["max_measure"] = maxMeasure

                                    measures.push(newMeasure)
                                }
                                valuesInCurrentMeasures = []
                            }
                        }
                    })

                    this.setState({
                        isLoading: false,
                        measures: measures.sort((a,b) => {
                            return ((Date.parse(a["logdate"]) <= Date.parse(b["logdate"])) ? 1 : -1)
                        }),
                        totalCount: measuresResponse.data.totalCount,
                        hasMore: this.state.cursor < measuresResponse.data.totalCount,
                        cursor: this.state.cursor += this.state.numberOfItemsPerPage,
                    })

                })


            }

        })
    }


    render(){
        if(this.props.factories === undefined){
            return <><Trans>Loading</Trans>...</>
        }
        return (
            <>

                <div className={classes.MeasuresListLegendContainer}>
                    <label>Légende</label>
                    <br />
                    <br />
                </div>
                <div className={classes.MeasuresListContainer}>

                    <label><span style={{fontSize: "23px", color: "rgba(255,246,0,0.70)"}}>■</span> <Trans>LowAlarm</Trans></label>
                    <label><span style={{fontSize: "23px", color: "rgba(255,153,0,0.60)"}}>■</span> <Trans>HighAlarm</Trans></label>
                    <label><span style={{fontSize: "23px", color: "rgba(255,3,0,0.60)"}}>■</span> <Trans>SaturateAlarm</Trans></label>
                    <InfiniteScroll
                        throttle={100}
                        threshold={300}
                        isLoading={this.state.isLoading}
                        hasMore={this.state.hasMore}
                        onLoadMore={() => this.loadMore()}>

                        <table className={classes.MeasuresListTable}>
                            <thead>
                            <tr className={classes.MeasuresListRowContainer}>
                                <th><Trans>SAS</Trans></th>
                                <th><Trans>ModacMacAddress</Trans></th>
                                <th><Trans>Date</Trans></th>
                                <th><Trans>ControlDuration</Trans></th>
                                <th><Trans>MaxMeasure</Trans></th>
                                <th><Trans>AverageMeasure</Trans></th>
                            </tr>
                            </thead>
                            <tbody>
                            {
                                this.state.measures.map(measure => {
                                    let background = "inherit"
                                    if(measure.has_low_alarm){
                                        background = "rgba(255,246,0,0.70)"
                                    }
                                    if(measure.has_high_alarm){
                                        background = "rgba(255,153,0,0.60)"
                                    }
                                    if(measure.has_saturate_alarm){
                                        background = "rgba(255,3,0,0.60)"
                                    }
                                    return (
                                        <tr style={{backgroundColor: background}} key={measure} className={classes.MeasuresListRowContainer}>
                                            <td>{getPathString(this.props.factories, measure.modac_path, this.props.selectedAreaPath, false)}</td>
                                            <td>{measure.modac_macaddr}</td>
                                            <td>{new Date(measure.logdate).toLocaleString(i18n.language)}</td>
                                            <td style={{textAlign: "right"}}>{secToMin(measure.p1_measure_duration)}</td>
                                            <td>{new Intl.NumberFormat(i18n.language).format(measure.max_measure.toFixed(3))}</td>
                                            <td>{new Intl.NumberFormat(i18n.language).format(measure.average_measure.toFixed(3))}</td>
                                        </tr>
                                    )
                                })
                            }
                            </tbody>
                        </table>
                    </InfiniteScroll>
                </div>
            </>

        )
    }
}

MeasuresList.propTypes = {
    startDate: propTypes.number,
    endDate: propTypes.number,
    deviceSerial: propTypes.string,
    modacMacAddr: propTypes.string,
    factories: propTypes.object
}


export default MeasuresList
