import { getClientUUID } from "../helpers/AuthHelper";
import { postRequest } from "./Request";


/***
 * Add new mediaspot to database
 * @param mediaspot mediaspot object to add on database
 */
 export const addMediaspot = async(mediaspot) => {
    return await postRequest("addmediaspot", {
        clientUUID: getClientUUID(),
        dest_node: mediaspot.gsi1sk.split("|")[mediaspot.gsi1sk.split("|").length - 1],
        serial: mediaspot.serial,
        brand: mediaspot.brand,
        model: mediaspot.model,
        log_type: mediaspot.log_type,
        reference: mediaspot.reference,
        type: mediaspot.type,
        HDDinGB: mediaspot.HDDinGB,
        memoryInGB: mediaspot.memoryInGB,
        buildVersion: mediaspot.buildVersion
    })
}




/***
 * Edit existing mediaspot on database
 * @param mediaspot mediaspot object to edit on database
 */
 export const editMediaspot = async(mediaspot) => {
    return await postRequest("editmediaspot", {
        id: mediaspot.pk.replace("mediaspot-", "").replace("|0", ""),
        clientUUID: getClientUUID(),
        dest_node: mediaspot.gsi1sk.split("|")[mediaspot.gsi1sk.split("|").length - 1],
        serial: mediaspot.serial,
        brand: mediaspot.brand,
        model: mediaspot.model,
        log_type: mediaspot.log_type,
        reference: mediaspot.reference,
        searchRef: mediaspot.searchRef,
        status: mediaspot.status,
        type: mediaspot.type,
        HDDinGB: mediaspot.HDDinGB,
        memoryInGB: mediaspot.memoryInGB,
        buildVersion: mediaspot.buildVersion
    })
}




/***
 * Add new modac to database
 * @param modac modac object to add on database
 */
 export const addModac = async(modac) => {
    return await postRequest("addmodac", {
        clientUUID: getClientUUID(),
        dest_node: modac.gsi1sk.split("|")[modac.gsi1sk.split("|").length - 1],
        serial: modac.serial,
        log_type: modac.log_type,
        reference: modac.reference,
        macaddr: modac.macaddr,
        p1_ch1_radiation_type: modac.configSet.p1.ch1.expectedRadiationType,
        p1_ch2_radiation_type: modac.configSet.p1.ch2.expectedRadiationType,
        p2_ch1_radiation_type: modac.configSet.p2.ch1.expectedRadiationType,
        p2_ch2_radiation_type: modac.configSet.p2.ch2.expectedRadiationType,
        p1_mustBeAvailable: modac.configSet.p1.mustBeAvailable,
        p2_mustBeAvailable: modac.configSet.p2.mustBeAvailable,
        mustAviorBeOnline: modac.configSet.mustAviorBeOnline,
        
    })
}

/***
 * Edit existing modac to database
 * @param modac modac object to update on database
 */
 export const editModac = async(modac) => {
    return await postRequest("editmodac", {
        id: modac.pk.replace("modac-", "").replace("|0", ""),
        clientUUID: getClientUUID(),
        dest_node: modac.gsi1sk.split("|")[modac.gsi1sk.split("|").length - 1],
        serial: modac.serial,
        log_type: modac.log_type,
        reference: modac.reference,
        searchRef: modac.searchRef,
        macaddr: modac.macaddr,
        p1_ch1_radiation_type: modac.configSet.p1.ch1.expectedRadiationType,
        p1_ch2_radiation_type: modac.configSet.p1.ch2.expectedRadiationType,
        p2_ch1_radiation_type: modac.configSet.p2.ch1.expectedRadiationType,
        p2_ch2_radiation_type: modac.configSet.p2.ch2.expectedRadiationType,
        p1_mustBeAvailable: modac.configSet.p1.mustBeAvailable,
        p2_mustBeAvailable: modac.configSet.p2.mustBeAvailable,
        mustAviorBeOnline: modac.configSet.mustAviorBeOnline,
        
    })
}



/***
 * Add new radiameter to database
 * @param radiameter radiameter object to add on database
 */
 export const addRadiameter = async(radiameter) => {
    return await postRequest("addradiameter", {
        clientUUID: getClientUUID(),
        dest_node: radiameter.gsi1sk.split("|")[radiameter.gsi1sk.split("|").length - 1],
        serial: radiameter.serial,
        log_type: radiameter.log_type,
        reference: radiameter.reference,
        brand: radiameter.brand,
        maxChannelPerProbe: radiameter.maxChannelPerProbe,
        maxNbProbes: radiameter.maxNbProbes,
        model: radiameter.model,
        type: radiameter.type,
    })
}

/***
 * Edit exising radiameter to database
 * @param radiameter radiameter object to update on database
 */
 export const editRadiameter = async(radiameter) => {
    return await postRequest("editradiameter", {
        clientUUID: getClientUUID(),
        id: radiameter.pk.replace("radiameter-", "").replace("|0", ""),
        dest_node: radiameter.gsi1sk.split("|")[radiameter.gsi1sk.split("|").length - 1],
        serial: radiameter.serial,
        log_type: radiameter.log_type,
        reference: radiameter.reference,
        searchRef: radiameter.searchRef,
        brand: radiameter.brand,
        maxChannelPerProbe: radiameter.maxChannelPerProbe,
        maxNbProbes: radiameter.maxNbProbes,
        model: radiameter.model,
        type: radiameter.type,
    })
}


/***
 * Add new probe to database
 * @param probe radiameter object to add on database
 */
 export const addProbe = async(probe) => {
    return await postRequest("addprobe", {
        clientUUID: getClientUUID(),
        dest_node: probe.gsi1sk.split("|")[probe.gsi1sk.split("|").length - 1],
        serial: probe.serial,
        log_type: probe.log_type,
        reference: probe.reference,
        brand: probe.brand,
        model: probe.model,
        type: probe.type,
        maxNbChannels: probe.maxNbChannels,
    })
}

/***
 * Edit existing probe on database
 * @param probe radiameter object to edit on database
 */
 export const editProbe = async(probe) => {
    return await postRequest("editprobe", {
        clientUUID: getClientUUID(),
        dest_node: probe.gsi1sk.split("|")[probe.gsi1sk.split("|").length - 1],
        serial: probe.serial,
        log_type: probe.log_type,
        reference: probe.reference,
        searchRef: probe.searchRef,
        brand: probe.brand,
        model: probe.model,
        type: probe.type,
        maxNbChannels: probe.maxNbChannels,
    })
}