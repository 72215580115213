export const getDataset = (dataParam) => {
    if(dataParam !== undefined && dataParam.data !== undefined && dataParam.data.aggregations !== undefined && dataParam.data.aggregations.date !== undefined && dataParam.data.aggregations.date.buckets !== undefined){

        const buckets = dataParam.data.aggregations.date.buckets
        const dataset = {
            borderColor: "rgba(28,95,220,0.63)",
            backgroundColor: "rgba(220,4,151,0)",
            label: "RSSI (dBm)",
            data: []
        };

        let dates = []
        buckets.forEach(it => {
            if(it.key_as_string !== undefined && it.rssi !== undefined && it.rssi.value !== undefined){
                const date = new Date(it.key_as_string)
                dates.push(date)
                dataset.data.push({
                    x: date,
                    y: Math.round((it.rssi.value + Number.EPSILON) * 100) / 100

                })
            }
        })

        return {
            datasets: [dataset],
            dates: dates,
        }
    }
    return {
        error: "invalid input to generate dataset"
    }
}
