import timeGraphZoomDefaultProps from "./TimeGraphZoomDefaultProps";
import xAxeTimeDefaultProps from "./XAxeTimeDefaultProps";
import "chartjs-plugin-annotation"

export const graphProps = (dates, interval, startDatePeriodTimestamp, endDatePeriodTimestamp) => {
    let minDate = undefined;
    let maxDate = undefined;
    if(dates !== undefined && dates.length > 0 && dates[0].getTime() !== undefined){
        minDate = dates[0].getTime();
        maxDate = dates[dates.length-1].getTime();
    }

    return {
        type: 'line',
        data: {},
        labels: dates,
        options: {
            plugins:{
                zoom: false
            },
            responsive: true,
            maintainAspectRatio: false,
            legend: {
                display: false
            },
            // pan: {
            //     enabled: true,
            //     mode: 'x',
            // },
            zoom: timeGraphZoomDefaultProps(maxDate, interval),
            scales: {
                xAxes: [
                    xAxeTimeDefaultProps(minDate, maxDate, interval, startDatePeriodTimestamp, endDatePeriodTimestamp)
                ],
                yAxes: [{
                    ticks: {
                        min: 0
                    }
                }]
            },
            // annotation: {
            //     events: ["click"],
            //     annotations: [
            //         {
            //             drawTime: "afterDatasetsDraw",
            //             id: "hline_high",
            //             type: "line",
            //             mode: "horizontal",
            //             scaleID: "y-axis-0",
            //             value: 20,
            //             borderColor: "red",
            //             borderWidth: 1
            //         }
            //     ]
            // },
        }
    }
}
