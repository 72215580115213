export const getDataset = (dataParam) => {
    if(dataParam !== undefined && dataParam.aggregations !== undefined && dataParam.aggregations.date !== undefined && dataParam.aggregations.date.buckets){
        const buckets = dataParam.aggregations.date.buckets
        const dataset = {
            backgroundColor: "rgba(255,220,3,0.31)",
            borderColor: "rgb(255,222,3)",
            label: "Uptime (sec)",
            data: [],
            lineTension: 0,
        }

        let dates = []
        buckets.forEach(it => {
            if(it.key_as_string !== undefined && it.uptime !== undefined && it.uptime.value !== undefined){
                const date = new Date(it.key_as_string)
                dates.push(date)

                dataset.data.push({
                    x: date,
                    y: it.uptime.value.toFixed(0)
                })
            }
        })

        return {
            datasets: [dataset],
            dates: dates
        }
    }
    return {
        error: "invalid input to generate dataset"
    }
}
