export const graphProps = (labels) => {
    return {
        type: "pie",
        labels: labels,
        data: {},
        responsive: true,
        options: {
            responsive: true,
            maintainAspectRatio: false,
            tooltips: {
                callbacks: {
                    title: function(tooltipItem, data) {
                        if(data !== undefined && data["labels"] !== undefined && tooltipItem !== undefined && tooltipItem.length > 0 && tooltipItem[0]['index'] !== undefined && data['labels'][tooltipItem[0]['index']] !== undefined){
                            return data['labels'][tooltipItem[0]['index']];
                        }
                        return ""
                    },
                    label: function(tooltipItem, data) {
                        if(data !== undefined && data["datasets"] !== undefined && data["datasets"].length > 0 && data['datasets'][0]['data'] !== undefined && tooltipItem !== undefined && tooltipItem['index'] !== undefined && data['datasets'][0]['data'][tooltipItem['index']] !== undefined){
                            return data['datasets'][0]['data'][tooltipItem['index']]+'%';
                        }
                        return ""
                    }
                }
            }
        }
    }
}
