import xAxeTimeDefaultProps from "./XAxeTimeDefaultProps";

export const graphProps = (dates, interval, maxValue, startDate, endDate) => {
    let minDate = undefined;
    let maxDate = undefined;
    if(dates !== undefined && dates.length > 0 && dates[0].getTime() !== undefined){
        minDate = dates[0].getTime();
        maxDate = dates[dates.length-1].getTime();
    }

    const xAxeTimeDefaultPropsObj = xAxeTimeDefaultProps(minDate, maxDate, interval, startDate, endDate)
    xAxeTimeDefaultPropsObj.stacked= false

    return {
        type: "bar",
        labels: dates,
        data: {},
        responsive: true,
        options: {
            plugins:{
                zoom: false
            },
            responsive: true,
            maintainAspectRatio: false,
            scales: {
                xAxes: [
                    xAxeTimeDefaultPropsObj
                ],
                yAxes: [{
                    ticks: {
                        min: 0,
                    },
                    stacked: false
                }]
            }
        }
    }
}
