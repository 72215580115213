import nucleotApi, {aviorDefaultElasticSearchHeader} from "../axios";
import {config} from "../../config";
import {adaptQuery} from "../../helpers/elasticSearchQueryHelper";

const evolutionOfModacAvailability = (interval, optionalStartDateTimestamp, optionalEndDateTimestamp, modacMacAddr, deviceSerial, nodePath) => {
    let body = {
        "aggs": {
            "date": {
                "date_histogram": {
                    "field": "logdate",
                    "interval": "1h",
                    "time_zone": "Europe/Berlin",
                    "min_doc_count": 1
                },
                "aggs": {
                    "availability": {
                        "filters": {
                            "filters": {
                                "available": {
                                    "query_string": {
                                        "query": "modac_rs485_status:1",
                                        "analyze_wildcard": true,
                                        "default_field": "*"

                                    }
                                },
                                "not_available": {
                                    "query_string": {
                                        "query": "modac_rs485_status:0",
                                        "analyze_wildcard": true,
                                        "default_field": "*"

                                    }
                                }
                            }
                        }
                    }
                }
            }
        },
        "size": 0,
        "_source": {
            "excludes": []
        },
        "stored_fields": [
            "*"
        ],
        "query": {
            "bool": {
                "must": []
            }
        }
    }
    adaptQuery(body, interval, optionalStartDateTimestamp, optionalEndDateTimestamp, nodePath, modacMacAddr, deviceSerial)

    return body;
}

export const getEvolutionOfModacAvailability = async(interval, optionalStartDateTimestamp, optionalEndDateTimestamp, modacMacAddr, deviceSerial, nodePath) => {
    if(modacMacAddr === undefined){
        return {
            error: "modac mac address is undefined"
        }
    }
    try{
        const query = evolutionOfModacAvailability(interval, optionalStartDateTimestamp, optionalEndDateTimestamp, modacMacAddr, deviceSerial, nodePath)
        const response = await nucleotApi.post(config.API_URL + config.ELASTIC_SEARCH_ENDPOINT_PATH, query, {
            headers: aviorDefaultElasticSearchHeader
        })
        return response.data
    } catch (e) {
        return {
            error: e
        }
    }
}
