import React, {Component} from "react"

import classes from "./DashboardDevices.module.css"
import Graph, {GraphType} from "../graphs/GraphComponent";

import Barcode from "react-barcode"
import {getDeviceInfos} from "../../requests/elastic_search/GetDeviceInfos";
import Loader from "react-loader-spinner";
import {secToText} from "../../helpers/DateHelper";
import Card, {CardProps} from "../card/Card";
import {getMediaspotInfos} from "../../requests/elastic_search/GetMediaspotInfo";
import i18n from "../../i18n"
import {Trans} from "react-i18next";

class DashboardDevices extends Component {

    state = {

        isProbe1Expanded: false,
        isProbe2Expanded: false,

        areSasInfosLoaded: false,
        areDeviceInfosLoaded: false,
        deviceInfos: undefined,

        numberOfModacs:0
    }

    async componentDidMount() {
        const modacCount = await this.updateModacsCount()
        this.setState({numberOfModacs: modacCount})

        if(this.props.selectedArea !== undefined){
            if(this.props.selectedArea.type === "modac"){
                this.getModacInfos(this.props.selectedArea)
            }

            if(this.props.selectedArea.mediaspot !== undefined){
                this.getSasMediaspotInfos(this.props.selectedArea)
            }
        }

    }

    async componentDidUpdate(prevProps, prevState, snapshot) {

        if(this.props.selectedArea !== prevProps.selectedArea){
            this.setState({areSasInfosLoaded: false, areDeviceInfosLoaded: false})
            if(this.props.selectedArea.type === 'modac'){
                this.getModacInfos(this.props.selectedArea)
            }else if(this.props.selectedArea.mediaspot !== undefined){
                this.getSasMediaspotInfos(this.props.selectedArea)
            }

        }

        if(this.props.selectedAreaPath !== prevProps.selectedAreaPath && this.props.selectedArea.type !== 'modac'){
            const modacCount = await this.updateModacsCount()
            this.setState({numberOfModacs: modacCount})

        }
    }

    getSasMediaspotInfos = (mediaspotNode) => {
        this.setState({selectedSas: mediaspotNode, areSasInfosLoaded: false}, async() => {
            const mediaspotInfos = await getMediaspotInfos(mediaspotNode.mediaspot.serial, this.props.selectedAreaPath)
            if(mediaspotInfos.data !== undefined){
                this.setState({areSasInfosLoaded: true, deviceInfos: mediaspotInfos.data._source})
            }
        })
    }

    getModacInfos = (selectedDeviceNode) => {
        this.setState({selectedDevice: selectedDeviceNode, areDeviceInfosLoaded : false}, async() => {
            const deviceInfos = await getDeviceInfos(selectedDeviceNode.macaddr, this.props.selectedAreaPath)
            if(deviceInfos.data !== undefined){
                this.setState({areDeviceInfosLoaded: true, deviceInfos: deviceInfos.data._source})
            }
        })
    }

    getAllModacs(obj, stack) {
        let numberOfModacs = 0
        for (let property in obj) {
            if (obj.hasOwnProperty(property)) {
                if(Array.isArray(obj[property]) && property === 'modacs'){
                    numberOfModacs += obj[property].length
                }
                if (typeof obj[property] == "object") {
                    numberOfModacs += this.getAllModacs(obj[property], stack + '.' + property);
                }
            }
        }
        return numberOfModacs;
    }



    updateModacsCount = async() => {
        return this.getAllModacs(this.props.selectedArea, '')
    }


    render(){

        let deviceSerial = undefined;
        let modacMacAddr = undefined;
        if(this.props.selectedArea !== undefined && this.props.selectedArea.type === "modac"){
            modacMacAddr =this.props.selectedArea.macaddr
        }else if(this.props.selectedArea !== undefined && this.props.selectedArea.mediaspot !== undefined){
            deviceSerial = this.props.selectedArea.mediaspot.serial
        }

        return (
            <div className={classes.DashboardDevicesContainer}>
                {/* Display number of modac only when no modac selected */}
                {this.props.selectedArea !== undefined && this.props.selectedArea.type !== 'modac' ?
                    <>
                        <Card nodePath={this.props.selectedAreaPath} style={{width: "350px", margin: "15px", display: "inline-block"}} title={i18n.t("NumberOfModacs")} value={this.state.numberOfModacs} backgroundColor={"#1F9BD9"} interval={this.state.interval} startDatePeriodTimestamp={this.state.startDatePeriodTimestamp} endDatePeriodTimestamp={this.state.endDatePeriodTimestamp}/>
                        <Card nodePath={this.props.selectedAreaPath} style={{width: "350px", margin: "15px", display: "inline-block"}} infoType={CardProps.ACTIVE_MODACS_COUNT} deviceSerial={deviceSerial} startDatePeriodTimestamp={this.props.startDatePeriodTimestamp} endDatePeriodTimestamp={this.props.endDatePeriodTimestamp} />
                    </> : undefined
                }

                {this.props.selectedArea !== undefined && this.props.selectedArea.type === 'modac' ?
                    <Card style={{width: "350px", margin: "15px", display: "inline-block"}}
                          infoType={CardProps.AVERAGE_MODAC_AVAILABILITY}
                          startDatePeriodTimestamp={this.props.startDatePeriodTimestamp}
                          endDatePeriodTimestamp={this.props.endDatePeriodTimestamp}
                          deviceSerial={deviceSerial}
                          nodePath={this.props.selectedAreaPath}
                          modacMacAddr={modacMacAddr}/>
                : undefined }


                {this.props.selectedArea !== undefined && (this.props.selectedArea.hasMediaspot === undefined || this.props.selectedArea.hasMediaspot === false) ? undefined :
                    (!this.state.areSasInfosLoaded)
                        ? <Loader type="Oval" color="#4185F4" height={100} width={100}/>
                        :
                        <>
                            {/* MEDIASPOT INFO */}
                            <br />
                            <div className={classes.DashboardDevicesGroupContainer}>

                                <label><Trans>MediaspotInfos</Trans></label>
                                <div className={classes.DashboardDevicesTitleSeparator}/>

                                <div className={classes.DashboardDevicesGroupContent}>
                                    {this.props.selectedArea.mediaspot.reference !== undefined ?
                                        <div className={classes.DashboardDevicesKeyValueContainer}>
                                            <label><Trans>Reference</Trans> :</label>
                                            <label style={{fontFamily: "Monaco"}}>{this.props.selectedArea.mediaspot.reference}</label>
                                        </div>
                                        : undefined}

                                    <div className={classes.DashboardDevicesKeyValueContainer}>
                                        <label><Trans>Serial</Trans></label>
                                        <label>{this.state.deviceInfos["DEVICE_SERIAL"]}</label>
                                    </div>
                                    <div className={classes.DashboardDevicesKeyValueContainer}>
                                        <label>BSSID</label>
                                        <label>{this.state.deviceInfos["modac_bssid"]}</label>
                                    </div>
                                    <div className={classes.DashboardDevicesKeyValueContainer} style={{display: "flow-root"}}>
                                        <label><Trans>HostIP</Trans></label>
                                        <label style={{fontFamily: "Monaco"}}>{this.state.deviceInfos.host !== undefined ? this.state.deviceInfos.host.ip.map(it => {
                                            return <span style={{display: "block", textAlign: "end"}}>{it}</span>
                                        }) : "-"}</label>
                                    </div>
                                    <div className={classes.DashboardDevicesKeyValueContainer}>
                                        <label><Trans>HostMac</Trans></label>
                                        <label style={{fontFamily: "Monaco"}}>{this.state.deviceInfos.host !== undefined ? this.state.deviceInfos.host.mac.map(it => {
                                            return <span style={{display: "block", textAlign: "end"}}>{it}</span>
                                        }) : "-"}</label>
                                    </div>
                                </div>
                            </div>
                        </>

                }

                {this.props.selectedArea !== undefined && this.props.selectedArea.type !== 'modac' ? undefined :

                    (!this.state.areDeviceInfosLoaded)
                        ?  <Loader type="Oval" color="#4185F4" height={100} width={100}/>
                    :
                    <>
                    {/* MODAC INFO */}

                        <div className={classes.DashboardDevicesGroupContainer} style={{width: "calc(100% - 15px)"}}>

                            <label><Trans>AviorIdentification</Trans></label>
                            <div className={classes.DashboardDevicesTitleSeparator}/>

                            <div className={classes.DashboardDevicesGroupContent}>
                                <div style={{textAlign: "center"}}>
                                    <Barcode height={30} value={"025298"}/>
                                </div>

                                <div className={classes.DashboardDevicesKeyValueContainer}>
                                    <label>AVIOR-2</label>
                                    <label>MD 25298</label>
                                </div>
                                <div className={classes.DashboardDevicesKeyValueContainer}>
                                    <label><Trans>LastCheck</Trans></label>
                                    <label>2020/03/31</label>
                                </div>

                                <div className={classes.DashboardDevicesKeyValueContainer}>
                                    <label><Trans>NextCheck</Trans></label>
                                    <label>2021/03/30</label>
                                </div>

                                <div className={classes.DashboardDevicesKeyValueContainer}>
                                    <label><Trans>ObservationID</Trans></label>
                                    <label>2020-42036</label>
                                </div>
                            </div>
                        </div>

                        {/* INFOS */}

                        <div className={classes.DashboardDevicesGroupContainer}>
                            <label><Trans>ModacInfos</Trans></label>
                            <div className={classes.DashboardDevicesTitleSeparator}/>


                            <div className={classes.DashboardDevicesGroupContent}>
                            {this.props.selectedArea.reference !== undefined ?
                                <div className={classes.DashboardDevicesKeyValueContainer}>
                                    <label><Trans>Reference</Trans> :</label>
                                    <label>{this.props.selectedArea.reference}</label>
                                </div>
                                : undefined}

                                <div className={classes.DashboardDevicesKeyValueContainer}>
                                    <label><Trans>SerialNumber</Trans> :</label>
                                    <label>{this.state.deviceInfos.modac_serial}</label>
                                </div>
                                <div className={classes.DashboardDevicesKeyValueContainer}>
                                    <label><Trans>ModacBuildVersion</Trans></label>
                                    <label>{this.state.deviceInfos.modac_build}</label>
                                </div>

                                <div className={classes.DashboardDevicesKeyValueContainer}>
                                    <label>Uptime</label>
                                    <label>{secToText(this.state.deviceInfos.modac_uptime)}</label>
                                </div>
                                <br />
                                <label><Trans>FreeRam</Trans> (kB) :</label>
                                <br /><br />
                                <div>
                                    <Graph deviceSerial={deviceSerial} modacMacAddr={this.props.selectedArea.macaddr} graphType={GraphType.FREE_RAM_EVOLUTION} interval={this.props.interval} startDatePeriodTimestamp={this.props.startDatePeriodTimestamp} endDatePeriodTimestamp={this.props.endDatePeriodTimestamp}/>
                                </div>
                            </div>

                        </div>



                        {/* WIFI */}

                        <div className={classes.DashboardDevicesGroupContainer}>
                            <label>Wi-Fi</label>
                            <div className={classes.DashboardDevicesTitleSeparator}/>

                            <div className={classes.DashboardDevicesGroupContent}>
                                <div className={classes.DashboardDevicesKeyValueContainer}>
                                    <label><Trans>WifiFirmware</Trans></label>
                                    <label>{this.state.deviceInfos.modac_wifi_firmware}</label>
                                </div>

                                <div className={classes.DashboardDevicesKeyValueContainer}>
                                    <label>SSID</label>
                                    <label>{this.state.deviceInfos.modac_ssid}</label>
                                </div>

                                <div className={classes.DashboardDevicesKeyValueContainer}>
                                    <label><Trans>MediaspotBSSID</Trans></label>
                                    <label>{this.state.deviceInfos.modac_bssid}</label>
                                </div>

                                <div className={classes.DashboardDevicesKeyValueContainer}>
                                    <label><Trans>IPAddress</Trans></label>
                                    <label>{this.state.deviceInfos.modac_ip}</label>
                                </div>

                                {/*<div className={classes.DashboardDevicesKeyValueContainer}>*/}
                                    {/*<label>&nbsp;</label>*/}
                                    {/*<label>&nbsp;</label>*/}
                                {/*</div>*/}
                                <br />

                                <label>RSSI (dB) :</label>
                                <br /><br />
                                <div>
                                    <Graph deviceSerial={deviceSerial} modacMacAddr={this.props.selectedArea.macaddr} graphType={GraphType.MODAC_RSSI_EVOLUTION} interval={this.props.interval} startDatePeriodTimestamp={this.props.startDatePeriodTimestamp} endDatePeriodTimestamp={this.props.endDatePeriodTimestamp}/>
                                </div>
                            </div>



                        </div>


                        {/* Uptime */}

                        <div className={classes.DashboardDevicesGroupContainer}>
                            <label><Trans>ModacUptime</Trans></label>
                            <div className={classes.DashboardDevicesTitleSeparator}/>
                            <div className={classes.DashboardDevicesGroupContent}>
                                <Graph deviceSerial={deviceSerial} modacMacAddr={this.props.selectedArea.macaddr} graphType={GraphType.UPTIME_EVOLUTION} interval={this.props.interval} startDatePeriodTimestamp={this.props.startDatePeriodTimestamp} endDatePeriodTimestamp={this.props.endDatePeriodTimestamp}/>

                            </div>
                        </div>

                        {/* Availability */}

                        <div className={classes.DashboardDevicesGroupContainer}>
                            <label><Trans>CanberraAvailability</Trans></label>
                            <div className={classes.DashboardDevicesTitleSeparator}/>
                            <div className={classes.DashboardDevicesGroupContent}>
                                <Graph deviceSerial={deviceSerial} modacMacAddr={this.props.selectedArea.macaddr} graphType={GraphType.MODAC_AVAILABILITY_EVOLUTION} interval={this.props.interval} startDatePeriodTimestamp={this.props.startDatePeriodTimestamp} endDatePeriodTimestamp={this.props.endDatePeriodTimestamp}/>

                            </div>
                        </div>

                    </>

                }
            </div>
        )

    }
}
export default DashboardDevices
