import React, {Component} from "react";
import classes from "./Provisionning.module.css"
import {Trans} from "react-i18next"
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import { faPen, faPlus } from "@fortawesome/free-solid-svg-icons";
import Loader from "react-loader-spinner";
import Proptypes from "prop-types"
import { addModac, editModac } from "../../requests/DevicesRequests";
import { toast, ToastContainer } from "react-toastify";
import 'react-toastify/dist/ReactToastify.css';
import { getOrganization } from "../../requests/FactoriesRequests";
import { getAllStoreNodes } from "../../helpers/FactoriesHelper";

class ModacsProvisionning extends Component {

    
    state = {
        creationRequestInProgress: false,
        modac: this.props.device !== undefined ? this.props.device : {
            configSet: {
              mustAviorBeOnline: true,
              p1: {
                ch1: {
                    expectedRadiationType: "NONE",
                },
                ch2: {
                    expectedRadiationType: "NONE",
                },
                mustBeAvailable: false
              },
              p2: {
                ch1: {
                    expectedRadiationType: "NONE",
                },
                ch2: {
                    expectedRadiationType: "NONE",
                },
                mustBeAvailable: false
              }
            },
            device_type: "modac",
            gsi1sk: "",
            isDeployed: false,
            last_received_event: "",
            lastIndex: 0,
            log_type: "REALTIME",
            macaddr: "",
            parent_key: "none",
            pk: "",
            serial: "",
            sk: "",
            status: "IN_STOCK",
            statusUpdatedOn: ""
          },
        storeNodes: []
    }

    async componentDidMount(){
        const factoriesResponse = await getOrganization()
        const storeNodes = getAllStoreNodes(factoriesResponse[Object.keys(factoriesResponse)[0]], Object.keys(factoriesResponse)[0])
        await this.setState({storeNodes: storeNodes})
        if(this.state.modac.gsi1sk === "" && storeNodes && storeNodes.length > 0){
            const modacCopy = {...this.state.modac}
            modacCopy.gsi1sk = storeNodes[0].path
            this.setState({
                modac: modacCopy
            })        
        }
    }

    onCreateClick = async(event) => {
        event.preventDefault()

        await this.setState({creationRequestInProgress: true})
        let response = undefined
        if(this.props.device){
            response = await editModac(this.state.modac)
        }else {
            response = await addModac(this.state.modac)
        }

        await this.setState({creationRequestInProgress: false})
        if(response.error){
            toast(this.props.device ? "Erreur lors de l'édition du modac" : "Erreur lors de la création du modac", {
                type: "error"
            })
        }else {
            toast("Modac ajouté au référentiel", {
                type: "success"
            })
        }
        
    }

    onSerialChanged = (event) => {
        const value = event.target.value
        const modacCopy = {...this.state.modac}
        modacCopy.serial = value
        this.setState({
            modac: modacCopy
        })
    }

    

    onBrandChanged = (event) => {
        const value = event.target.value
        const modacCopy = {...this.state.modac}
        modacCopy.brand = value
        this.setState({
            modac: modacCopy
        })
    }
    
    onMacaddrChanged = (event) => {
        const value = event.target.value
        const modacCopy = {...this.state.modac}
        modacCopy.macaddr = value
        this.setState({
            modac: modacCopy
        })
    }

    onModelChanged = (event) => {
        const value = event.target.value
        const modacCopy = {...this.state.modac}
        modacCopy.model = value
        this.setState({
            modac: modacCopy
        })
    }

    onBuildVersionChanged = (event) => {
        const value = event.target.value
        const modacCopy = {...this.state.modac}
        modacCopy.buildVersion = value
        this.setState({
            modac: modacCopy
        })
    }

    onPathChanged = (event) => {
        const value = event.target.value
        const modacCopy = {...this.state.modac}
        modacCopy.gsi1sk = value
        this.setState({
            modac: modacCopy
        })
    }

    onLogtypeChanged = (event) => {
        const value = event.target.value
        const modacCopy = {...this.state.modac}
        modacCopy.log_type = value
        this.setState({
            modac: modacCopy
        })
    }

    onReferenceChanged = (event) => {
        const value = event.target.value
        const modacCopy = {...this.state.modac}
        modacCopy.reference = value
        this.setState({
            modac: modacCopy
        })
    }
    
    onSearchReferenceChanged = (event) => {
        const value = event.target.value
        const modacCopy = {...this.state.modac}
        modacCopy.searchRef = value
        this.setState({
            modac: modacCopy
        })
    }
    
    onMustAviorBeOnlineChanged = (event) => {
        const value = event.target.checked
        const modacCopy = {...this.state.modac}
        modacCopy.configSet.mustAviorBeOnline = value
        this.setState({
            modac: modacCopy
        })
    }

    onP1MustAvailableChanged = (event) => {
        const value = event.target.checked
        const modacCopy = {...this.state.modac}
        modacCopy.configSet.p1.mustBeAvailable = value
        this.setState({
            modac: modacCopy
        })
    }
    
    onP2MustAvailableChanged = (event) => {
        const value = event.target.checked
        const modacCopy = {...this.state.modac}
        modacCopy.configSet.p2.mustBeAvailable = value
        this.setState({
            modac: modacCopy
        })
    }


    onP1CH1RadiationTypeChanged = (event) => {
        const value = event.target.value
        const modacCopy = {...this.state.modac}
        modacCopy.configSet.p1.ch1.expectedRadiationType = value
        this.setState({
            modac: modacCopy
        })
    }
    
    onP1CH2RadiationTypeChanged = (event) => {
        const value = event.target.value
        const modacCopy = {...this.state.modac}
        modacCopy.configSet.p1.ch2.expectedRadiationType = value
        this.setState({
            modac: modacCopy
        })
    }
    
    onP2CH1RadiationTypeChanged = (event) => {
        const value = event.target.value
        const modacCopy = {...this.state.modac}
        modacCopy.configSet.p2.ch1.expectedRadiationType = value
        this.setState({
            modac: modacCopy
        })
    }
    
    onP2CH2RadiationTypeChanged = (event) => {
        const value = event.target.value
        const modacCopy = {...this.state.modac}
        modacCopy.configSet.p2.ch2.expectedRadiationType = value
        this.setState({
            modac: modacCopy
        })
    }

    render(){
        console.log(this.state.modac.configSet.mustAviorBeOnline)
        return (
            <div className={classes.ProvisionningContainer}>
                <h4>{this.props.device === undefined ? "Ajout d'un modac au référentiel" : "Édition d'un modac"}</h4>
                <form onSubmit={this.onCreateClick}>
                    <div style={{display: "flex", flexDirection: "column"}}>
                        <table className={classes.ProvisionningFieldsTable}>
                            <tr>
                                <td><label><Trans>Serial</Trans> : </label></td>
                                <td><input required onChange={this.onSerialChanged} value={this.state.modac.serial} type="text"/></td>
                            </tr>
                            
                            <tr>
                                <td><label><Trans>Macaddr</Trans> : </label></td>
                                <td><input required onChange={this.onMacaddrChanged} value={this.state.modac.macaddr} type="text"/></td>
                            </tr>
                            
                            <tr>
                                <td><label><Trans>Reference</Trans> : </label></td>
                                <td><input required onChange={this.onReferenceChanged} value={this.state.modac.reference} type="text"/></td>
                            </tr>

                            <tr>
                                <td><label><Trans>Must Avior be online</Trans> : </label></td>
                                <td><input type="checkbox" onChange={this.onMustAviorBeOnlineChanged} checked={this.state.modac.configSet.mustAviorBeOnline}/></td>
                            </tr>
                            

                            <tr>
                                <td><label><Trans>LogType</Trans> : </label></td>
                                <td><select onChange={this.onLogtypeChanged}>
                                    <option key="realtime" value="REALTIME">Realtime</option>
                                    <option key="offline" value="OFFLINE">Offline</option>
                                </select>
                                </td>
                            </tr>

                            {
                                this.props.device ? 
                                <>
                                    <tr>
                                        <td><label><Trans>Search reference</Trans> : </label></td>
                                        <td><input required onChange={this.onSearchReferenceChanged} value={this.state.modac.searchRef} type="text"/></td>
                                    </tr>
                                </>
                                : null
                            }

                            <tr>
                                <td><label><Trans>Path {this.props.device ? "" : "(réserve)"}</Trans> : </label></td>
                                <td><select onChange={this.onPathChanged}>
                                    {
                                        this.state.storeNodes.map(node => {
                                            return <option key={node.path} value={node.path}>{node.val}</option>
                                        })
                                    }
                                    
                                </select>
                                </td>
                            </tr>

                            <th>
                                <td colSpan={2}><h6>Sonde 1</h6></td>
                            </th>

                            <tr>
                                <td><label><Trans>Doit être branchée</Trans> : </label></td>
                                <td><input type="checkbox" onChange={this.onP1MustAvailableChanged} checked={this.state.modac.configSet.p1.mustBeAvailable}/></td>
                            </tr>
                            
                            <tr>
                                <td><label><Trans>Type de mesure sur channel 1</Trans> : </label></td>
                                <td><input type="text" onChange={this.onP1CH1RadiationTypeChanged} value={this.state.modac.configSet.p1.ch1.expectedRadiationType}/></td>
                            </tr>
                            
                            <tr>
                                <td><label><Trans>Type de mesure sur channel 2</Trans> : </label></td>
                                <td><input type="text" onChange={this.onP1CH2RadiationTypeChanged} value={this.state.modac.configSet.p1.ch2.expectedRadiationType}/></td>
                            </tr>


                            <th>
                                <td colSpan={2}><h6>Sonde 2</h6></td>
                            </th>

                            <tr>
                                <td><label><Trans>Doit être branchée</Trans> : </label></td>
                                <td><input type="checkbox" onChange={this.onP2MustAvailableChanged} checked={this.state.modac.configSet.p2.mustBeAvailable}/></td>
                            </tr>
                            
                            <tr>
                                <td><label><Trans>Type de mesure sur channel 1</Trans> : </label></td>
                                <td><input type="text" onChange={this.onP2CH1RadiationTypeChanged} value={this.state.modac.configSet.p2.ch1.expectedRadiationType}/></td>
                            </tr>
                            
                            <tr>
                                <td><label><Trans>Type de mesure sur channel 2</Trans> : </label></td>
                                <td><input type="text" onChange={this.onP2CH2RadiationTypeChanged} value={this.state.modac.configSet.p2.ch2.expectedRadiationType}/></td>
                            </tr>
                        </table>
                    </div>
                    <div style={{display: "flex", flexDirection: "column"}}>
                        {
                            this.state.creationRequestInProgress
                                ? <Loader type={"Oval"} width={25} height={25} color={"#0095ff"}/>
                                :<button className={this.props.device ? classes.ProvisionningEditButton : classes.ProvisionningValidateButton} type={"submit"}><FontAwesomeIcon icon={this.props.device ? faPen: faPlus}/> <Trans>{this.props.device ? "Edit" : "Create"}</Trans></button>}

                    </div>
                </form>
                <ToastContainer />
            </div>
        )
    }
}

ModacsProvisionning.PropType = {
    device: Proptypes.object
}

export default ModacsProvisionning