import React from "react"
//import classes from "./User.module.css"
import PropTypes from "prop-types"
import i18n from "../../../i18n"


const User = (props) => {
    return (
        <tr>
            <td><input onChange={(event => { event.target.checked === true ?  props.onSelect(props.user.id) : props.onUnselect(props.user.id)})} type={"checkbox"}/></td>
            <td>{props.user.lastname} {props.user.firstname}
                <br/>
                {props.user.email}
            </td>
            <td>{props.user.isAdmin === true ? i18n.t("Yes") : i18n.t("No")}</td>
            <td>-</td>
            <td>{props.user.lastSignedIn !== undefined ? props.user.lastSignedIn : "None"}</td>
            <td>View / Edit</td>
        </tr>
    )
}

User.propTypes = {
    user: PropTypes.any,
    onSelect: PropTypes.func,
    onUnselect: PropTypes.func
}

export default User;
