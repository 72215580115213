import React, {Component} from "react";
import classes from "./Provisionning.module.css"
import {Trans} from "react-i18next"
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import { faPen, faPlus } from "@fortawesome/free-solid-svg-icons";
import Loader from "react-loader-spinner";
import Proptypes from "prop-types"
import { addMediaspot, editMediaspot } from "../../requests/DevicesRequests";
import { toast, ToastContainer } from "react-toastify";
import 'react-toastify/dist/ReactToastify.css';
import { getOrganization } from "../../requests/FactoriesRequests";
import { getAllStoreNodes } from "../../helpers/FactoriesHelper";

class MediaspotProvisionning extends Component {

    
    state = {
        creationRequestInProgress: false,
        mediaspot: this.props.device !== undefined ? this.props.device : {
            lastWSContact: "",
            brand: "",
            serial: "",
            model: "",
            type: "",
            HDDinGB: 120,
            buildVersion: "build-nspINTEGRATED-2020-03-27.175",
            log_type: "REALTIME",
            gsi1sk: "",
            memoryInGB: 4,
            isDeployed: false,
            lastIndex: 0,
            pk: "",
            reference: "",
            searchRef: "",
            sk: "",
            status: "IN_STOCK",
            statusUpdatedOn: "",
            device_type: "mediaspot",
        },
        storeNodes: []
    }

    async componentDidMount(){
        const factoriesResponse = await getOrganization()
        const storeNodes = getAllStoreNodes(factoriesResponse[Object.keys(factoriesResponse)[0]], Object.keys(factoriesResponse)[0])
        await this.setState({storeNodes: storeNodes})
        if(this.state.mediaspot.gsi1sk === "" && storeNodes && storeNodes.length > 0){
            const mediaspotCopy = {...this.state.mediaspot}
            mediaspotCopy.gsi1sk = storeNodes[0].path
            this.setState({
                mediaspot: mediaspotCopy
            })        
        }
    }

    onCreateClick = async(event) => {
        event.preventDefault()

        await this.setState({creationRequestInProgress: true})
        let response = undefined
        if(this.props.device){
            response = await editMediaspot(this.state.mediaspot)
        }else {
            response = await addMediaspot(this.state.mediaspot)
        }

        await this.setState({creationRequestInProgress: false})
        if(response.error){
            toast(this.props.device ? "Erreur lors de l'édition du mediaspot" : "Erreur lors de la création du mediaspot", {
                type: "error"
            })
        }else {
            toast("Mediaspot ajouté au référentiel", {
                type: "success"
            })
        }
        
    }

    onSerialChanged = (event) => {
        const value = event.target.value
        const mediaspotCopy = {...this.state.mediaspot}
        mediaspotCopy.serial = value
        if(this.props.device === undefined){
            mediaspotCopy.reference = `mediaspot-${value}`
            mediaspotCopy.searchRef = `mediaspot-${value}`
        }
        this.setState({
            mediaspot: mediaspotCopy
        })
    }

    

    onBrandChanged = (event) => {
        const value = event.target.value
        const mediaspotCopy = {...this.state.mediaspot}
        mediaspotCopy.brand = value
        this.setState({
            mediaspot: mediaspotCopy
        })
    }

    onModelChanged = (event) => {
        const value = event.target.value
        const mediaspotCopy = {...this.state.mediaspot}
        mediaspotCopy.model = value
        this.setState({
            mediaspot: mediaspotCopy
        })
    }

    onBuildVersionChanged = (event) => {
        const value = event.target.value
        const mediaspotCopy = {...this.state.mediaspot}
        mediaspotCopy.buildVersion = value
        this.setState({
            mediaspot: mediaspotCopy
        })
    }

    onPathChanged = (event) => {
        const value = event.target.value
        const mediaspotCopy = {...this.state.mediaspot}
        mediaspotCopy.gsi1sk = value
        this.setState({
            mediaspot: mediaspotCopy
        })
    }

    onTypeChanged = (event) => {
        const value = event.target.value
        const mediaspotCopy = {...this.state.mediaspot}
        mediaspotCopy.type = value
        this.setState({
            mediaspot: mediaspotCopy
        })
    }

    onHDDinGBChanged = (event) => {
        const value = event.target.value
        const mediaspotCopy = {...this.state.mediaspot}
        mediaspotCopy.HDDinGB = Number.parseInt(value)
        this.setState({
            mediaspot: mediaspotCopy
        })
    }

    onMemoryinGBChanged = (event) => {
        const value = event.target.value
        const mediaspotCopy = {...this.state.mediaspot}
        mediaspotCopy.memoryInGB = Number.parseInt(value)
        this.setState({
            mediaspot: mediaspotCopy
        })
    }

    
    onLogtypeChanged = (event) => {
        const value = event.target.value
        const mediaspotCopy = {...this.state.mediaspot}
        mediaspotCopy.log_type = value
        this.setState({
            mediaspot: mediaspotCopy
        })
    }

    onReferenceChanged = (event) => {
        const value = event.target.value
        const mediaspotCopy = {...this.state.mediaspot}
        mediaspotCopy.reference = value
        this.setState({
            mediaspot: mediaspotCopy
        })
    }
    
    onSearchReferenceChanged = (event) => {
        const value = event.target.value
        const mediaspotCopy = {...this.state.mediaspot}
        mediaspotCopy.searchRef = value
        this.setState({
            mediaspot: mediaspotCopy
        })
    }

    render(){
        return (
            <div className={classes.ProvisionningContainer}>
                <h4>{this.props.device === undefined ? "Ajout d'un mediaspot au référentiel" : "Édition d'un mediaspot"}</h4>
                <form onSubmit={this.onCreateClick}>
                    <div style={{display: "flex", flexDirection: "column"}}>
                        <table className={classes.ProvisionningFieldsTable}>
                            <tr>
                                <td><label><Trans>Serial</Trans> : </label></td>
                                <td><input required onChange={this.onSerialChanged} value={this.state.mediaspot.serial} type="text"/></td>
                            </tr>

                            <tr>
                                <td><label><Trans>Brand</Trans> : </label></td>
                                <td><input required onChange={this.onBrandChanged} value={this.state.mediaspot.brand} type="text"/></td>
                            </tr>

                            <tr>
                                <td><label><Trans>Model</Trans> : </label></td>
                                <td><input required onChange={this.onModelChanged} value={this.state.mediaspot.model} type="text"/></td>
                            </tr>

                            <tr>
                                <td><label><Trans>Build version</Trans> : </label></td>
                                <td><input required onChange={this.onBuildVersionChanged} value={this.state.mediaspot.buildVersion} type="text"/></td>
                            </tr>
                            
                            <tr>
                                <td><label><Trans>Type</Trans> : </label></td>
                                <td><input required onChange={this.onTypeChanged} value={this.state.mediaspot.type} type="text"/></td>
                            </tr>
                            
                            <tr>
                                <td><label><Trans>Memory in GB</Trans> : </label></td>
                                <td><input required onChange={this.onMemoryinGBChanged} value={this.state.mediaspot.memoryInGB} type="number"/></td>
                            </tr>
                            <tr>
                                <td><label><Trans>HDD in GB</Trans> : </label></td>
                                <td><input required onChange={this.onHDDinGBChanged} value={this.state.mediaspot.HDDinGB} type="number"/></td>
                            </tr>
                            

                            <tr>
                                <td><label><Trans>LogType</Trans> : </label></td>
                                <td><select onChange={this.onLogtypeChanged}>
                                    <option key="realtime" value="REALTIME">Realtime</option>
                                    <option key="offline" value="OFFLINE">Offline</option>
                                </select>
                                </td>
                            </tr>

                            {
                                this.props.device ? 
                                <>
                                    <tr>
                                        <td><label><Trans>Reference</Trans> : </label></td>
                                        <td><input required onChange={this.onReferenceChanged} value={this.state.mediaspot.reference} type="text"/></td>
                                    </tr>
                                    
                                    <tr>
                                        <td><label><Trans>Search reference</Trans> : </label></td>
                                        <td><input required onChange={this.onSearchReferenceChanged} value={this.state.mediaspot.searchRef} type="text"/></td>
                                    </tr>
                                </>
                                : null
                            }

                            <tr>
                                <td><label><Trans>Path {this.props.device ? "" : "(réserve)"}</Trans> : </label></td>
                                {/* <td><input required onChange={this.onPathChanged} value={this.state.mediaspot.gsi1sk} type="text"/></td> */}
                                <td><select onChange={this.onPathChanged}>
                                    {
                                        this.state.storeNodes.map(node => {
                                            return <option key={node.path} value={node.path}>{node.val}</option>
                                        })
                                    }
                                    
                                </select>
                                </td>
                            </tr>
                        </table>
                    </div>
                    <div style={{display: "flex", flexDirection: "column"}}>
                        {
                            this.state.creationRequestInProgress
                                ? <Loader type={"Oval"} width={25} height={25} color={"#0095ff"}/>
                                :<button className={this.props.device ? classes.ProvisionningEditButton : classes.ProvisionningValidateButton} type={"submit"}><FontAwesomeIcon icon={this.props.device ? faPen: faPlus}/> <Trans>{this.props.device ? "Edit" : "Create"}</Trans></button>}

                    </div>
                </form>
                <ToastContainer />
            </div>
        )
    }
}

MediaspotProvisionning.PropType = {
    device: Proptypes.object
}

export default MediaspotProvisionning