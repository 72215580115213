import React, {Component} from "react"
import {getAviorsAvailabilityPerModacs} from "../../requests/elastic_search/GetAviorAvailabilityPerModac";
import {getModacReference, getPathString} from "../../helpers/FactoriesHelper";
import classes from "../Dashboard.module.css";
import OKIcon from "../../assets/img/ok.svg";
import {Trans} from "react-i18next";
import Loader from "react-loader-spinner";
import * as portalConfig from "../../portal_config"

class BadAviorsAvailability extends Component {

    state = {
        aviorsAvailabilityResponse: undefined,
        loadingInProgress: true
    }


    async componentDidMount() {
        await this.loadBadAviorsAvailability(false)

        setInterval(this.loadBadAviorsAvailability, portalConfig.SUPERVISION_REFRESH_TICK_MILLISECONDS)

    }

    async componentDidUpdate(prevProps, prevState, snapshot) {
        if(prevProps.nodePath !== this.props.nodePath || prevProps.startDatePeriodTimestamp !== this.props.startDatePeriodTimestamp || prevProps.endDatePeriodTimestamp !== this.props.endDatePeriodTimestamp){
            await this.loadBadAviorsAvailability(false)
        }
    }

    loadBadAviorsAvailability = async(isAutoRefresh = true) => {
        this.setState({loadingInProgress: !isAutoRefresh}, async() => {

            const response = await getAviorsAvailabilityPerModacs(this.props.startDatePeriodTimestamp, this.props.endDatePeriodTimestamp, this.props.nodePath)
            if(response.error !== undefined){
                return;
            }

            this.setState({
                loadingInProgress: false,
                aviorsAvailabilityResponse: response.data.aggregations.modac_path_agg.buckets
            })

        })
    }

    render() {
        if(this.state.loadingInProgress){
            return <div style={{textAlign: "center"}}>
                <Loader type="Oval" color="#4185F4" height={50} width={50}/>
            </div>
        }


        let availabilities = []
        if(this.state.aviorsAvailabilityResponse === undefined){
            return <label><Trans>AnErrorOccurred</Trans></label>
        }

        this.state.aviorsAvailabilityResponse.forEach(bucket => {
            const key = bucket.key;
            const nodeName = getPathString(this.props.factories, key, this.props.nodePath)

            bucket.modac_macaddr_agg.buckets.forEach(it => {
                const modacMacAddr =  it.key
                const available = it.availability.buckets.available.doc_count
                const notAvailable = it.availability.buckets.not_available.doc_count
                const total = available + notAvailable

                const thericCountOfEventsDurationMs = (this.props.endDatePeriodTimestamp - this.props.startDatePeriodTimestamp)
                const thericCountOfEventsDurationSec = thericCountOfEventsDurationMs / 1000
                const thericCountOfEvents = (thericCountOfEventsDurationSec / 6)

                let result = (total * 100) / thericCountOfEvents
                if(result > 100){
                    result = 100
                }

                availabilities.push({
                    nodeName: nodeName,
                    modacReference: getModacReference(this.props.factories, key, modacMacAddr),
                    availability: result
                })
            })
        })

        const badAviorsAvailability = availabilities.filter(it => {
            return it.availability <= portalConfig.WARNING_AVIOR_AVAILABILITY_VALUE_THRESHOLD
        })

        if(badAviorsAvailability.length === 0){
            return (
                <div className={classes.DashboardSupervisionOKContainer}>
                    <img src={OKIcon} alt={"OK"}/>
                    <label> <Trans>AllAviorsAreAvailable</Trans></label>
                </div>
            )
        }

        return <table>
                <thead>
                    <tr>
                        <th><Trans>Location</Trans></th>
                        <th><Trans>Device</Trans></th>
                        <th><Trans>Availability</Trans></th>
                    </tr>
                </thead>
                <tbody>
                    {
                        badAviorsAvailability.map( it =>
                        <tr className={classes.DashboardSupervisionErrorRow}>
                            <td>{it.nodeName}</td>
                            <td>{it.modacReference}</td>
                            <td className={it.availability < portalConfig.ALERT_AVIOR_AVAILABILITY_VALUE_THRESHOLD ? classes.DashboardSupervisionAlertRowValue : classes.DashboardSupervisionWarningRowValue}>{it.availability.toFixed(2)}%</td>
                        </tr>
                        )
                    }
                </tbody>
        </table>
    }
}

export default BadAviorsAvailability;
