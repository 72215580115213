import Interval from "../../../requests/elastic_search/Interval";
import {subDays} from "date-fns";
import {isValidTimestamp} from "../../../helpers/DateHelper";

/***
 * Returns default props for graph dataset to make a graph with x axes as date without auto-bounding with dates
 * @param maxTimestamp timestamp of last visible date (used to bound x axes on right range)
 * @param interval interval of time between each item (see Interval.js => ex: Interval.HOUR, Interval.DAY, Interval.MONTH...)
 * @returns {{mode: string, rangeMax: {x: *, y: null}, rangeMin: {x: number, y: null}, enabled: boolean}}
 */
const timeGraphZoomDefaultProps = (maxTimestamp, interval) => {
    const isMaxTimestampValidTimestamp = isValidTimestamp(maxTimestamp)
    let rangeMin = undefined
    if(isMaxTimestampValidTimestamp){
        rangeMin = getRangeMin(maxTimestamp, interval)
    }

    return {
        enabled: true,
        mode: 'x',
        rangeMin: {
            // Format of min pan range depends on scale type
            x: isNaN(rangeMin) ? null : rangeMin,
            y: null
        },
        rangeMax: {
            // Format of max pan range depends on scale type
            x: isMaxTimestampValidTimestamp ? maxTimestamp : null,
            y: null
        }
    }
}

/***
 * From date and interval, this function returns best graph range
 * @param timestamp timestamp of last graph date where range will be substract to determine first visible date on range
 * @param interval of time between each item (see Interval.js => ex: Interval.HOUR, Interval.DAY, Interval.MONTH...)
 * @returns {number} returns timestamp of first date of range
 */
const getRangeMin = (timestamp, interval) => {
    switch (interval) {
        case Interval.HOUR:
            return subDays(new Date(timestamp), 1).getTime()
        case Interval.DAY:
            return subDays(new Date(timestamp), 30).getTime()
        case Interval.MONTH:
            return subDays(new Date(timestamp), 365).getTime()
        default:
            return subDays(new Date(timestamp), 30).getTime()
    }
}

export default timeGraphZoomDefaultProps;
