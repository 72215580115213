import {getI18n} from "react-i18next";

export const getDataset = (dataParam) => {
    if(dataParam !== undefined && dataParam.aggregations !== undefined && dataParam.aggregations.date !== undefined && dataParam.aggregations.date.buckets !== undefined){
        const buckets = dataParam.aggregations.date.buckets
        const dataset = {
            backgroundColor: "rgba(22,255,1,0.31)",
            borderColor: "rgb(0,184,104)",
            label: `${getI18n().t("Availability")} (%)`,
            data: []
        }

        let dates = []
        buckets.forEach(it => {
            if(it.key_as_string !== undefined && it.availability !== undefined && it.availability.buckets !== undefined && it.availability.buckets.not_available !== undefined && it.availability.buckets.not_available.doc_count !== undefined && it.doc_count !== undefined){
                const date = new Date(it.key_as_string)
                dates.push(date)

                dataset.data.push(
                    {
                        x: date,
                        y: 100-((it.availability.buckets.not_available.doc_count * 100) / it.doc_count).toFixed(2)
                    }
                )
            }
        })

        return {
            datasets: [dataset],
            dates: dates
        }
    }

    return {
        error: "invalid input to generate dataset"
    }
}
