import nucleotApi, {aviorDefaultElasticSearchHeader} from "../axios";
import {config} from "../../config";
import {adaptQuery} from "../../helpers/elasticSearchQueryHelper";

const repartitionOfControlsDurationBody = (optionalStartDateTimestamp, optionalEndDateTimestamp, optionalModacMacAddr, optionalDeviceSerial, nodePath) => {
    let body = {
        "aggs": {
            "durations": {
                "filters": {
                    "filters": {

                    }
                }
            }
        },
        "size": 0,
        "_source": {
            "excludes": []
        },
        "stored_fields": [
            "*"
        ],
        "script_fields": {},
        "docvalue_fields": [
            {
                "field": "@timestamp",
                "format": "date_time"
            },
            {
                "field": "logdate",
                "format": "date_time"
            }
        ],
        "query": {
            "bool": {
                "must": [
                    {
                        "match_phrase": {
                            "p1_measure_status": {
                                "query": "3"
                            }
                        }
                    },
                    // {
                    //     "match_phrase": {
                    //         "p1_ch1_trans": {
                    //             "query": "BETA"
                    //         }
                    //     }
                    // }
                ],
                "filter": [],
                "should": [],
                "must_not": []
            }
        }
    }

    for(let i = 0 ; i < 180 ; i+=10){
        const newKey = `${i}`
        const filter = `p1_measure_duration:>=${i} && p1_measure_duration:<${i+10}`
        body.aggs.durations.filters.filters[newKey] = {
            "query_string": {
                "query": filter,
                "analyze_wildcard": true,
                "default_field": "*"
            }
        }
    }

    adaptQuery(body, undefined, optionalStartDateTimestamp, optionalEndDateTimestamp, nodePath, optionalModacMacAddr, optionalDeviceSerial)

    return body;
}

export const getEvolutionRepartition = async(optionalStartDateTimestamp, optionalEndDateTimestamp, optionalModacMacAddr, optionalDeviceSerial, nodePath) => {
    try{
        const query = repartitionOfControlsDurationBody(optionalStartDateTimestamp, optionalEndDateTimestamp, optionalModacMacAddr, optionalDeviceSerial, nodePath)
        const response = await nucleotApi.post(config.API_URL + config.ELASTIC_SEARCH_ENDPOINT_PATH, query, {
            headers: aviorDefaultElasticSearchHeader
        })
        return response.data
    } catch (e) {
        return {
            error: e
        }
    }
}
