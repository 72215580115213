import {adaptQuery} from "../../helpers/elasticSearchQueryHelper";
import nucleotApi, {aviorDefaultElasticSearchHeader} from "../axios";
import {config} from "../../config";

export const getAlarmsCountAndAverageMeasuresPerModacs = async(optionalStartDateTimestamp, optionalEndDateTimestamp, nodePath) => {
    if(nodePath === undefined){
        return {
            error: "node path has to be specified"
        }
    }

    try{
        const body =
            {
                "size": 0,
                "aggs": {
                    "modacs" : {
                        "filter" : {
                            "regexp": {
                                "modac_path.keyword":{
                                    "value": nodePath.replace(/\|/g, '\\|') + ".*"
                                }
                            }
                        },
                        "aggs": {
                            "modac_path_agg": {
                                "terms": {
                                    "field": "modac_path.keyword"
                                },
                                "aggs": {
                                    "modac_macaddr_agg": {
                                        "terms": {
                                            "field": "modac_macaddr.keyword"
                                        },

                                        "aggs": {
                                            "per_unit": {
                                                "terms": {
                                                    "field": "p1_ch1_unit.keyword"
                                                },


                                                "aggs": {
                                                    "measure_avg": {
                                                        "avg": {
                                                            "field": "p1_ch1_smooth"
                                                        }
                                                    },
                                                    "low_alarms_count": {
                                                        "filter": {
                                                            "bool": {
                                                                "must": [
                                                                    {
                                                                        "term": {
                                                                            "p1_ch1_alarm_val": "2"
                                                                        }
                                                                    },
                                                                    {
                                                                        "term": {
                                                                            "p1_ch1_alarm.keyword": "LOW"
                                                                        }
                                                                    }

                                                                ]
                                                            }

                                                        }
                                                    },
                                                    "high_alarms_count": {
                                                        "filter": {
                                                            "bool": {
                                                                "must": [
                                                                    {
                                                                        "term": {
                                                                            "p1_ch1_alarm_val": "2"
                                                                        }
                                                                    },
                                                                    {
                                                                        "term": {
                                                                            "p1_ch1_alarm.keyword": "HIGH"
                                                                        }
                                                                    }
                                                                ]
                                                            }

                                                        }
                                                    },
                                                    "saturate_alarms_count": {
                                                        "filter": {
                                                            "bool": {
                                                                "must": [
                                                                    {
                                                                        "term": {
                                                                            "p1_ch1_alarm_val": "2"
                                                                        }
                                                                    },
                                                                    {
                                                                        "term": {
                                                                            "p1_ch1_alarm.keyword": "SATURATE"
                                                                        }
                                                                    }
                                                                ]
                                                            }
                                                        }
                                                    }
                                                }
                                            }
                                        }
                                    }
                                }
                            }}}
                },
                "query": {
                    "bool": {
                        "must": [
                            {
                                "match_phrase":{
                                    "modac_rs485_status": "1"
                                }
                            }
                        ],
                        "filter": [],
                        "should": [],
                        "must_not": [
                            {
                                "match_phrase":{
                                    "p1_ch1_trans": "NONE"
                                }
                            }
                        ]
                    }
                }
            }

        adaptQuery(body, undefined, optionalStartDateTimestamp, optionalEndDateTimestamp, undefined, undefined, undefined)

        const response = await nucleotApi.post(config.API_URL + config.ELASTIC_SEARCH_ENDPOINT_PATH, body, {
            headers: aviorDefaultElasticSearchHeader
        })

        if(response.data !== undefined && response.data.aggregations !== undefined && response.data.aggregations.modacs !== undefined && response.data.aggregations.modacs.modac_path_agg !== undefined && response.data.aggregations.modacs.modac_path_agg.buckets !== undefined){
            return {
                error: undefined,
                data: response.data
            }
        }
        return {
            error: new Error("no data in response")

        }
    }catch(e){
        return {
            error: e
        }
    }
}
