import React, {Component} from "react"
import classes from "./Users.module.css"
import User from "./user/User"
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faTrash} from "@fortawesome/free-solid-svg-icons";

class Users extends Component{

    state = {
        users: [],
        selectedUsersId: [],
        isUserAdding: false
    }

    componentDidMount() {
        this.getUsers()
    }

    getUsers = () => {
        const users = [
            {
                id:"1",
                firstname: "John",
                lastname: "John",
                isAdmin: true,
                lastSignedIn: "2020-05-20 15:34:00",
                email: "john.doe@gmail.com",
            },
            {
                id:"2",
                firstname: "Mathieu",
                lastname: "EDET",
                isAdmin: false,
                lastSignedIn: "2020-05-20 15:34:00",
                email: "mathieu.edet@fast-point.net",
            }
        ]

        this.setState({users: users})
    }

    onSelect = (userId) => {
        const selectedUserIds = [...this.state.selectedUsersId]
        selectedUserIds.push(userId)
        this.setState({selectedUsersId: selectedUserIds})
    }

    onUnselect = (userId) => {
        const selectedUserIds = [...this.state.selectedUsersId]
        if(selectedUserIds.indexOf(userId) !== -1){
            const indexToDelete = selectedUserIds.indexOf(userId)
            selectedUserIds.splice(indexToDelete, 1)
            this.setState({selectedUsersId: selectedUserIds})
        }
    }

    onUserAddClick = () => {
        this.setState({isUserAdding: true})
    }

    onDeleteClick = () => {
        alert("delete")
    }


    render(){
        return (
            <div className={classes.UsersContainer}>
                <button onClick={this.onUserAddClick} className={classes.AddUserButton}>+ Add user</button>
                <button onClick={this.onDeleteClick} disabled={this.state.selectedUsersId.length === 0} className={classes.DeleteUserButton}><FontAwesomeIcon icon={faTrash} size={"sm"}/>&nbsp;&nbsp;Delete user</button>
                <label className={classes.UsersCountLabel}>{this.state.users.length} users</label>
                <table className={classes.UsersTable}>
                    <thead>
                        <tr>
                            <td></td>
                            <td>Identity</td>
                            <td>Administrator</td>
                            <td>Organization accesses</td>
                            <td>Last signed in at</td>
                            <td>More</td>
                        </tr>
                    </thead>
                    <tbody>
                        {
                            this.state.users.map(user => {
                                return <User key={user.id} user={user} onSelect={this.onSelect} onUnselect={this.onUnselect}/>
                            })
                        }
                    </tbody>
                </table>
            </div>
        )
    }
}

export default Users;
