import React, {Component} from "react";
import classes from "./ModacOverview.module.css";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faPlug, faRadiation, faSlidersH} from "@fortawesome/free-solid-svg-icons";
import {getI18n, Trans} from "react-i18next";
import {secToTextWithoutUselessUnit} from "../../helpers/DateHelper";
import {getTransmitterReadableString} from "../../helpers/UnitsHelper";

const isModacOnline = (logtype, lastReceivedEventDateString) => {
    const lastReceivedEventTimestamp = Date.parse(lastReceivedEventDateString)
    const now = Date.now()
    const diff = now-lastReceivedEventTimestamp
    if(logtype === "REALTIME"){
        return diff < (1000 * 60 * 5) // < 5 min
    }else {
        return diff < (1000 * 60 * 60 * 24) // < 24h
    }
}

const ModacState = {
    ACTIVE: "ACTIVE",
    INACTIVE: "INACTIVE",
    OFFLINE: "OFFLINE"
}

const RadiameterState = {
    COMPLIANT: "COMPLIANT",
    UNAVAILABLE_AVIOR: "UNAVAILABLE_AVIOR",
    AVAILABLE_AVIOR_AND_SHOULDNT_BE: "AVAILABLE_AVIOR_AND_SHOULDNT_BE",
    PROBE_BAD_CONNECTION: "PROBE_BAD_CONNECTION",
    INACTIVE: "INACTIVE"
}

const getModacState = (modac) => {
    const isOnline = isModacOnline(modac.log_type, modac.last_received_event)
    if(isOnline && modac.log_type === "REALTIME" && modac.status === "ACTIVE"){
        return ModacState.ACTIVE
    }else if(!isOnline && modac.log_type === "REALTIME" && modac.status === "ACTIVE"){
        return ModacState.OFFLINE
    }else {
        return ModacState.INACTIVE
    }
}

const getStateIconColor = (node) => {

    const isOnline = isModacOnline(node.log_type, node.last_received_event)
    if(isOnline && node.log_type === "REALTIME" && node.status === "ACTIVE"){
        return "rgba(63,173,23,0.75)"
    }else if(!isOnline && node.log_type === "REALTIME" && node.status === "ACTIVE"){
        return "rgba(173,44,25,0.75)"
    }else {
        return "rgba(87,87,87,1.00)"
    }
}

const getConfigPictoColor = (node) => {

    // If any detected config is incorrect, return error color
    if(node.configSet.p1.ch1.expectedRadiationType !== node.configDetected.p1.ch1.detectedRadiationType ||
        node.configSet.p1.ch2.expectedRadiationType !== node.configDetected.p1.ch2.detectedRadiationType ||
        node.configSet.p2.ch1.expectedRadiationType !== node.configDetected.p2.ch1.detectedRadiationType ||
        node.configSet.p2.ch2.expectedRadiationType !== node.configDetected.p2.ch2.detectedRadiationType){
        return "rgba(173,44,25,0.75)"
    }

    // If config is correct and at least one probe is available, return correct color
    if(node.configDetected.p1.isAvailable === true || node.configDetected.p2.isAvailable === true){
        return "rgba(63,173,23,0.75)"
    }

    // If config is correct and no probe connected neutral color
    return "rgba(87,87,87,1.00)"
}


const getRadiameterState = (node) => {
    // Check if one of probe is not in the expected state
    if(node.configSet.mustAviorBeOnline !== node.configDetected.isAviorOnline){
        return node.configSet.mustAviorBeOnline === true ? RadiameterState.UNAVAILABLE_AVIOR : RadiameterState.AVAILABLE_AVIOR_AND_SHOULDNT_BE
    }
    // Check if all probe are in expected state and at least one probe is available
    if(node.configDetected.isAviorOnline === false){
        return RadiameterState.INACTIVE
    }

    if(node.configSet.p1.mustBeAvailable === node.configDetected.p1.isAvailable && node.configSet.p2.mustBeAvailable === node.configDetected.p2.isAvailable){
        return RadiameterState.COMPLIANT
    }else {
        return RadiameterState.PROBE_BAD_CONNECTION
    }
}

const getRadiameterStateIconColor = (node) => {
    const radiameterState = getRadiameterState(node)
    switch (radiameterState) {
        case RadiameterState.COMPLIANT:
            return "rgba(63,173,23,0.75)"
        case RadiameterState.INACTIVE:
            return "rgba(87,87,87,1.00)"
        default:
            return "rgba(173,44,25,0.75)"
    }
}

class ModacOverview extends Component {


    shouldComponentUpdate(nextProps, nextState, nextContext) {
        return true
    }

    isCommunicatingOverGoodMediaspot = () => {
        if(this.props.modac.detectedMediaspotSerial !== undefined && this.props.modac.parent_key !== undefined && this.props.modac.parent_key.includes("mediaspot-")){
            const extractedSerial = this.props.modac.parent_key.replace("mediaspot-", "").split("|")[0]
            if(extractedSerial !== this.props.modac.detectedMediaspotSerial){
                return false
            }
        }
        return true
    }

    render(){
        const modac = this.props.modac
        const modacState = getModacState(modac)
        const radiameterState = getRadiameterState(modac)

        return (
            <div>

                <div className={classes.ModacOverviewContainer}>
                    <div className={classes.ModacOverviewContent}>

                        <label>{modac.reference}</label>
                        <button className={classes.ModacOverviewCloseButton} onClick={this.props.onCloseModacModal}>X</button>
                        <div className={classes.DashboardDevicesTitleSeparator}/>

                        <div className={classes.DashboardModacInfoContainer}>

                            <label className={classes.DashboardModacTitle}><FontAwesomeIcon fixedWidth={true} icon={faRadiation} color={getStateIconColor(modac)} />&nbsp;&nbsp;<Trans>Status</Trans> :&nbsp;
                                {modacState === ModacState.ACTIVE ? <Trans>Online</Trans> : undefined}
                                {modacState === ModacState.OFFLINE ? <Trans>Offline</Trans> : undefined}
                                {modacState === ModacState.INACTIVE ? <Trans>Inactive</Trans> : undefined}
                            </label>
                            {this.isCommunicatingOverGoodMediaspot() === false ?
                                <label style={{fontWeight: "bold", color: "orange"}}>
                                    ⚠ <Trans>ThisModacDoesntCommunicateWithConfiguredMediaspot</Trans> ({modac.parent_key.replace("mediaspot-", "").replace("|0", "")}). <Trans>DetectedCommunicationWith</Trans> {modac.detectedMediaspotSerial}
                                    <br />
                                </label>
                                : undefined}
                            <div className={classes.DashboardModacProbeState}>
                                <label>
                                <span>
                                    {modacState === ModacState.ACTIVE ? <Trans>OnlineModacDescription</Trans> : undefined}
                                    {modacState === ModacState.OFFLINE ? <Trans>OfflineModacDescription</Trans> : undefined}
                                    {modacState === ModacState.INACTIVE ? <Trans>InactiveModacDescription</Trans> : undefined}
                                </span>
                                    <br />
                                    <Trans>LastInfoReceived</Trans> {new Date(Date.parse(modac.last_received_event)).toLocaleDateString('fr-FR',{hour: 'numeric', hour12: false, minute: "numeric"})} (<Trans values={{date: secToTextWithoutUselessUnit((Date.now() - Date.parse(modac.last_received_event))/1000)}}>DateAgo</Trans>)
                                    <br />
                                    <Trans>CollectModeIs</Trans> {modac.log_type === "REALTIME" ? getI18n().t("Realtime").toLowerCase() : getI18n().t("Disconnected").toLowerCase()}
                                </label>
                            </div>



                            <label className={classes.DashboardModacTitle}><FontAwesomeIcon fixedWidth={true} icon={faPlug} color={getRadiameterStateIconColor(modac)}/>&nbsp;&nbsp;<Trans>RadiameterState</Trans>:&nbsp;
                                {radiameterState === RadiameterState.COMPLIANT ? <Trans>Online</Trans> : undefined}
                                {radiameterState === RadiameterState.UNAVAILABLE_AVIOR ? <Trans>Offline</Trans> : undefined}
                                {radiameterState === RadiameterState.AVAILABLE_AVIOR_AND_SHOULDNT_BE ? <Trans>Inactive</Trans> : undefined}
                                {radiameterState === RadiameterState.PROBE_BAD_CONNECTION ? <Trans>Inactive</Trans> : undefined}
                                {radiameterState === RadiameterState.INACTIVE ? <Trans>Inactive</Trans> : undefined}
                            </label>

                            <label style={{whiteSpace: "pre-line"}}>
                                {radiameterState === RadiameterState.COMPLIANT ? <Trans>MeasureDeviceIsAvailable</Trans> : undefined}
                                {radiameterState === RadiameterState.UNAVAILABLE_AVIOR ? <Trans>MeasureDeviceNotAvailableAvior</Trans> : undefined}
                                {radiameterState === RadiameterState.AVAILABLE_AVIOR_AND_SHOULDNT_BE ? <Trans>MeasureDeviceAvailableAviorShouldntBe</Trans> : undefined}
                                {radiameterState === RadiameterState.PROBE_BAD_CONNECTION ? <Trans>MeasureDeviceBadProbeConnection</Trans> : undefined}
                                {radiameterState === RadiameterState.INACTIVE ? <Trans>MeasureDeviceInactive</Trans> : undefined}
                            </label>

                            <br />
                            <br />

                            <table className={classes.ModacOverviewRadiameterStateTable}>
                                <thead>
                                <tr>
                                    <td></td>
                                    <td><Trans>ExpectedConnection</Trans></td>
                                    <td><Trans>DetectedConnection</Trans></td>
                                </tr>
                                </thead>
                                <tbody>
                                <tr>
                                    <td><Trans>Input</Trans> 1</td>
                                    <td><span style={modac.configSet.p1.mustBeAvailable !== modac.configDetected.p1.isAvailable ? {color: "red"} : undefined}>{modac.configSet.p1.mustBeAvailable === true ? <Trans>PluggedProbe</Trans> : <Trans>UnpluggedProbe</Trans>}</span></td>
                                    <td><span style={modac.configSet.p1.mustBeAvailable !== modac.configDetected.p1.isAvailable ? {color: "red"} : undefined}>{modac.configDetected.p1.isAvailable === true ? <Trans>PluggedProbe</Trans> : <Trans>UnpluggedProbe</Trans>}</span></td>
                                </tr>
                                <tr>
                                    <td><Trans>Input</Trans> 2</td>
                                    <td><span style={modac.configSet.p2.mustBeAvailable !== modac.configDetected.p2.isAvailable ? {color: "red"} : undefined}>{modac.configSet.p2.mustBeAvailable === true ? <Trans>PluggedProbe</Trans> : <Trans>UnpluggedProbe</Trans>}</span></td>
                                    <td><span style={modac.configSet.p2.mustBeAvailable !== modac.configDetected.p2.isAvailable ? {color: "red"} : undefined}>{modac.configDetected.p2.isAvailable === true ? <Trans>PluggedProbe</Trans> : <Trans>UnpluggedProbe</Trans>}</span></td>
                                </tr>
                                </tbody>
                            </table>



                            <label className={classes.DashboardModacTitle}><FontAwesomeIcon fixedWidth={true} icon={faSlidersH} color={getConfigPictoColor(modac)}/>&nbsp;&nbsp;<Trans>ProbeSettings</Trans></label>
                            <table>
                                <thead>
                                <tr>
                                    <td></td>
                                    <td></td>
                                    <td><Trans>ExpectedTransmitter</Trans></td>
                                    <td><Trans>DetectedTransmitter</Trans></td>
                                </tr>
                                </thead>
                                <tbody>
                                <tr className={classes.ModacOverviewBadProbeSettingsInputRow}>
                                    {/*<td><img src={modac.configSet.p1.ch1.expectedRadiationType === modac.configDetected.p1.ch1.detectedRadiationType ? OKIcon: NOKIcon} alt={"status icon"}/></td>*/}
                                    <td rowSpan={2}><Trans>Input</Trans> 1</td>
                                    <td><Trans>Channel</Trans> 1</td>
                                    <td><span style={modac.configSet.p1.ch1.expectedRadiationType !== modac.configDetected.p1.ch1.detectedRadiationType ? {color: "red"} : undefined}>{getTransmitterReadableString(modac.configSet.p1.ch1.expectedRadiationType)}</span></td>
                                    <td><span style={modac.configSet.p1.ch1.expectedRadiationType !== modac.configDetected.p1.ch1.detectedRadiationType ? {color: "red"} : undefined}>{getTransmitterReadableString(modac.configDetected.p1.ch1.detectedRadiationType)}</span></td>
                                </tr>
                                <tr>
                                    {/*<td><img src={modac.configSet.p1.ch2.expectedRadiationType === modac.configDetected.p1.ch2.detectedRadiationType ? OKIcon: NOKIcon} alt={"status icon"}/></td>*/}
                                    {/*<td rowSpan={2}><Trans>Input</Trans> 1</td>*/}
                                    <td><Trans>Channel</Trans> 2</td>
                                    <td><span style={modac.configSet.p1.ch2.expectedRadiationType !== modac.configDetected.p1.ch2.detectedRadiationType ? {color: "red"} : undefined}>{getTransmitterReadableString(modac.configSet.p1.ch2.expectedRadiationType)}</span></td>
                                    <td><span style={modac.configSet.p1.ch2.expectedRadiationType !== modac.configDetected.p1.ch2.detectedRadiationType ? {color: "red"} : undefined}>{getTransmitterReadableString(modac.configDetected.p1.ch2.detectedRadiationType)}</span></td>
                                </tr>
                                <tr className={classes.ModacOverviewBadProbeSettingsInputRow}>
                                    <td rowSpan={2}><Trans>Input</Trans> 2</td>
                                    <td><Trans>Channel</Trans> 1</td>
                                    <td><span style={modac.configSet.p2.ch1.expectedRadiationType !== modac.configDetected.p2.ch1.detectedRadiationType ? {color: "red"} : undefined}>{getTransmitterReadableString(modac.configSet.p2.ch1.expectedRadiationType)}</span></td>
                                    <td><span style={modac.configSet.p2.ch1.expectedRadiationType !== modac.configDetected.p2.ch1.detectedRadiationType ? {color: "red"} : undefined}>{getTransmitterReadableString(modac.configDetected.p2.ch1.detectedRadiationType)}</span></td>
                                </tr>
                                <tr>
                                    {/*<td rowSpan={2}><Trans>Input</Trans> 2</td>*/}
                                    <td><Trans>Channel</Trans> 2</td>
                                    <td><span style={modac.configSet.p2.ch2.expectedRadiationType !== modac.configDetected.p2.ch2.detectedRadiationType ? {color: "red"} : undefined}>{getTransmitterReadableString(modac.configSet.p2.ch2.expectedRadiationType)}</span></td>
                                    <td><span style={modac.configSet.p2.ch2.expectedRadiationType !== modac.configDetected.p2.ch2.detectedRadiationType ? {color: "red"} : undefined}>{getTransmitterReadableString(modac.configDetected.p2.ch2.detectedRadiationType)}</span></td>
                                </tr>
                                </tbody>
                            </table>
                        </div>


                    </div>

                </div>

                <div className={classes.ModacOverviewOverlay} onClick={this.props.onCloseModacModal}/>

            </div>)
    }

}

export default ModacOverview;
