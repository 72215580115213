import decode from "jwt-decode";

// This function is exported in a specific module to easily be mocked
export const isTokenExpired = token => {
    try {
        const decoded = decode(token);
        return decoded.exp < new Date().getTime() / 1000
    } catch (err) {
        console.log("expired check failed! Line 41: AuthService.js" + err);
        return true;
    }
};
