import nucleotApi, {aviorDefaultElasticSearchHeader} from "../axios";
import {config} from "../../config";
import {adaptQuery} from "../../helpers/elasticSearchQueryHelper";

const modacsAvailabilityBody = (optionalStartDateTimestamp, optionalEndDateTimestamp, modacMacAddr, optionalDeviceSerial, nodePath) => {
    let body = {
        "aggs": {
            "availability": {
                "filters": {
                    "filters": {
                        "available": {
                            "query_string": {
                                "query": "modac_rs485_status:1",
                                "analyze_wildcard": true,
                                "default_field": "*"

                            }
                        },
                        "not_available": {
                            "query_string": {
                                "query": "modac_rs485_status:0",
                                "analyze_wildcard": true,
                                "default_field": "*"
                            }
                        }
                    }
                }
            }
        },

        "size": 0,
        "query": {
            "bool": {
                "must": []
            }
        }
    }

    adaptQuery(body, undefined, optionalStartDateTimestamp, optionalEndDateTimestamp, nodePath, modacMacAddr, optionalDeviceSerial)

    return body;
}

export const getAverageModacsAvailability = async(optionalStartDateTimestamp, optionalEndDateTimestamp, modacMacAddr, optionalDeviceSerial, nodePath) => {
    try{
        let endDate = optionalEndDateTimestamp;
        // To get average availability, we only get data on past events (not average with not defined future values)
        if(optionalEndDateTimestamp !== undefined && optionalEndDateTimestamp > Date.now()){
            endDate = Date.now()
        }

        if(nodePath === undefined){
            return {
                error: "node path has to be specified"
            }
        }

        const query = modacsAvailabilityBody(optionalStartDateTimestamp, endDate, modacMacAddr, optionalDeviceSerial, nodePath)
        const response = await nucleotApi.post(config.API_URL + config.ELASTIC_SEARCH_ENDPOINT_PATH, query, {
            headers: aviorDefaultElasticSearchHeader
        })
        if(response.data !== undefined){
            const available = response.data.aggregations.availability.buckets.available.doc_count
            const notAvailable = response.data.aggregations.availability.buckets.not_available.doc_count
            const total = available + notAvailable

            const thericCountOfEventsDurationMs = (endDate - optionalStartDateTimestamp)
            const thericCountOfEventsDurationSec = thericCountOfEventsDurationMs / 1000
            const thericCountOfEvents = (thericCountOfEventsDurationSec / 6)

            let result = (total * 100) / thericCountOfEvents
            if(result > 100){
                result = 100
            }
            return {
                error: undefined,
                data: result
            }
        }
        return {
            error: new Error("no data in response")

        }

    } catch (e) {
        return {
            error: e
        }
    }
}
