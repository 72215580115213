import React, {Component} from "react"
import {getPathString} from "../../helpers/FactoriesHelper";
import classes from "../Dashboard.module.css";
import OKIcon from "../../assets/img/ok.svg";
import {Trans} from "react-i18next";
import {getAlarmsCountAndAverageMeasuresPerModacs} from "../../requests/elastic_search/GetAlarmsAndAverageMeasuresPerModac";
import i18n from "../../i18n"
import Loader from "react-loader-spinner";
import * as portalConfig from "../../portal_config"
import ReactTooltip from "react-tooltip";
import PropTypes from "prop-types"

class AlarmsAndBadMeasures extends Component {

    state = {
        response: undefined,
        loadingInProgress: true,
    }



    async componentDidMount() {
        await this.loadAlarmsAndMeasures(false)

        setInterval(this.loadAlarmsAndMeasures, portalConfig.SUPERVISION_REFRESH_TICK_MILLISECONDS)
    }

    async componentDidUpdate(prevProps, prevState, snapshot) {
        if(prevProps.nodePath !== this.props.nodePath || prevProps.startDatePeriodTimestamp !== this.props.startDatePeriodTimestamp || prevProps.endDatePeriodTimestamp !== this.props.endDatePeriodTimestamp){
            await this.loadAlarmsAndMeasures(false)
        }
        ReactTooltip.rebuild()
    }

    /***
     *
     * @param isAutoRefresh indicate if refresh is coming from a user action (node change, date range change) or if it's a auto refresh after some seconds
     * @return {Promise<void>}
     */
    loadAlarmsAndMeasures = async(isAutoRefresh = true) => {
        this.setState({loadingInProgress: !isAutoRefresh}, async() => {
            const response = await getAlarmsCountAndAverageMeasuresPerModacs(this.props.startDatePeriodTimestamp, this.props.endDatePeriodTimestamp, this.props.nodePath)
            if(response.error !== undefined){
                return;
            }

            this.setState({
                loadingInProgress: false,
                response: response.data.aggregations.modacs.modac_path_agg.buckets
            })

        })
    }



    getUnitStringFromKey = (key) => {
        switch (key) {
            case "C_PER_S":{
                return "c/s"
            }
            case "BQEQ_PER_CM2": {
                return "Bq/cm²"
            }
            default: return ""
        }
    }


    render() {
        if(this.state.loadingInProgress){
            return <div style={{textAlign: "center"}}>
                <Loader type="Oval" color="#4185F4" height={50} width={50}/>
            </div>
        }

        if(this.state.response === undefined){
            return <label>No data</label>
        }

        let result = {}
        this.state.response.forEach(bucket => {
            const key = bucket.key;
            result[key]={}
            const nodeName = getPathString(this.props.factories, key, this.props.nodePath)

            let modacsValuesPerUnit = {}
            bucket.modac_macaddr_agg.buckets.forEach(it => {
                it["per_unit"].buckets.forEach(perUnitObj => {
                    const unit = perUnitObj.key
                    if (modacsValuesPerUnit[unit] === undefined) {
                        modacsValuesPerUnit[unit] = []
                    }
                    modacsValuesPerUnit[unit].push({
                        lowAlarmsCount: perUnitObj.low_alarms_count.doc_count,
                        highAlarmsCount: perUnitObj.high_alarms_count.doc_count,
                        saturateAlarmsCount: perUnitObj.saturate_alarms_count.doc_count,
                        averageMeasure: perUnitObj.measure_avg.value
                    })
                })

            })


            const measuresUnitsKeys = Object.keys(modacsValuesPerUnit);
            measuresUnitsKeys.forEach(unitKey => {
                const measures = modacsValuesPerUnit[unitKey].map(it => it.averageMeasure)
                const lowAlarmCounts = modacsValuesPerUnit[unitKey].map(it => it.lowAlarmsCount)
                const highAlarmsCounts = modacsValuesPerUnit[unitKey].map(it => it.highAlarmsCount)
                const saturateAlarmsCounts = modacsValuesPerUnit[unitKey].map(it => it.saturateAlarmsCount)


                const avgMeasure = measures.reduce((a,b) => a + b, 0) / measures.length
                const lowAlarmCountForNode = lowAlarmCounts.reduce((a,b) => a + b, 0)
                const highAlarmCountForNode = highAlarmsCounts.reduce((a,b) => a + b, 0)
                const saturateAlarmCountForNode = saturateAlarmsCounts.reduce((a,b) => a + b, 0)

                result[key][unitKey] = {
                    nodeName: nodeName,
                    lowAlarmsCount : lowAlarmCountForNode,
                    highAlarmsCount : highAlarmCountForNode,
                    saturateAlarmsCount : saturateAlarmCountForNode,
                    averageMeasure : avgMeasure,
                    unit: unitKey
                }
            })

        })

        const nodeKeys = Object.keys(result)

        const rowValues = nodeKeys.map(key => {
            const keys = result[key]
            const unitKeys = Object.keys(keys)
            return unitKeys.map(unit => {
                const it = keys[unit]

                return it.lowAlarmsCount > 0 || it.highAlarmsCount > 0 || it.saturateAlarmsCount > 0 || (it.unit === "C_PER_S" && it.averageMeasure > portalConfig.WARNING_AVERAGE_MEASURE_VALUE_THRESHOLD_CS) || (it.unit === "BQEQ_PER_CM2" && it.averageMeasure > portalConfig.WARNING_AVERAGE_MEASURE_VALUE_THRESHOLD_BQ_CM2) ?
                    (
                        <tr className={classes.DashboardSupervisionErrorRow} key={it.nodeName}>
                            <td>{it.nodeName}</td>

                            {it.unit === "C_PER_S"
                                ? <td className={it.averageMeasure >=  portalConfig.ALERT_AVERAGE_MEASURE_VALUE_THRESHOLD_CS ? classes.DashboardSupervisionAlertRowValue : (it.averageMeasure >=  portalConfig.WARNING_AVERAGE_MEASURE_VALUE_THRESHOLD_CS ? classes.DashboardSupervisionWarningRowValue : undefined)}>{Intl.NumberFormat(i18n.language).format(it.averageMeasure.toFixed(3))} {this.getUnitStringFromKey(it.unit)}</td>
                                : undefined
                            }

                            {it.unit === "BQEQ_PER_CM2"
                                ? <td className={it.averageMeasure >=  portalConfig.ALERT_AVERAGE_MEASURE_VALUE_THRESHOLD_BQ_CM2 ? classes.DashboardSupervisionAlertRowValue : (it.averageMeasure >=  portalConfig.WARNING_AVERAGE_MEASURE_VALUE_THRESHOLD_BQ_CM2 ? classes.DashboardSupervisionWarningRowValue : undefined)}>{Intl.NumberFormat(i18n.language).format(it.averageMeasure.toFixed(3))} {this.getUnitStringFromKey(it.unit)}</td>
                                : undefined
                            }

                            <td data-tip={i18n.t("LowAlarms")}>{it.lowAlarmsCount === 0 || it.lowAlarmsCount === undefined ? "-" : <span className={classes.LowAlarmsCountText}>{it.lowAlarmsCount}</span>}</td>
                            <td data-tip={i18n.t("HighAlarms")}>{it.highAlarmsCount === 0 || it.highAlarmsCount === undefined ? "-" : <span className={classes.HighAlarmsCountText}>{it.highAlarmsCount}</span>}</td>
                            <td data-tip={i18n.t("SaturateAlarms")}>{it.saturateAlarmsCount === 0 || it.saturateAlarmsCount === undefined ? "-" : <span className={classes.SaturateAlarmsCountText}>{it.saturateAlarmsCount}</span>}</td>
                        </tr>
                    ) : undefined

            })

        })


        // If there are another value that undefined, there is bad values, else all values are good
        if(rowValues.filter(it => {
            return it.filter(value => value !== undefined).length !== 0
        }).length === 0){
            return (
                <div className={classes.DashboardSupervisionOKContainer}>
                    <img src={OKIcon} alt={"OK"}/>
                    <label> <Trans>NoAlarmAndCorrectAverageRadiaton</Trans></label>
                </div>
            )
        }


        return(
            <table>
                <thead>
                <tr>
                    <th><Trans>Location</Trans></th>
                    <th><Trans>AverageMeasure</Trans></th>
                    <th colSpan={3}><Trans>Alarms</Trans></th>
                </tr>
                </thead>

                <tbody>
                {
                    nodeKeys.map(key => {
                        const keys = result[key]
                        const unitKeys = Object.keys(keys)
                        return unitKeys.map(unit => {
                            const it = keys[unit]

                            return it.lowAlarmsCount > 0 || it.highAlarmsCount > 0 || it.saturateAlarmsCount > 0 || (it.unit === "C_PER_S" && it.averageMeasure > portalConfig.WARNING_AVERAGE_MEASURE_VALUE_THRESHOLD_CS) || (it.unit === "BQEQ_PER_CM2" && it.averageMeasure > portalConfig.WARNING_AVERAGE_MEASURE_VALUE_THRESHOLD_BQ_CM2) ?
                                (
                                    <tr className={classes.DashboardSupervisionErrorRow} key={`${it.nodeName}${it.unit}`}>
                                        <td>{it.nodeName}</td>

                                        {it.unit === "C_PER_S"
                                            ? <td className={it.averageMeasure >=  portalConfig.ALERT_AVERAGE_MEASURE_VALUE_THRESHOLD_CS ? classes.DashboardSupervisionAlertRowValue : (it.averageMeasure >=  portalConfig.WARNING_AVERAGE_MEASURE_VALUE_THRESHOLD_CS ? classes.DashboardSupervisionWarningRowValue : undefined)}>{Intl.NumberFormat(i18n.language).format(it.averageMeasure.toFixed(3))} {this.getUnitStringFromKey(it.unit)}</td>
                                            : undefined
                                        }

                                        {it.unit === "BQEQ_PER_CM2"
                                            ? <td className={it.averageMeasure >=  portalConfig.ALERT_AVERAGE_MEASURE_VALUE_THRESHOLD_BQ_CM2 ? classes.DashboardSupervisionAlertRowValue : (it.averageMeasure >=  portalConfig.WARNING_AVERAGE_MEASURE_VALUE_THRESHOLD_BQ_CM2 ? classes.DashboardSupervisionWarningRowValue : undefined)}>{Intl.NumberFormat(i18n.language).format(it.averageMeasure.toFixed(3))} {this.getUnitStringFromKey(it.unit)}</td>
                                            : undefined
                                        }

                                        <td data-tip={i18n.t("LowAlarms")}>{it.lowAlarmsCount === 0 || it.lowAlarmsCount === undefined ? "-" : <span className={classes.LowAlarmsCountText}>{it.lowAlarmsCount}</span>}</td>
                                        <td data-tip={i18n.t("HighAlarms")}>{it.highAlarmsCount === 0 || it.highAlarmsCount === undefined ? "-" : <span className={classes.HighAlarmsCountText}>{it.highAlarmsCount}</span>}</td>
                                        <td data-tip={i18n.t("SaturateAlarms")}>{it.saturateAlarmsCount === 0 || it.saturateAlarmsCount === undefined ? "-" : <span className={classes.SaturateAlarmsCountText}>{it.saturateAlarmsCount}</span>}</td>
                                    </tr>
                                ) : undefined

                        })

                    })
                }
                </tbody>
            </table>
        )
    }
}

AlarmsAndBadMeasures.propTypes = {
    startDatePeriodTimestamp: PropTypes.number.isRequired,
    endDatePeriodTimestamp: PropTypes.number.isRequired,
    nodePath: PropTypes.string.isRequired,
    factories: PropTypes.any.isRequired,
    modacs: PropTypes.array.isRequired
}

export default AlarmsAndBadMeasures;
