import React, {Component} from "react";
import {Trans} from "react-i18next";
import classes from "./Settings.module.css"

class Settings extends Component {

    static SHOW_NO_VISIBLE_NODE_KEY = "show_not_visible_nodes"
    static SHOW_STORE_NODE_KEY = "show_store_nodes"
    static SHOW_ARCHIVE_DEVICES = "show_archive_devices"

    state = {
        showNotVisibleNodes: false,
        showStoreNodes: false,
        showArchiveDevices: false
    }



    constructor(props) {
        super(props);
        this.state = {
            showNotVisibleNodes: Settings.shouldShowNoVisibleNodes(),
            showStoreNodes: Settings.shouldShowStoreNodes(),
            showArchiveDevices: Settings.shouldShowArchiveDevices()
        }
    }

    static getLocalStorageBooleanValue = (key) => {
        const value = localStorage.getItem(key)
        if(value === null || (value !== "true" && value !== "false")){
            return false
        }else{
            return value === "true"
        }
    }

    static shouldShowNoVisibleNodes = () => {
        return Settings.getLocalStorageBooleanValue(Settings.SHOW_NO_VISIBLE_NODE_KEY)
    }

    static shouldShowStoreNodes = () => {
        return Settings.getLocalStorageBooleanValue(Settings.SHOW_STORE_NODE_KEY)
    }

    static shouldShowArchiveDevices = () => {
        return Settings.getLocalStorageBooleanValue(Settings.SHOW_ARCHIVE_DEVICES)
    }

    onVisibleAreaOptionChanged = (event) => {
        const lastValue = this.state.showNotVisibleNodes
        localStorage.setItem(Settings.SHOW_NO_VISIBLE_NODE_KEY, !lastValue)
        this.setState({showNotVisibleNodes: !lastValue})
    }

    onStoreNodeVisibilityOptionChanged = (event) => {
        const lastValue = this.state.showStoreNodes
        localStorage.setItem(Settings.SHOW_STORE_NODE_KEY, !lastValue)
        this.setState({showStoreNodes: !lastValue})
    }

    onArchiveDevicesVisibilityOptionChanged = (event) => {
        const lastValue = this.state.showArchiveDevices
        localStorage.setItem(Settings.SHOW_ARCHIVE_DEVICES, !lastValue)
        this.setState({showArchiveDevices: !lastValue})
    }

    render(){
        return <div className={classes.SettingsContainer}>
            <label className={classes.SettingsTitle}><Trans>Settings</Trans></label>

            <div className={classes.SettingsItem}>
                <input id={"hideVisibleNodesSettingsCheckbox"} onChange={this.onVisibleAreaOptionChanged} type="checkbox" name="associated" checked={this.state.showNotVisibleNodes}/>
                <label htmlFor={"hideVisibleNodesSettingsCheckbox"}> <Trans>ShowNotVisibleAreas</Trans></label>
            </div>

            <div className={classes.SettingsItem}>
                <input id={"showStoreNodesSettingsCheckbox"} onChange={this.onStoreNodeVisibilityOptionChanged} type="checkbox" name="associated" checked={this.state.showStoreNodes}/>
                <label htmlFor={"showStoreNodesSettingsCheckbox"}> <Trans>ShowStoreAreas</Trans></label>
            </div>

            <div className={classes.SettingsItem}>
                <input id={"showArchiveDevicesSettingsCheckbox"} onChange={this.onArchiveDevicesVisibilityOptionChanged} type="checkbox" name="associated" checked={this.state.showArchiveDevices}/>
                <label htmlFor={"showArchiveDevicesSettingsCheckbox"}> <Trans>ShowArchiveDevices</Trans></label>
            </div>
        </div>
    }
}

export default Settings
