import {Dropdown} from "rsuite";
import React from "react";

const DropdownTypeItem = (props) => {
    const icon = props.level.icon
    const isEdition = props.isEdition
    let isDisabled;
    if(isEdition){
        isDisabled = props.currentTypeIndex > props.level.level || props.lowerChildLevelIndex <= props.level.level
    }else{
        isDisabled = props.currentTypeIndex >= props.level.level
    }
    return <>
        <Dropdown.Item onSelect={() => props.onSelectType(props.level)} disabled={ isDisabled} icon={<div style={{display: "inline"}}><i className={`fa ${icon}`}/> </div>}> {props.level.nodeType}</Dropdown.Item>
        {props.level.child !== undefined ? <DropdownTypeItem {...props}  level={props.level.child}/> : undefined}
    </>
}

export default DropdownTypeItem;
