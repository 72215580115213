import React from "react"
import PropTypes from "prop-types"
import {getPathString} from "../../helpers/FactoriesHelper";
import {Trans} from "react-i18next";
import classes from "../Dashboard.module.css";
import OKIcon from "../../assets/img/ok.svg";

const modacsUpdatesTime = (props) => {

    const badConfiguredModacs = props.modacs.filter(modac => {
            // Display only active modacs.
            return modac.status === "ACTIVE" && modac.gsi1sk.includes(props.nodePath)
        }).filter(modac => {
            const lastReceivedEventDateString = modac.last_received_event
            const lastReceivedEventTimestamp = Date.parse(lastReceivedEventDateString)
            const now = Date.now()
            const diff = now-lastReceivedEventTimestamp
            if(modac.log_type === "REALTIME"){
                return diff > (1000 * 60 * 60) // < 1h
            }else {
                return diff > (1000 * 60 * 60 * 24 * 7) // < 7j
            }

        })

    if(badConfiguredModacs.length > 0) {

        return (
            <table>
            <thead>
            <tr>
                <th><Trans>Location</Trans></th>
                <th><Trans>Date</Trans></th>
            </tr>
            </thead>
            <tbody>

            {badConfiguredModacs.map(modac => {
                let pathString = getPathString(props.factories, modac.gsi1sk, props.nodePath, false)
                pathString += pathString.length === 0 ? "" : " > "
                pathString += modac.reference

                return <tr key={pathString}>
                    <td>{pathString}</td>
                    <td>{new Date(Date.parse(modac.last_received_event)).toLocaleDateString('fr-FR', {
                        hour: 'numeric',
                        hour12: false,
                        minute: "numeric"
                    })}

                    </td>
                    </tr>
                })
            }
            </tbody>
            </table>
        )
    }
    return (
        <div className={classes.DashboardSupervisionOKContainer}>
            <img src={OKIcon} alt={"OK"}/>
            <label> <Trans>AllDataAreUpToDate</Trans></label>
        </div>)
}

modacsUpdatesTime.propTypes = {
    factories: PropTypes.any.isRequired,
    modacs: PropTypes.array.isRequired,
    nodePath: PropTypes.string.isRequired,
    className: PropTypes.any
}

export default modacsUpdatesTime;
