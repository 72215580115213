export const getPathString = (orga, nodePath, relativeToNodePath, includeCurrentNode = true, separator = ">") => {
    const nodes = nodePath.split('|')
    let result = "";
    let currentPath = ""
    for(let i = 0 ; i < nodes.length ; i++){
        if(currentPath.length > 0){
            currentPath += "|";
        }
        currentPath += nodes[i];
        if(relativeToNodePath === undefined || (currentPath.includes(relativeToNodePath) && (currentPath !== relativeToNodePath || includeCurrentNode === true))){
            result += getVarPath(orga, currentPath).val + (i !== (nodes.length-1) ? ` ${separator} ` : "")
        }
    }
    return result
}

export const getModacReference = (orga, nodePath, modacMacAddr) => {
    const node = getVarPath(orga, nodePath)
    const modacsNode = node["modacs"]
    if(modacsNode !== undefined){
        const foundModac = modacsNode.find(it => it["macaddr"] === modacMacAddr)
        if(foundModac !== undefined){
            return foundModac["reference"]
        }
    }
    return ""
}

export const getChildKeys = (obj) => {
    const isUUIDRegex = RegExp(/^[0-9a-f]{8}-[0-9a-f]{4}-[0-5][0-9a-f]{3}-[089ab][0-9a-f]{3}-[0-9a-f]{12}$/i)
    return Object.keys(obj).filter(key => {
        if(isUUIDRegex.test(key)){
            return true
        }
        return key.startsWith("mediaspot-") || key.startsWith("modac-") || key.startsWith("radiameter-") || key.startsWith("probe-")
    })
}

export const findPathOfParentKey = (obj, currentPath, parentKey) => {
    const childKeys = getChildKeys(obj)
    if(obj.id !== undefined && obj.id.replace("|", "_@_") === parentKey){
        return currentPath
    }
    let result = undefined
    childKeys.forEach(key => {
        const foundParentKey = findPathOfParentKey(obj[key], currentPath + "|" + key, parentKey)
        if(foundParentKey !== undefined){
            result = foundParentKey
        }
    })
    return result

}

export const getAllStoreNodes = (node, callerPath) => {

    const childKeys = getChildKeys(node)
    let storeNodes = []

    childKeys.forEach(it => {
        if(node[it].isStore === true){
            node[it].path = callerPath + "|" + it
            storeNodes.push(node[it])
        }

        const childsStore = getAllStoreNodes(node[it], callerPath + "|" + it)
        storeNodes = storeNodes.concat(childsStore)
    })
    return storeNodes
}

export const getAllFlatedNodes = (node, includeRootNode) => {
    const childKeys = getChildKeys(node)
    let nodes = []
    if(includeRootNode === true){
        const copyOfCurrentNode = {...node}
        const childKeyOfNodeToAdd = getChildKeys(copyOfCurrentNode)
        childKeyOfNodeToAdd.forEach(keyToDelete => {
            delete copyOfCurrentNode[keyToDelete]
        })
        nodes.push(copyOfCurrentNode)
    //console.log(copyOfCurrentNode)
    }

    childKeys.forEach(it => {
        let nodeToAdd = {...node[it]}
        const childKeyOfNodeToAdd = getChildKeys(nodeToAdd)
        childKeyOfNodeToAdd.forEach(keyToDelete => {
            delete nodeToAdd[keyToDelete]
        })

        nodes.push(nodeToAdd)
        const childsStore = getAllFlatedNodes(node[it])
        nodes = nodes.concat(childsStore)
    })
    return nodes
}


export const getVarPath = (obj, key) => {
    let result = {};
    let src = obj,     // inner source object
        dest = result, // inner destination object
        parts = key.split(/\|/);

    if (src === undefined){
        return ""
    }

    parts.forEach(function(part) {

        if(src[part] !== undefined){
            // if we're looking at an object, make sure it exists in the dest
            if (typeof(src[part]) === "object")
                dest[part] = dest[part] || {};
            // if it's just a value, copy it
            else
                dest[part] = src[part];

            dest = dest[part]; // move from obj to obj.product, then to obj.product.candidate, etc.
            src = src[part];

        }


    });

    return src;
}


