export const getDataset = (dataParam) => {
    const colors = ["#2196f3", "#FFDE03", "#48b174", "#bc5038", "#000000", "#e876fe", "#4ed7fe", "#fe9700", "#00feda", "#b4b4b4", "#8d492d", "#357951", "#79006e"]

    if(dataParam !== undefined && dataParam.aggregations !== undefined && dataParam.aggregations.useragents !== undefined && dataParam.aggregations.useragents.buckets){
        const buckets = dataParam.aggregations.useragents.buckets

        let datasets = [{data:[], backgroundColor: []}]
        let labels = []
        if(dataParam.hits !== undefined && dataParam.hits.total !== undefined && dataParam.hits.total.value !== undefined){
            const totalHits = dataParam.hits.total.value
            buckets.forEach(code => {
                const key = code.key
                const value = code.doc_count
                datasets[0].data.push(((value * 100)/totalHits).toFixed(2))
                datasets[0].backgroundColor.push(colors[datasets[0].data.length])
                labels.push(key)

            })
        }else {
            return {
                error: "invalid input to generate dataset"
            }
        }
        return {
            datasets: datasets,
            labels: labels,
        }
    }

    return {
        error: "invalid input to generate dataset"
    }
}
