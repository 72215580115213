export const getDataset = (dataParam) => {
    if(dataParam !== undefined && dataParam.data !== undefined && dataParam.data.aggregations !== undefined && dataParam.data.aggregations.date !== undefined && dataParam.data.aggregations.date.buckets !== undefined){

        const buckets = dataParam.data.aggregations.date.buckets
        const jsonDataset = {backgroundColor: "#2196f3", label: "JSON", data: []}
        const mediasDataset = {backgroundColor: "#FFDE03", label: "Médias", data: []}
        let dates = []
        let maxValue = 0
        buckets.forEach(it => {
            if(it.key_as_string !== undefined && it.types !== undefined && it.types.buckets){
                const date = new Date(it.key_as_string)
                dates.push(date)

                if(it.types.buckets.Json.doc_count + it.types.buckets.mp4.doc_count > maxValue){
                    maxValue = it.types.buckets.Json.doc_count + it.types.buckets.mp4.doc_count
                }

                jsonDataset.data.push({
                    x: date,
                    y: it.types.buckets.Json.doc_count
                })

                mediasDataset.data.push({
                    x: date,
                    y: it.types.buckets.mp4.doc_count
                })
            }
        })

        return {
            datasets: [jsonDataset, mediasDataset],
            dates: dates,
            maxValue: maxValue
        }
    }

    return {
        error: "invalid input to generate dataset"
    }
}
