import React, {Component} from "react"
import classes from "./DissociationModalContainer.module.css"
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faUnlink} from "@fortawesome/free-solid-svg-icons";
import PropTypes from "prop-types"
import {Trans} from "react-i18next";
import Loader from 'react-loader-spinner'
import {dissociateDevice} from "../../requests/FactoriesRequests";
import {getClientUUID} from "../../helpers/AuthHelper";

class DissociationModal extends Component {

    state = {
        /***
         * If only one store node, we automatically set this node as destination, else we don't set it to let user choose
         */
        targetNode: (this.props.storeNodes !== undefined && this.props.storeNodes.length === 1 ? this.props.storeNodes[0] : undefined),

        dissociationInProgress: false,
    }

    onDissociateRequest = async() => {

        this.setState({dissociationInProgress: true}, async() => {
            if(this.state.targetNode !== undefined){
                const dissociationResponse = await dissociateDevice(this.props.dissociationNode.id, this.props.dissociationNode.nodePath, this.state.targetNode.path, getClientUUID())

                await this.setState({dissociationInProgress: false})

                if(dissociationResponse.error !== undefined){
                    alert("dissociation request error")
                    return;
                }

                this.props.onDissociationSuccess(this.state.targetNode.path)
            }
        })
    }

    onDismissClick = () => {
        if(this.state.dissociationInProgress === false){
            this.props.onDismissModal()
        }
    }

    onStockAreaChanged = (event) => {
        const index = event.target.value
        this.setState({targetNode: this.props.storeNodes[index]})
    }

    render() {
        return <>
            <div className={classes.PopupOverlay} onClick={this.props.onDismissModal}/>

            <div className={classes.DissociationModalPopupContainer}>
                <div className={classes.DissociationModalPopupContent}>
                    <label className={classes.DissociationModalPopupTitle}><Trans>Confirmation</Trans></label>

                    <label><Trans>AreYouSureYouWantToDissociate</Trans> "{this.props.dissociationNode.val}" ?</label>

                    {this.props.storeNodes.length > 1 ?
                        <>
                            <label style={{display: "block"}}><Trans>PleaseSelectAStockAreaToPutDeviceYouWantToDissociate</Trans></label>
                            <select onChange={this.onStockAreaChanged} disabled={this.state.dissociationInProgress === true} className={classes.DissociationModalStoreNodeSelect}>
                                <option disabled selected value>Select an option</option>
                                {
                                    this.props.storeNodes.map((node, index) => {
                                        return <option value={index}>{node.val}</option>
                                    })
                                }
                            </select>
                        </>
                        : undefined}

                    {this.state.dissociationInProgress
                        ? <Loader style={{marginTop: "15px", paddingTop: "12px", paddingBottom: "12px"}} type={"Oval"} width={25} height={25} color={"#0095ff"}/>
                        : <button disabled={this.state.targetNode === undefined || this.state.dissociationInProgress === true} onClick={this.onDissociateRequest} className={classes.DissociationModalValidateButton}><FontAwesomeIcon icon={faUnlink} size={"sm"}/> <Trans>Dissociate</Trans></button>}
                </div>
            </div>
        </>
    }
}

DissociationModal.propTypes = {
    storeNodes: PropTypes.array.isRequired,
    dissociationNode: PropTypes.any.isRequired,
    onDismissModal: PropTypes.func,

    onDissociationSuccess: PropTypes.func.isRequired
}


export default DissociationModal;
