import React, {Component} from "react"
import classes from "../Dashboard.module.css"

import Interval from "../../requests/elastic_search/Interval"
import {subDays} from 'date-fns/fp'
import Graph, {GraphType} from "../graphs/GraphComponent";

import 'react-tree-graph/dist/style.css'
import '../graphs/custom_graph.less'

import '../../../node_modules/react-grid-layout/css/styles.css';

import {Responsive, WidthProvider} from "react-grid-layout";
import "../grid_override.css"

import Tabs from "../../navigation/tabs/Tabs";
import Tree from "../tree/Tree";
import {getOrganization} from "../../requests/FactoriesRequests";
import Card, {CardProps} from "../card/Card";
import Chart from "chart.js"
import i18n from "../../i18n"
import {Trans} from "react-i18next"

const ResponsiveReactGridLayout = WidthProvider(Responsive);
const originalLayouts = getFromLS("layouts") || {};

class DashboardMedias extends Component {

    constructor(props) {
        super(props);
        const now = new Date(Date.now())
        const oneDayAgo = subDays(1, now)
        oneDayAgo.setHours(0,0,0,0);

        this.state = {
            interval: Interval.HOUR,

            startDatePeriodTimestamp: oneDayAgo.getTime(),
            endDatePeriodTimestamp: now.getTime(),

            layouts: JSON.parse(JSON.stringify(originalLayouts)),
        }
    }

    async componentDidMount() {
        Chart.plugins.register({
            afterDraw: function(chart) {
                if (chart.data.datasets[0].data.length === 0) {
                    const ctx = chart.chart.ctx;
                    const width = chart.chart.width;
                    const height = chart.chart.height;
                    chart.clear();

                    ctx.save();
                    ctx.textAlign = 'center';
                    ctx.textBaseline = 'middle';
                    ctx.fillText(i18n.t("NoDataOnThisPeriod"), width / 2, height / 2);
                    ctx.restore();
                }
            }
        });


        const factoriesResponse = await getOrganization()
        if(factoriesResponse.error !== undefined){
            this.setState({
                factoriesLoading: false,
                factoriesError: factoriesResponse.error
            })
            return
        }
        this.setState({
            factories: factoriesResponse.factories,
        })
    }


    onFilterTap = () => {
        this.setState({areFiltersVisible: !this.state.areFiltersVisible})
    }

    onPeriodChange = (dates) => {
        if(dates.length === 0){
            this.setState({
                startDatePeriodTimestamp: undefined,
                endDatePeriodTimestamp: undefined
            })
        }else{
            const endDate = dates[1].getTime() + (24 * 60 * 60 * 1000) - 1; // "Returns date at 00h00, so we had seconds to get same date at 23h59
            this.setState({
                interval: (endDate - dates[0]) > (1000 * 60 * 60 * 24 * 2) ? Interval.DAY : Interval.HOUR,
                startDatePeriodTimestamp: dates[0].getTime(),
                endDatePeriodTimestamp: endDate
            })
        }
    }

    onIntervalChanged = (interval) => {
        const currentInterval = this.state.interval
        if(interval !== currentInterval){
            this.setState({interval: interval})
        }
    }

    onFilterTap = () => {
        this.setState({areFiltersVisible: !this.state.areFiltersVisible})
    }

    onSelectArea = (area, nodeId, nodePath) => {
        this.setState({selectedArea: area, selectedNodeId: nodeId, selectedAreaPath: nodePath})

    }

    onLayoutChange = (layout, layouts) => {
        saveToLS("layouts", layouts);
        this.setState({ layouts });
    }

    onFactoriesLoaded = (factories) => {
        this.setState({factories: factories})
    }

    onSelectModac = (modacNode, nodeId, nodePath) => {
        if(window.location.pathname === "/dashboard/operations/supervision"){
            this.setState({selectedModac: modacNode})
        }else {
            this.onSelectArea(modacNode, nodeId, nodePath)
        }
    }

    render(){
        return (
            <>
                <Tabs noPadding startDatePeriodTimestamp={this.state.startDatePeriodTimestamp} endDatePeriodTimestamp={this.state.endDatePeriodTimestamp} onPeriodChange={this.onPeriodChange} onIntervalChanged={this.onIntervalChanged} interval={this.state.interval} tabs={[]} onFilterTap={this.onFilterTap} />

                <div className={classes.DashboardContainer}>
                    <div className={classes.DashboardDevicesTreeContainer}>
                        <Tree hideArchiveDevices={false} hideVisibleNodes={true} hideModacs onFactoriesLoaded={this.onFactoriesLoaded}  onSelectModac={this.onSelectModac} alreadySelectedNodeId={this.state.selectedNodeId} onSelect={this.onSelectArea} />
                    </div>

                    <ResponsiveReactGridLayout
                        rowHeight={100}
                        style={{position: "relative", marginTop: this.state.areFiltersVisible ? "200px" : "45px"}}
                        cols={{ lg: 12, md: 12, sm: 12, xs: 4, xxs: 2 }}
                        layouts={this.state.layouts}
                        onLayoutChange={this.onLayoutChange}
                        compactType={"horizontal"}>

                        <div key={"sync_count"} data-grid={{ w: 3, h: 1, x: 0, y: 0, minW: 2, maxH:1 }}>
                            <Card infoType={CardProps.SYNC_COUNT} startDatePeriodTimestamp={this.state.startDatePeriodTimestamp} endDatePeriodTimestamp={this.state.endDatePeriodTimestamp} />
                        </div>
                        <div key={"error_percentage"} data-grid={{ w: 3, h: 1, x: 3, y: 0, minW: 2, maxH:1 }}>
                            <Card infoType={CardProps.CLIENTS_ERROR_PERCENT} startDatePeriodTimestamp={this.state.startDatePeriodTimestamp} endDatePeriodTimestamp={this.state.endDatePeriodTimestamp} />
                        </div>

                        <div className={classes.ChartContainer} key={"playback_count_per_content_type_evolution"} data-grid={{ w: 5, h: 3, x: 0, y: 1, minW: 3, minH: 3 }}>
                            <label><Trans>PlaybacksCountPerContentType</Trans></label>
                            <div className={classes.ChartTitleSeparator}/>
                            <div className={classes.ChartGraphContainer}>
                                <Graph graphType={GraphType.PLAYBACK_COUNT_PER_CONTENT_TYPE_EVOLUTION} interval={this.state.interval} startDatePeriodTimestamp={this.state.startDatePeriodTimestamp} endDatePeriodTimestamp={this.state.endDatePeriodTimestamp} />

                            </div>
                        </div>

                        <div className={classes.ChartContainer} key={"playback_count_per_device_type_evolution"} data-grid={{ w: 5, h: 3, x: 5, y: 1, minW: 3, minH: 3 }}>
                            <label><Trans>PlaybacksCountPerDeviceType</Trans></label>
                            <div className={classes.ChartTitleSeparator}/>
                            <div className={classes.ChartGraphContainer}>
                                <Graph graphType={GraphType.PLAYBACK_COUNT_PER_DEVICE_TYPE_EVOLUTION} interval={this.state.interval} startDatePeriodTimestamp={this.state.startDatePeriodTimestamp} endDatePeriodTimestamp={this.state.endDatePeriodTimestamp} />
                            </div>
                        </div>

                        <div className={classes.ChartContainer} key={"http_codes_distribution"} data-grid={{ w: 3, h: 3, x: 0, y: 4, minW: 3, minH: 3 }}>
                            <label><Trans>HTTPCodesDistribution</Trans></label>
                            <div className={classes.ChartTitleSeparator}/>
                            <div className={classes.ChartGraphContainer}>
                                <Graph graphType={GraphType.HTTP_CODES_DISTRIBUTION} interval={this.state.interval} startDatePeriodTimestamp={this.state.startDatePeriodTimestamp} endDatePeriodTimestamp={this.state.endDatePeriodTimestamp} />
                            </div>
                        </div>

                        <div className={classes.ChartContainer} key={"browsers_distribution"} data-grid={{ w: 3, h: 3, x: 1, y: 4, minW: 3, minH: 3 }}>
                            <label><Trans>BrowsersDistribution</Trans></label>
                            <div className={classes.ChartTitleSeparator}/>
                            <div className={classes.ChartGraphContainer}>
                                <Graph graphType={GraphType.BROWSERS_DISTRIBUTION} interval={this.state.interval} startDatePeriodTimestamp={this.state.startDatePeriodTimestamp} endDatePeriodTimestamp={this.state.endDatePeriodTimestamp} />
                            </div>
                        </div>

                        <div className={classes.ChartContainer} key={"hits_evolution"} data-grid={{ w: 10, h: 3, x: 0, y: 7, minW: 3, minH: 3 }}>
                            <label><Trans>HitsCountEvolution</Trans></label>
                            <div className={classes.ChartTitleSeparator}/>
                            <div className={classes.ChartGraphContainer}>
                                <Graph graphType={GraphType.HITS_EVOLUTION} interval={this.state.interval} startDatePeriodTimestamp={this.state.startDatePeriodTimestamp} endDatePeriodTimestamp={this.state.endDatePeriodTimestamp} />
                            </div>
                        </div>

                    </ResponsiveReactGridLayout>
                </div>
            </>
        )
    }
}

function getFromLS(key) {
    let ls = {};
    if (localStorage) {
        try {
            ls = JSON.parse(localStorage.getItem("rgl-contents")) || {};
        } catch (e) {
            /*Ignore*/
        }
    }
    return ls[key];
}

function saveToLS(key, value) {
    if (localStorage) {
        localStorage.setItem("rgl-contents",
            JSON.stringify({
                [key]: value
            })
        );
    }
}

export default DashboardMedias;
