import {adaptQuery} from "../../helpers/elasticSearchQueryHelper";
import nucleotApi, {aviorDefaultElasticSearchHeader} from "../axios";
import {config} from "../../config";

export const getAviorsAvailabilityPerModacs = async(optionalStartDateTimestamp, optionalEndDateTimestamp, nodePath) => {
    try {
        const body = {
            "size": 0,
            "aggs": {
                "modac_path_agg": {
                    "terms": {
                        "field": "modac_path.keyword"
                    },
                    "aggs": {

                        "modac_macaddr_agg": {
                            "terms": {
                                "field": "modac_macaddr.keyword"
                            },
                            "aggs": {
                                "availability": {
                                    "filters": {
                                        "filters": {
                                            "available": {
                                                "query_string": {
                                                    "query": "modac_rs485_status:1",
                                                    "analyze_wildcard": true,
                                                    "default_field": "*"
                                                }
                                            },
                                            "not_available": {
                                                "query_string": {
                                                    "query": "modac_rs485_status:0",
                                                    "analyze_wildcard": true,
                                                    "default_field": "*"
                                                }
                                            }
                                        }
                                    }
                                }
                            }
                        }
                    }
                }
            },
            "query": {
                "bool": {
                    "must": [],
                    "must_not": []
                }
            }
        }

        adaptQuery(body, undefined, optionalStartDateTimestamp, optionalEndDateTimestamp, nodePath, undefined, undefined)

        const response = await nucleotApi.post(config.API_URL + config.ELASTIC_SEARCH_ENDPOINT_PATH, body, {
            headers: aviorDefaultElasticSearchHeader
        })
        if(response.data !== undefined){
            return {
                error: undefined,
                data: response.data
            }
        }
        return {
            error: new Error("no data in response")

        }
    }catch(e){
        return {
            error: e
        }
    }
}
