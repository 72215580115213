import React, {Component} from "react"
import classes from "./Header.module.css"
import {getUsername, logout} from "../../helpers/AuthHelper";
import {Trans} from "react-i18next"
import i18n from "../../i18n"
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faChevronDown, faUser} from "@fortawesome/free-solid-svg-icons";
import ReactFlagsSelect from 'react-flags-select';
import 'react-flags-select/css/react-flags-select.css';

import "./LanguageSelector.css"
import {Link} from "react-router-dom";

class Header extends Component {

    state = {
        isProfileSubmenuDisplayed: false
    }

    constructor(props) {
        super(props);

        this.wrapperRef = React.createRef();
        this.handleClickOutside = this.handleClickOutside.bind(this);
    }

    componentDidMount() {
        document.addEventListener('mousedown', this.handleClickOutside);
    }

    componentWillUnmount() {
        document.removeEventListener('mousedown', this.handleClickOutside);
    }

    handleClickOutside(event) {
        if (this.wrapperRef && !this.wrapperRef.current.contains(event.target)) {
            if(this.state.isProfileSubmenuDisplayed === true){
                this.setState({isProfileSubmenuDisplayed: false})
            }
        }
    }

    render() {


        let currentLanguage = i18n.fallbackLng
        // If user language already defined (by language detector)
        if (i18n.language !== undefined) {
            currentLanguage = i18n.language
        }

        if (i18n.lng !== undefined) {
            currentLanguage = i18n.lng
        }

        if (currentLanguage.toUpperCase().substr(0, 2) === "EN") {
            currentLanguage = "GB"
        }

        return (
            <div ref={this.wrapperRef} className={classes.HeaderContainer}>

                <ReactFlagsSelect
                    className="menu-flags"
                    onSelect={(it => {

                        i18n.changeLanguage(it.toLowerCase() === "gb" ? "en" : it.toLowerCase());
                        window.location.replace("/")
                    })} countries={["FR", "GB"]} customLabels={{"FR": "Français", "GB": "English"}}
                    defaultCountry={currentLanguage.substr(0, 2).toUpperCase()} showSelectedLabel={false}/>


                <div className={classes.HeaderProfileContainer} onClick={() => { this.setState({ isProfileSubmenuDisplayed: !this.state.isProfileSubmenuDisplayed}) }
                }>
                    <div className={classes.HeaderProfileContent}>
                        {/*<img className={classes.HeaderProfileButton} alt={"profile"} src={ProfileImage}/>*/}
                        <FontAwesomeIcon size={"1x"} icon={faUser}/>
                        <label><FontAwesomeIcon icon={faChevronDown}/></label>
                        <label style={{display: "block"}}>{getUsername()}</label>
                    </div>

                </div>

                {this.state.isProfileSubmenuDisplayed === true ?
                    <>
                    <div className={classes.HeaderSettingsContainer}>
                            <div className={classes.HeaderSettingsContent}>
                                {/*<img className={classes.HeaderLogoutButton} alt={"logout"} src={LogoutImage} />*/}
                                <label style={{display: "block"}}><Link to={"/settings"} onClick={() => this.setState({isProfileSubmenuDisplayed: false})}><Trans>Settings</Trans></Link></label>

                            </div>
                    </div>
                    <div className={classes.HeaderLogoutContainer} onClick={() => {
                        if (window.confirm(i18n.t("AreYouSureYouWantToLogout"))) {
                            logout()
                            window.location.replace("/")
                        }
                    }}>
                            <div className={classes.HeaderLogoutContent}>
                                {/*<img className={classes.HeaderLogoutButton} alt={"logout"} src={LogoutImage} />*/}
                                <label style={{display: "block"}}><Trans>Logout</Trans></label>
                            </div>
                    </div>
                    </>
                :undefined}
            </div>
        )
    }
}

export default Header;
