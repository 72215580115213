import React from 'react';
import ReactDOM from 'react-dom';
import Login from "./auth/Login";
import Logout from "./auth/Logout";
import {BrowserRouter, Route} from 'react-router-dom';

import Router from "./Router"
import {I18nextProvider} from "react-i18next";
//@ts-ignore
import i18n from './i18n'
import {DndProvider} from "react-dnd";
import {HTML5Backend} from "react-dnd-html5-backend";
import {TouchBackend} from "react-dnd-touch-backend";
import MultiBackend, { TouchTransition } from 'react-dnd-multi-backend';

import CustomDragLayer from "./admin/admin_areas/CustomDragLayer"

const HTML5toTouch = {
    backends: [
        {
            backend: HTML5Backend,
        },
        {
            backend: TouchBackend, // Note that you can call your backends with options
            options:{enableMouseEvents: true, enableTouchEvents: true},
            preview: true,
            transition: TouchTransition,
        },
    ],
};

const app =  (

    <I18nextProvider i18n={ i18n }>
        <DndProvider backend={MultiBackend} options={HTML5toTouch}>
            <CustomDragLayer />
            <BrowserRouter>
                <>
                    <Router/>
                    <Route exact path="/login" render={() => <Login />} />
                    <Route exact path="/logout" render={() => <Logout />} />
                </>
            </BrowserRouter>

        </DndProvider>
    </I18nextProvider>
)

ReactDOM.render(app, document.getElementById('root'));
