import "chartjs-plugin-annotation"
import i18n from "../../../i18n"
//import {getI18n} from "react-i18next";

const CONTROLS_REPARTITION_LEVEL = {
    NOT_COMPLIANT: {
        key: "NOT_COMPLIANT",
        color: "red",
        text: i18n.t("NotCompliant"),
        onHoverValueSuffix: i18n.t("notCompliants"),
        labelXOffset: -15,
        value: 0.5,
        minValue: 0, // Use to determinate percent
        maxValue: 90
    },
    CORRECT: {
        key: "CORRECT",
        color: "orange",
        text: i18n.t("Correct"),
        onHoverValueSuffix: i18n.t("corrects"),
        labelXOffset: 0,
        value: 5.5,
        minValue: 90,
        maxValue: 120
    },
    COMPLIANT: {
        key: "COMPLIANT",
        color: "green",
        text: i18n.t("Compliant"),
        onHoverValueSuffix: i18n.t("compliants"),
        labelXOffset: 0,
        value: 11.5,
        minValue: 120,
        maxValue: 300
    }
}

export const graphProps = (labels, values) => {
    const props = {
        type: 'line',
        data: {},
        labels: labels,
        options: {
            responsive: true,
            maintainAspectRatio: false,
            legend: {
                display: false
            },
            pan: {
                enabled: false,
                mode: 'x',
            },
            tooltips: {
                callbacks: {
                    title: function(tooltipItem, data) {
                        if(data !== undefined && data["labels"] !== undefined && tooltipItem !== undefined && tooltipItem.length > 0 && tooltipItem[0]["index"] !== undefined && data['labels'][tooltipItem[0]['index']]){
                            const value = data['labels'][tooltipItem[0]['index']]
                            return `${i18n.t("Between")} ${parseFloat(value)-5}-${parseFloat(value) + 5} sec`
                        }
                        return ""
                    },
                    label: function(tooltipItem, data) {
                        if(data !== undefined && data["datasets"] !== undefined && data["datasets"].length > 0 && data["datasets"][0]["data"] !== undefined && tooltipItem !== undefined && tooltipItem['index'] !== undefined && data['datasets'][0]['data'][tooltipItem['index']] !== undefined){
                            return data['datasets'][0]['data'][tooltipItem['index']]+'%';
                        }
                        return ""
                    }
                }
            },
            zoom: {},
            scales: {
                xAxes: [
                    {
                        offset: true,
                        display: false,
                    },
                    {
                        id: 'xAxeSec',
                        type: 'linear',
                        position: 'right',
                        ticks: {
                            stepSize: 10,
                            max: 180,
                            min: 0
                        },
                        scaleLabel: {
                            display: true,
                            labelString: `${i18n.t("Duration")} (sec)`
                        }
                    }
                ],
                yAxes: [{
                    scaleLabel: {
                        display: true,
                        labelString: i18n.t('Percent')
                    },
                    ticks: {
                        max: 100,
                        min: 0,
                        stepSize: 20,
                    }
                }]
            },
            annotation: {
                events: ["click", "mouseenter", "mouseleave"],
                annotations: []
            },
        }
    }

    Object.values(CONTROLS_REPARTITION_LEVEL).forEach(controlsRepartitionLevel => {
        props.options.annotation.annotations.push(getAnnotationObj(controlsRepartitionLevel, values))
    })
    return props
}

const getAnnotationObj = (controlsRepartitionLevel, values) => {
    const levelMinLimit = controlsRepartitionLevel.minValue
    const levelMaxLimit = controlsRepartitionLevel.maxValue

    let percent = 0.0
    let currentIndex = 0
    if(values !== undefined && values.length > 0){
        values.forEach(value => {
            if(currentIndex >= levelMinLimit && currentIndex < levelMaxLimit){
                percent += parseFloat(value)
            }
            currentIndex += 10
        })
    }

    return {
        drawTime: "afterDatasetsDraw",
        id: "vline_" + controlsRepartitionLevel.key,
        type: "line",
        mode: "vertical",
        scaleID: "x-axis-0",
        value: controlsRepartitionLevel.value,
        borderColor: controlsRepartitionLevel.color,
        borderWidth: 1,
        label: {
            backgroundColor: controlsRepartitionLevel.color,
            content: controlsRepartitionLevel.text,
            enabled: true
        },
        onMouseenter: function(e) {
            if(percent !== undefined && percent.toFixed(2) !== undefined){
                this.chartInstance.options.annotation.annotations.push(
                    {
                        drawTime: "afterDatasetsDraw",
                        id: "hline4",
                        type: "line",
                        mode: "vertical",
                        scaleID: "x-axis-0",
                        value: controlsRepartitionLevel.value,
                        borderColor: controlsRepartitionLevel.color,
                        borderWidth: 1,
                        label: {
                            xAdjust: controlsRepartitionLevel.labelXOffset,
                            backgroundColor: "rgba(0,0,0,0.82)",
                            content: `${percent.toFixed(2)}% ${controlsRepartitionLevel.onHoverValueSuffix}`,
                            enabled: true,
                            position: "top"
                        }
                    });
                this.chartInstance.update();

            }
        },
        onMouseleave: function (e) {
            this.chartInstance.options.annotation.annotations.pop()
            this.chartInstance.update();
        }
    }
}
