import React, {Component} from "react"


import {Dropdown} from "rsuite";
import PropTypes from 'prop-types';

import classes from "./NewArea.module.css"
import Loader from "react-loader-spinner";
import DropdownTypeItem from "./DropdownTypeItem"
import {addNode, editNode} from "../../requests/FactoriesRequests";
import {isSuperAdmin} from "../../helpers/AuthHelper";
import {getI18n, Trans} from "react-i18next";

class NewArea extends Component {

    state = {
        isLoading: false,
        label: this.props.label !== undefined ? this.props.label : "",
        selectedLevelType: this.props.type,
        reserve: false
    }

    constructor(props) {
        super(props);
        this.wrapperRef = React.createRef();
        this.handleClickOutside = this.handleClickOutside.bind(this);

        // If no type selected, select default type (first type child type)
        if(this.state.selectedLevelType === undefined){
            this.state.selectedLevelType = this.findDefaultLevel()
        }
    }

    findDefaultLevel = () => {
        const firstLevelIndex = this.props.parentLevelIndex + 1
        let level = this.props.types
        for(let i = 0 ; i < firstLevelIndex ; i++){
            level = level.child
        }
        return level
    }

    componentDidMount() {
        document.addEventListener('mousedown', this.handleClickOutside);
        document.addEventListener('keydown', this.handleEscKeyDown, false)
    }

    componentWillUnmount() {
        document.removeEventListener('mousedown', this.handleClickOutside);
        document.removeEventListener('keydown', this.handleEscKeyDown, false)
    }

    handleEscKeyDown = (event) => {
        // Esc key code
        if(event.keyCode === 27){
            if(this.props.onNodeEditionCancelled !== undefined){
                this.props.onNodeEditionCancelled()
            }
            if(this.props.onNodeAddingCancelled !== undefined){
                this.props.onNodeAddingCancelled()
            }
        }
    }

    handleClickOutside = async(event) => {
        if (this.wrapperRef && !this.wrapperRef.current.contains(event.target)) {
            await this.createArea()
        }
    }

    createArea = async() => {
        // If all conditions are OK --> validate
        if(this.state.selectedLevelType !== undefined && this.state.label !== undefined && this.state.label.trim() !== ""){
            //Check if component was created for edition or creation
            if(this.props.label !== undefined){
                //Edition
                this.setState({isLoading: true}, async() => {
                    const response = await editNode(this.props.path, this.state.label, this.state.selectedLevelType.nodeType, this.props.orgTypeUUID)
                    this.setState({isLoading: false})
                    if(response.error !== undefined){
                        alert(response.error);
                        return
                    }
                    this.props.onNodeEdited(this.state.label, this.state.selectedLevelType)
                })
            }else{
                //Creation
                this.setState({isLoading: true}, async() => {
                    const response = await addNode(this.state.label, this.state.selectedLevelType.nodeType, this.props.path, this.state.reserve, this.props.orgTypeUUID)
                    this.setState({isLoading: false})
                    if(response.error !== undefined || response.message === undefined || response.message.pk === undefined){
                        alert("Error during area creation")
                        return
                    }
                    this.props.onNewNodeCreated(response.message.pk.split('|')[1], this.state.label, this.state.selectedLevelType, this.state.reserve)
                })
            }

            return;
        }

        // If text is empty, it's considered as a cancellation
        if(this.state.label === undefined || this.state.label === "") {

            if(this.props.onNodeEdited !== undefined){
                this.props.onNodeEditionCancelled()
            }else{
                this.props.onNodeAddingCancelled()
            }
        }
    }

    onSelectLevelType = (levelType) => {
        this.setState({selectedLevelType: levelType})
    }

    handleLabelChanged = (event) => {
        this.setState({label: event.target.value})
    }

    handleSubmit = async(event) => {
        event.preventDefault()
        const labelRegex = RegExp(/^[a-zA-Z0-9éèàùçâêîôû()°\_\\\-\/ ,’'“”.:#@<>+*&]{1,50}$/)
        if(labelRegex.test(this.state.label)){
            await this.createArea()
        }else {
            alert(getI18n().t("AreaLabelNotConform"))
        }
    }

    render(){
        const types = this.props.types
        return (
            <div style={this.props.style} ref={this.wrapperRef} >
                <form onSubmit={this.handleSubmit} >

                    <Dropdown style={{zIndex: 100}} disabled={this.state.isLoading} title={this.state.selectedLevelType === undefined ? "-" : undefined} icon={this.state.selectedLevelType === undefined ? undefined : <div><i className={`fa ${this.state.selectedLevelType.icon}`}/></div>}>
                        {
                            <DropdownTypeItem lowerChildLevelIndex={this.props.lowerChildLevelIndex} onSelectType={this.onSelectLevelType} isEdition={this.props.onNodeEdited !== undefined} currentTypeIndex={this.props.parentLevelIndex} level={types}/>
                        }
                    </Dropdown>

                    <input autoFocus={true} maxLength={50} value={this.state.label} onChange={this.handleLabelChanged} disabled={this.state.isLoading} className={classes.NewAreaInput}/>

                    {
                        // If node creation, display checkbox to defined node as reserve node or not
                        (this.props.label === undefined && isSuperAdmin() === true) ? <>
                            <input className={classes.NewAreaReserveCheckbox} id={"reservenode"} onChange={() => this.setState({reserve: !this.state.reserve}) } type="checkbox" name="reserve" value={this.state.reserve} checked={this.state.reserve}/>
                            <label htmlFor={"reservenode"}> <Trans>StoreNode</Trans></label>
                        </> : undefined
                    }
                    {
                        this.state.isLoading ? (
                            <div className={classes.NewAreaLoader}>
                                <Loader type="Oval" color="#0095ff" height={25} width={25} />
                            </div>
                        ) : undefined
                    }
                </form>
            </div>
        )
    }
}

NewArea.propTypes = {
    onNewNodeCreated: PropTypes.func,
    onNodeAddingCancelled: PropTypes.func,
    onNodeEditionCancelled: PropTypes.func,
    onNodeEdited: PropTypes.func,

    /***
     * When addition => current path of node addition
     * When edition => current path to edit
     */
    path: PropTypes.string,

    /***
     * When edition, contains current label value
     */
    label: PropTypes.string,

    /***
     * When edition, contains current type level value
     */
    type: PropTypes.any,

    /***
     * Parent level index, used to limit higher type level
     */
    parentLevelIndex: PropTypes.number,

    /***
     * List of types
     */
    types: PropTypes.any,

    /***
     * Indicates if edition area already has area child to prevent to set same level as child
     */
    lowerChildLevelIndex: PropTypes.any,

    orgTypeUUID: PropTypes.string
}

export default NewArea;
