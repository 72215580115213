import React from "react"
import PropTypes from "prop-types"
import {getPathString} from "../../helpers/FactoriesHelper";
import {Trans} from "react-i18next";
import OKIcon from "../../assets/img/ok.svg"

import classes from "../Dashboard.module.css"
import {PROBE_NUMBER} from "../../requests/elastic_search/GetProbeInfos";

const badRadiationTypes = (props) => {

    const modacsInPath = props.modacs.filter(modac => modac.gsi1sk.includes(props.nodePath))

    const badModacsRadiationTypesP1Ch1 = modacsInPath.filter(modac => {return modac.status === "ACTIVE"}).filter(modac => {
        return modac.configSet.p1.ch1.expectedRadiationType !== modac.configDetected.p1.ch1.detectedRadiationType
    })

    const badModacsRadiationTypesP1Ch2 = modacsInPath.filter(modac => {return modac.status === "ACTIVE"}).filter(modac => {
        return modac.configSet.p1.ch2.expectedRadiationType !== modac.configDetected.p1.ch2.detectedRadiationType
    })

    const badModacsRadiationTypesP2Ch1 = modacsInPath.filter(modac => {return modac.status === "ACTIVE"}).filter(modac => {
        return modac.configSet.p2.ch1.expectedRadiationType !== modac.configDetected.p2.ch1.detectedRadiationType
    })
    const badModacsRadiationTypesP2Ch2 = modacsInPath.filter(modac => {return modac.status === "ACTIVE"}).filter(modac => {
        return modac.configSet.p2.ch2.expectedRadiationType !== modac.configDetected.p2.ch2.detectedRadiationType
    })

    if(badModacsRadiationTypesP1Ch1.length === 0 && badModacsRadiationTypesP1Ch2.length === 0 && badModacsRadiationTypesP2Ch1.length === 0 && badModacsRadiationTypesP2Ch2.length === 0){
        return (
            <div className={classes.DashboardSupervisionOKContainer}>
                <img src={OKIcon} alt={"OK"}/>
                <label> <Trans>AllProbeSettingsAreCorrect</Trans></label>
            </div>
        )
    }

    return (
        <table>
            <thead>
            <tr>
                <th><Trans>Location</Trans></th>
                <th><Trans>Input</Trans></th>
                <th><Trans>Expected</Trans></th>
                <th><Trans>Detected</Trans></th>
            </tr>
            </thead>
            <tbody>
            {
                badModacsRadiationTypesP1Ch1.map(modac => {
                    return <BadRadiationTypeRow key={modac.gsi1sk + "p1ch1"} nodePath={props.nodePath}  probe={PROBE_NUMBER.PROBE_1} channel={1} factories={props.factories} modac={modac} expected={modac.configSet.p1.ch1.expectedRadiationType} detected={modac.configDetected.p1.ch1.detectedRadiationType}/>
                })
            }
            {
                badModacsRadiationTypesP1Ch2.map(modac => {
                    return <BadRadiationTypeRow key={modac.gsi1sk + "p1ch2"} nodePath={props.nodePath} probe={PROBE_NUMBER.PROBE_1} channel={2} factories={props.factories} modac={modac} expected={modac.configSet.p1.ch2.expectedRadiationType} detected={modac.configDetected.p1.ch2.detectedRadiationType}/>
                })
            }
            {
                badModacsRadiationTypesP2Ch1.map(modac => {
                    return <BadRadiationTypeRow key={modac.gsi1sk + "p2ch1"} nodePath={props.nodePath} probe={PROBE_NUMBER.PROBE_2} channel={1} factories={props.factories} modac={modac} expected={modac.configSet.p2.ch1.expectedRadiationType} detected={modac.configDetected.p2.ch1.detectedRadiationType}/>
                })
            }
            {
                badModacsRadiationTypesP2Ch2.map(modac => {
                    return <BadRadiationTypeRow key={modac.gsi1sk + "p2ch2"} nodePath={props.nodePath} probe={PROBE_NUMBER.PROBE_2} channel={2} factories={props.factories} modac={modac} expected={modac.configSet.p2.ch2.expectedRadiationType} detected={modac.configDetected.p2.ch2.detectedRadiationType}/>
                })
            }
            </tbody>
        </table>
    )
}

const BadRadiationTypeRow = (props) => {
    let pathString = getPathString(props.factories, props.modac.gsi1sk, props.nodePath, false)
    pathString += pathString.length === 0 ? "" : " > "
    pathString += props.modac.reference

    return <tr className={classes.DashboardSupervisionErrorRow} key={props.modac.gsi1sk + props.probe.toString() + props.channel.toString()}>
        <td>{pathString}</td>
        <td><Trans>Input</Trans> {props.probe === PROBE_NUMBER.PROBE_1 ? "1" : "2"} CH {props.channel}</td>
        <td className={classes.DashboardSupervisionAlertRowValue}>{props.expected}</td>
        <td className={classes.DashboardSupervisionAlertRowValue}>{props.detected}</td>
    </tr>
}

badRadiationTypes.propTypes = {
    factories: PropTypes.any.isRequired,
    modacs: PropTypes.array.isRequired,
    className: PropTypes.any
}

export default badRadiationTypes;
