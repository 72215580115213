import nucleotApi, {aviorDefaultElasticSearchHeader} from "../axios";
import {config} from "../../config";
import {adaptQuery} from "../../helpers/elasticSearchQueryHelper";

const averageControlsDurationBody = (optionalStartDateTimestamp, optionalEndDateTimestamp, optionalModacMacAddr, optionalDeviceSerial, nodePath) => {
    let body = {
        "aggs": {

            "average_duration": {
                "avg": {
                    "field": "p1_measure_duration"
                }
            }


        },
        "size": 0,
        "_source": {
            "excludes": []
        },
        "stored_fields": [
            "*"
        ],
        "script_fields": {},
        "docvalue_fields": [
            {
                "field": "@timestamp",
                "format": "date_time"
            },
            {
                "field": "logdate",
                "format": "date_time"
            }
        ],
        "query": {
            "bool": {
                "must": [
                    {
                        "match_phrase": {
                            "p1_measure_status": {
                                "query": "3"
                            }
                        }
                    },
                    // {
                    //     "match_phrase": {
                    //         "p1_ch1_trans": {
                    //             "query": "BETA"
                    //         }
                    //     }
                    // }
                ],
                "filter": [],
                "should": [],
                "must_not": []
            }
        }
    }

    adaptQuery(body, undefined, optionalStartDateTimestamp, optionalEndDateTimestamp, nodePath, optionalModacMacAddr, optionalDeviceSerial)

    return body;
}

export const getAverageControlsDuration = async(optionalStartDateTimestamp, optionalEndDateTimestamp,  optionalModacMacAddr, optionalDeviceSerial, nodePath) => {
    try{
        const query = averageControlsDurationBody(optionalStartDateTimestamp, optionalEndDateTimestamp, optionalModacMacAddr, optionalDeviceSerial, nodePath)
        const response = await nucleotApi.post(config.API_URL + config.ELASTIC_SEARCH_ENDPOINT_PATH, query, {
            headers: aviorDefaultElasticSearchHeader
        })

        if(response.data !== undefined && response.data.aggregations.average_duration.value !== null){
            return {
                error: undefined,
                data: response.data.aggregations.average_duration.value
            }

        }
        return {
            error: new Error("no data in response")

        }

    } catch (e) {
        return {
            error: e
        }
    }
}
