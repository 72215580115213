import nucleotApi, {aviorDefaultElasticSearchHeader} from "../axios";
import {config} from "../../config";
import {adaptQuery} from "../../helpers/elasticSearchQueryHelper";

const measuresBody = (startDate, endDate, deviceSerial, modacMacAddr, start, size, nodePath) => {
    let body = {
        "from": start,
        "size": size,
        "_source": {
            "includes": ["p1_measure_status" ,"logdate", "p1_measure_duration", "DEVICE_SERIAL", "modac_macaddr", "p1_ch1_smooth", "p1_ch1_alarm", "modac_path"],
            "excludes": []
        },
        "sort": { "logdate": "desc"},
        "query": {
            "bool": {
                "must": [],
                "filter": [],
                "should": [
                    {
                        "match_phrase": {
                            "p1_measure_status": {
                                "query": "2"
                            }
                        }
                    },
                    {
                        "match_phrase": {
                            "p1_measure_status": {
                                "query": "3"
                            }
                        }
                    },
                    {
                        "match_phrase": {
                            "p1_measure_status": {
                                "query": "1"
                            }
                        }
                    }
                ],
                "minimum_should_match": 1,
                "must_not": []
            }
        }
    }
    adaptQuery(body, undefined, startDate, endDate, nodePath, modacMacAddr, deviceSerial)
    return body;
}

export const getMeasures = async(start, size, optionalStartDateTimestamp, optionalEndDateTimestamp, deviceSerial, modacMacAddr, nodePath) => {

    try{
        const query = measuresBody(optionalStartDateTimestamp, optionalEndDateTimestamp, deviceSerial, modacMacAddr, start, size, nodePath)
        const response = await nucleotApi.post(config.API_URL + config.ELASTIC_SEARCH_ENDPOINT_PATH, query, {
            headers: aviorDefaultElasticSearchHeader
        })

        if(response.data !== undefined && response.data.hits !== undefined && response.data.hits.hits !== undefined  && response.data.hits.hits.length > 0) {

            const sources = response.data.hits.hits.map(hit => {
                return hit._source
            })

            return {
                data: {
                    measures: sources,
                    totalCount: response.data.hits.total.value
                },
                error: undefined
            }
        }else {
            return {
                error: "no data in response"
            }
        }
    } catch (e) {
        return {
            error: e
        }
    }
}
