import nucleotApi, {aviorDefaultElasticSearchHeader} from "../axios";
import {config} from "../../config";
import {adaptQuery} from "../../helpers/elasticSearchQueryHelper";
import {PROBE_NUMBER} from "./GetProbeInfos";
import Interval from "./Interval";

const evolutionOfProbeAlarms = (interval, optionalStartDateTimestamp, optionalEndDateTimestamp, modacMacAddr, channel, probe, deviceSerial, nodePath) => {

    let body = {
        "aggs": {
            "date": {
                "date_histogram": {
                    "field": "logdate",
                    "interval": "1m",
                    "time_zone": "Europe/Berlin",
                    "min_doc_count": 1
                },
                "aggs": {
                    "alarm": {
                        "filters": {
                            "filters": {
                                "low": {
                                    "regexp":
                                        probe === PROBE_NUMBER.PROBE_1 ?
                                            (channel === 1 ? { "p1_ch1_alarm.keyword" : "LOW" } : { "p1_ch2_alarm.keyword" : "LOW" }) :
                                            (channel === 1 ? { "p2_ch1_alarm.keyword" : "LOW" } : { "p2_ch2_alarm.keyword" : "LOW" })

                                },
                                "high": {
                                    "regexp":
                                        probe === PROBE_NUMBER.PROBE_1 ?
                                            (channel === 1 ? { "p1_ch1_alarm.keyword" : "HIGH" } : { "p1_ch2_alarm.keyword" : "HIGH" }) :
                                            (channel === 1 ? { "p2_ch1_alarm.keyword" : "HIGH" } : { "p2_ch2_alarm.keyword" : "HIGH" })
                                },
                                "saturate": {
                                    "regexp":
                                        probe === PROBE_NUMBER.PROBE_1 ?
                                            (channel === 1 ? { "p1_ch1_alarm.keyword" : "SATURATE" } : { "p1_ch2_alarm.keyword" : "SATURATE" }) :
                                            (channel === 1 ? { "p2_ch1_alarm.keyword" : "SATURATE" } : { "p2_ch2_alarm.keyword" : "SATURATE" })
                                }
                            }
                        },
                        "aggs": {
                            "max_value": {
                                "max": probe === PROBE_NUMBER.PROBE_1 ?
                                    (channel === 1 ? { "field": "p1_ch1_smooth"  } : { "field": "p1_ch2_smooth"  }) :
                                    (channel === 1 ? { "field": "p2_ch1_smooth"  } : { "field": "p2_ch2_smooth"  })
                            }
                        }
                    }
                }
            }
        },
        "size": 0,
        "_source": {
            "excludes": []
        },
        "stored_fields": [
            "*"
        ],
        "script_fields": {},
        "query": {
            "bool": {
                "must": [
                    probe === PROBE_NUMBER.PROBE_1 ?
                        (channel === 1 ? { "match_phrase": {"p1_ch1_alarm_val" : {query: "2"} }} : { "match_phrase": {"p1_ch2_alarm_val" : {query: "2"} }}) :
                        (channel === 1 ? { "match_phrase": {"p2_ch1_alarm_val" : {query: "2"} }} : { "match_phrase": {"p2_ch2_alarm_val" : {query: "2"} }})
                ],
                "filter": [],
                "should": [],
                "must_not": [
                    probe === PROBE_NUMBER.PROBE_1 ?
                        (channel === 1 ? { "match_phrase": {"p1_ch1_alarm" : {query: "NONE"} }} : { "match_phrase": {"p1_ch2_alarm" : {query: "NONE"} }}) :
                        (channel === 1 ? { "match_phrase": {"p2_ch1_alarm" : {query: "NONE"} }} : { "match_phrase": {"p2_ch2_alarm" : {query: "NONE"} }})
                ]
            }
        }
    }

    adaptQuery(body, Interval.MINUTE, optionalStartDateTimestamp, optionalEndDateTimestamp, nodePath, modacMacAddr, deviceSerial)

    return body;
}

export const getEvolutionOfProbeAlarms = async(interval, optionalStartDateTimestamp, optionalEndDateTimestamp, modacMacAddr, channel, probe, deviceSerial, nodePath) => {
    if(probe !== PROBE_NUMBER.PROBE_1 && probe !== PROBE_NUMBER.PROBE_2){
        return {
            error: "Probe has to be PROBE_NUMBER.PROBE_1 OR PROBE_NUMBER.PROBE_2"
        }
    }

    if(channel !== 1 && channel !== 2){
        return {
            error: "channel has to be 1 or 2"
        }
    }

    if(modacMacAddr === undefined){
        return {
            error: "modacMacAddr has to be specified"
        }
    }
    try{
        const query = evolutionOfProbeAlarms(interval, optionalStartDateTimestamp, optionalEndDateTimestamp, modacMacAddr, channel, probe, deviceSerial, nodePath)
        const response = await nucleotApi.post(config.API_URL + config.ELASTIC_SEARCH_ENDPOINT_PATH, query, {
            headers: aviorDefaultElasticSearchHeader
        })
        return response.data
    } catch (e) {
        return {
            error: e
        }
    }
}
