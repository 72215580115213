import axios from "./axios";

export const postRequest = async(url, body) => {
    try{
        console.info(url)
        console.info(body)
        const response = await axios.post(url, body)

        if(response.data === undefined){
            return {
                error: `undefined data response for request on ${url}`
            }
        }
        return response.data;
    }catch(err){
        return {
            error: err
        }
    }
}