import i18n from "../i18n"

export const getTransmitterReadableString = (transmitter) => {
    if(transmitter === undefined){
        return ""
    }
    if(!(typeof transmitter === "string")){
        return ""
    }
    return i18n.t(transmitter.toLowerCase())

}
