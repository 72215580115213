import i18n from "../../../i18n"

export const getDataset = (dataParam) => {
    if(dataParam !== undefined && dataParam.hits !== undefined && dataParam.hits.total !== undefined && dataParam.hits.total.value !== undefined && dataParam.aggregations !== undefined && dataParam.aggregations.durations !== undefined && dataParam.aggregations.durations.buckets !== undefined){

        const totalMeasuresCount = dataParam.hits.total.value

        const buckets = dataParam.aggregations.durations.buckets
        const dataset = {
            backgroundColor: "rgba(255,220,3,0.31)",
            borderColor: "rgb(255,222,3)",
            label: `${i18n.t("ControlsDuration")} (%)`,
            data: []
        }

        const additionalDatasetBar = {
            backgroundColor: "rgba(27,130,255,0.31)",
            borderColor: "rgb(25,120,254)",
            label: `${i18n.t("ControlsDuration")} (%)`,
            type: "bar",
            data: [],
            categoryPercentage: 1.0,
            barPercentage: 0.90
        }

        let filtersKeys = Object.keys(buckets)
        filtersKeys = filtersKeys.sort((e1, e2) => {
            return e1 - e2;
        });


        let labels = []
        filtersKeys.forEach(filterKey => {
            labels.push(parseFloat(filterKey)+5)
            let percentValue = 0
            if(buckets[filterKey].doc_count !== 0){
                percentValue = (Math.round(buckets[filterKey].doc_count * 100) / totalMeasuresCount).toFixed(2)
            }
            dataset.data.push(percentValue)
            additionalDatasetBar.data.push(percentValue)
        })

        return {
            datasets: [dataset, additionalDatasetBar],
            labels: labels
        }
    }
    return {
        error: "invalid input to generate dataset"
    }
}
