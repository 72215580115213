import React from "react"
import PropTypes from "prop-types"
import {NodeType} from "../../admin_areas/NodeType";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faAtom, faMicrochip, faWeight, faWifi} from "@fortawesome/free-solid-svg-icons";
import {getI18n} from "react-i18next";
import {LogtypeParameter, StatusDevicesParameter} from "../../../requests/FactoriesRequests";
import DetailsIcon from "../../../assets/img/details.svg"

const inventoryDevice = (props) => {
    const deviceIcon = <>
        {props.device.type === NodeType.MEDIASPOT ? <FontAwesomeIcon fixedWidth={true} icon={faWifi}/> : undefined}
        {props.device.type === NodeType.MODAC ? <FontAwesomeIcon fixedWidth={true} icon={faMicrochip}/> : undefined}
        {props.device.type === NodeType.RADIAMETER ? <FontAwesomeIcon fixedWidth={true} icon={faWeight}/> : undefined}
        {props.device.type === NodeType.PROBE ? <FontAwesomeIcon fixedWidth={true} icon={faAtom}/> : undefined}
    </>
    return (
        <tr>
            <td>{deviceIcon}&nbsp;&nbsp;{props.device.device_type}</td>
            <td>{props.device.serial}</td>
            <td>{props.device.reference}</td>
            <td>{props.device.isDeployed === true ? getI18n().t("Yes") : getI18n().t("No")}</td>
            <td>{props.device.deliveredDate}</td>
            <td>{props.device.installationDate}</td>
            <td>{props.device.status !== undefined ? statusToString(props.device.status) : "-"}</td>
            <td>{props.device.log_type !== undefined ? logtypeToString(props.device.log_type) : "-"}</td>
            <td><img style={{height: "20px", cursor: "pointer"}} src={DetailsIcon} onClick={props.onSelect} alt={"details"}/></td>
        </tr>
    )
}

const statusToString = (status) => {
    if(status === undefined) return ""
    switch (status){
        case StatusDevicesParameter.ACTIVE : return getI18n().t("Active")
        case StatusDevicesParameter.INACTIVE : return getI18n().t("Inactive")
        case StatusDevicesParameter.IN_STOCK : return getI18n().t("InStock")
        case StatusDevicesParameter.ARCHIVE : return getI18n().t("Archive")
        default: return status
    }
}

const logtypeToString = (logtype) => {
    if(logtype === undefined) return ""
    switch (logtype){
        case LogtypeParameter.REALTIME : return getI18n().t("Realtime")
        //case LogtypeParameter.OFFLINE : return getI18n().t("Offline_en")
        case LogtypeParameter.OFFLINE : return "Offline"
        default: return logtype
    }
}

inventoryDevice.propTypes = {
    device: PropTypes.any,
    onSelect: PropTypes.func
}

export default inventoryDevice
