import {getI18n} from "react-i18next";

export const getDataset = (dataParam) => {
    if(dataParam !== undefined && dataParam.aggregations !== undefined && dataParam.aggregations.date !== undefined && dataParam.aggregations.date.buckets !== undefined){

        const buckets = dataParam.aggregations.date.buckets
        const lowDataset = {backgroundColor: "#FFDE03", label: getI18n().t("Low"), data: []}
        const highDataset = {backgroundColor: "#ffaa33", label: getI18n().t("High"), data: []}
        const saturateDataset = {backgroundColor: "#ff2709", label: getI18n().t("Saturate"), data: []}
        let dates = []
        buckets.forEach(it => {
            if(it.key_as_string !== undefined && it.alarm !== undefined && it.alarm.buckets !== undefined
                && it.alarm.buckets.low !== undefined && it.alarm.buckets.low.max_value !== undefined && it.alarm.buckets.low.max_value.value !== undefined
                && it.alarm.buckets.high !== undefined && it.alarm.buckets.high.max_value !== undefined && it.alarm.buckets.high.max_value.value !== undefined
                && it.alarm.buckets.saturate !== undefined && it.alarm.buckets.saturate.max_value !== undefined && it.alarm.buckets.saturate.max_value.value !== undefined){

                const date = new Date(it.key_as_string)
                dates.push(date)

                lowDataset.data.push(
                    {
                        x: date,
                        y: it.alarm.buckets.low.max_value.value !== null ? it.alarm.buckets.low.max_value.value.toFixed(3) : null
                    }
                )

                highDataset.data.push(
                    {
                        x: date,
                        y: it.alarm.buckets.high.max_value.value !== null ? it.alarm.buckets.high.max_value.value.toFixed(3) : null
                    }
                )

                saturateDataset.data.push(
                    {
                        x: date,
                        y: it.alarm.buckets.saturate.max_value.value !== null ? it.alarm.buckets.saturate.max_value.value.toFixed(3) : null
                    }
                )
            }

        })

        return {
            datasets: [lowDataset, highDataset, saturateDataset],
            dates: dates,
        }
    }
    return {
        error: "invalid input to generate dataset"
    }
}
