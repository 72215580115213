import React from "react"
import { useDragLayer } from 'react-dnd'
import {NodeType} from "./NodeType";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faWifi, faAtom, faMicrochip, faWeight} from "@fortawesome/free-solid-svg-icons";
import i18n from "../../i18n"

function getItemStyles(initialOffset, currentOffset) {
    if (!initialOffset || !currentOffset) {
        return {
            display: 'none',
        };
    }
    let { x, y } = currentOffset;
    const transform = `translate(${x}px, ${y}px)`;
    return {
        transform,
        WebkitTransform: transform,
        background: "rgba(0,0,0,0.80)",
        borderRadius: "15px",
        border: "3px solid black",
        color: "white",
        padding: "20px"
    };
}

function DragLayerComponent() {
    const layerStyles = {
        position: 'fixed',
        pointerEvents: 'none',
        zIndex: 100,
        left: 0,
        top: 0
    };

    const { isDragging, item, initialOffset, currentOffset } = useDragLayer(monitor => ({
        item: monitor.getItem(),
        itemType: monitor.getItemType(),
        initialOffset: monitor.getInitialSourceClientOffset(),
        currentOffset: monitor.getSourceClientOffset(),
        isDragging: monitor.isDragging(),
    }));


    if (!isDragging) {
        return null;
    }

    return <div style={layerStyles}>
        <div style={getItemStyles(initialOffset, currentOffset)}>
            {item.subtype === NodeType.MEDIASPOT ? <><FontAwesomeIcon icon={faWifi} color={"#FFFFFF"}/><label>&nbsp;&nbsp;{i18n.t("DropThisMediaspotOnAnAreaToBindIt")}</label></> : undefined}
            {item.subtype === NodeType.MODAC ? <><FontAwesomeIcon icon={faMicrochip} color={"#FFFFFF"}/><label>&nbsp;&nbsp;{i18n.t("DropThisModacOnAMediaspotToBindTt")}</label></> : undefined}
            {item.subtype === NodeType.RADIAMETER ? <><FontAwesomeIcon icon={faWeight} color={"#FFFFFF"}/><label>&nbsp;&nbsp;{i18n.t("DropThisRadiameterOnAModacToBindIt")}</label></> : undefined}
            {item.subtype === NodeType.PROBE ? <><FontAwesomeIcon icon={faAtom} color={"#FFFFFF"}/><label>&nbsp;&nbsp;{i18n.t("DropThisProbeOnARadiameterToBindIt")}</label></> : undefined}
        </div>
    </div>
}
export default DragLayerComponent
