import React, {Component} from "react"
import classes from "../Dashboard.module.css"
import '../graphs/custom_graph.less'

import Interval from "../../requests/elastic_search/Interval"

import DashboardDevices from "../devices/DashboardDevices"
import Tabs from "../../navigation/tabs/Tabs"
import {NavLink, Redirect, Route, Switch} from "react-router-dom";
import '../../../node_modules/react-grid-layout/css/styles.css';

import "../grid_override.css"
import DashboardEvents from "../events/DashboardEvents";

import {subDays, subHours} from 'date-fns/fp'
import DashboardMeasures from "../measures/DashboardMeasures";
import Tree from "../tree/Tree";
import i18n from "../../i18n"
import MeasuresList from "../measures/MeasuresList";

import ModacOverview from "../modac_overview/ModacOverview"

import {Trans} from "react-i18next"
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faChevronCircleLeft} from "@fortawesome/free-solid-svg-icons";
import DashboardSupervision from "../supervision/DashboardSupervision";
import ReactTooltip from "react-tooltip";

class DashboardOperations extends Component {

    constructor(props) {
        super(props)

        const now = new Date(Date.now())
        const oneDayAgo = subDays(1, now)
        if(!window.location.pathname.startsWith("/dashboard/operations/supervision")){
            oneDayAgo.setHours(0,0,0,0);
        }

        this.state = {
            isEvolutionOfCountOfPlaybackPerContentTypeBodyLoaded:false,
            interval: Interval.HOUR,

            startDatePeriodTimestamp: oneDayAgo.getTime(),
            endDatePeriodTimestamp: now.getTime(),

            showTree: false,

            tabs: [
                {
                    name: i18n.t("Supervision"),
                    path: "/dashboard/operations/supervision"
                },
                {
                    name: i18n.t("Measures"),
                    path: "/dashboard/operations/measures"
                },
                {
                    name: i18n.t("Events"),
                    path: "/dashboard/operations/events"
                },
                {
                    name: i18n.t("Devices"),
                    path: "/dashboard/operations/devices"
                }],
            selectedTab: undefined,

            selectedArea: undefined,
            selectedPath: undefined,
            selectedNodeId: undefined,

            currentBreakpoint: undefined,

            modacs: []
        };

    }

    async componentDidMount() {
        this.updateCurrentTabIfNeeded()
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        this.updateCurrentTabIfNeeded()

        // If tabs change and previous or new tab is supervision, we handle date filter (not same date filter on supervision and rest of portal)
        // In two cases, default value is "last 24 hours"
        if(prevState.selectedTab !== this.state.selectedTab &&  (this.state.selectedTab === this.state.tabs[0] || prevState.selectedTab === this.state.tabs[0])){
            const now = new Date(Date.now())
            const oneDayAgo = subHours(24, now)
            // For supervision tab, we get real last 24h. on other default is current days and past day until at 00:00
            if(this.state.selectedTab !== this.state.tabs[0]){
                oneDayAgo.setHours(0,0,0,0);
            }

            this.setState({
                startDatePeriodTimestamp: oneDayAgo.getTime(),
                endDatePeriodTimestamp: now.getTime(),
            })
        }
    }

    onFactoriesLoaded = (factories) => {
        this.setState({factories: factories})
    }

    updateCurrentTabIfNeeded = () => {
        let tab;
        switch(window.location.pathname){
            case "/dashboard/operations/supervision": {
                tab = {...this.state.tabs[0]}
                break
            }
            case "/dashboard/operations/measures": {
                tab = {...this.state.tabs[1]}
                break
            }
            case "/dashboard/operations/measures/list": {
                tab = {...this.state.tabs[1]}
                break
            }
            case "/dashboard/operations/events": {
                tab = {...this.state.tabs[2]}
                break
            }
            case "/dashboard/operations/devices": {
                tab = {...this.state.tabs[3]}
                break
            }
            default:
                tab = {...this.state.tabs[0]}
        }
        if(JSON.stringify(this.state.selectedTab) !== JSON.stringify(tab)){
            this.setState({
                selectedTab: tab
            })
        }
    }


    onFilterTap = () => {
        this.setState({areFiltersVisible: !this.state.areFiltersVisible})
    }


    onPeriodChange = (dates, isMidnightHour = true) => {
        if(dates.length === 0){
            this.setState({
                startDatePeriodTimestamp: undefined,
                endDatePeriodTimestamp: undefined
            })
        }else{
            const endDate = dates[1].getTime() + (isMidnightHour ? ((24 * 60 * 60 * 1000) - 1) : 0); // "Returns date at 00h00, so we had seconds to get same date at 23h59
            this.setState({
                interval: (endDate - dates[0]) > (1000 * 60 * 60 * 24 * 2) ? Interval.DAY : Interval.HOUR,
                startDatePeriodTimestamp: dates[0].getTime(),
                endDatePeriodTimestamp: endDate
            })
        }
    }

    onIntervalChanged = (interval) => {
        const currentInterval = this.state.interval
        if(interval !== currentInterval) {
            this.setState({interval: interval})
        }
    }

    onTabChange = (selectedTab) => {
        this.setState({selectedTab: selectedTab})
    }


    onSelectArea = (area, nodeId, nodePath) => {
        this.setState({selectedArea: area, selectedNodeId: nodeId, selectedAreaPath: nodePath})
    }

    onBreakpointChange = (breakpoint) => {
        this.setState({currentBreakpoint: breakpoint})
    }

    onModacFetched = async(modacs) => {
        await this.setState({modacs: modacs})

        // If a modac was selected (modal shown), update this one
        if(this.state.selectedModac !== undefined){
            const copyOfSelectedModac = {...this.state.selectedModac}
            const updatedModac = this.state.modacs.find(modac => modac.pk === copyOfSelectedModac.pk && modac.status === copyOfSelectedModac.status)
            if(updatedModac !== undefined){
                this.setState({selectedModac: updatedModac})
            }
        }
    }


    onSelectModac = (modacNode, nodeId, nodePath) => {
        if(window.location.pathname === "/dashboard/operations/supervision"){
            const modacMatchingWithModacNode = this.state.modacs.find(modac => modac.pk === nodeId && modac.status === modacNode.status)
            this.setState({selectedModac: modacMatchingWithModacNode !== undefined ? modacMatchingWithModacNode : modacNode})
        }else {
            this.onSelectArea(modacNode, nodeId, nodePath)
        }
    }

    onUnselectModac = () => {
        this.setState({selectedModac: undefined})
    }


    render(){
        return (
            <>
                {this.state.selectedModac === undefined ? undefined : <ModacOverview onCloseModacModal={this.onUnselectModac} modac={this.state.selectedModac}/>}

                <Tabs
                    startDatePeriodTimestamp={this.state.startDatePeriodTimestamp}
                    endDatePeriodTimestamp={this.state.endDatePeriodTimestamp}
                    onPeriodChange={this.onPeriodChange}
                    onIntervalChanged={this.onIntervalChanged}
                    interval={this.state.interval}
                    onSelect={this.onTabChange}
                    tabs={this.state.tabs}
                    selectedTab={this.state.selectedTab}
                    onFilterTap={this.onFilterTap}
                    displayOnlyDateShortcuts={window.location.pathname.startsWith("/dashboard/operations/supervision")}/>

                {window.location.pathname === "/dashboard/operations/measures/list" ?
                        <div className={classes?.DashboardTabSubMenu}>
                            <NavLink style={{padding: "5px", cursor: "pointer"}} to={"/dashboard/operations/measures"}>
                                <label><FontAwesomeIcon icon={faChevronCircleLeft} color={"#a4a4a4"} /> <Trans>Back</Trans></label>
                            </NavLink>
                            <h1><Trans>MeasuresList</Trans></h1>
                        </div>

                    : undefined
                }

                <div className={classes.DashboardContainer}>


                    <div className={classes.DashboardDevicesTreeContainer}>
                        <Tree autoExpandAllAreas={true} isAreaManagement={false} hideVisibleNodes={true} onFactoriesLoaded={this.onFactoriesLoaded} onModacFetched={this.onModacFetched} onSelectModac={this.onSelectModac} alreadySelectedNodeId={this.state.selectedNodeId} onSelect={this.onSelectArea} />
                    </div>

                    {this.state.selectedArea === undefined ? undefined : this.state.selectedArea.isAllowed === false ?
                        <label className={classes.DashboardAccessToPerimeterLabel}><Trans>NoAccessToPerimeter</Trans></label>
                        :

                        <Switch>
                            <Redirect exact from={"/dashboard/operations/"} to={"/dashboard/operations/supervision"}/>

                            <Route exact path={"/dashboard/operations/measures/list"} render={() =>
                                <MeasuresList
                                    startDate={this.state.startDatePeriodTimestamp}
                                    endDate={this.state.endDatePeriodTimestamp}
                                    factories={this.state.factories}
                                    selectedArea={this.state.selectedArea}
                                    selectedAreaPath={this.state.selectedAreaPath}

                                />
                            }/>

                            <Route exact path={"/dashboard/operations/events"} render={() =>
                                <DashboardEvents
                                    areFiltersVisible={this.state.areFiltersVisible}
                                    interval={this.state.interval}
                                    startDatePeriodTimestamp={this.state.startDatePeriodTimestamp}
                                    endDatePeriodTimestamp={this.state.endDatePeriodTimestamp}

                                    selectedArea={this.state.selectedArea}
                                    selectedAreaPath={this.state.selectedAreaPath}

                                />

                            }/>

                            <Route exact path={"/dashboard/operations/measures"} render={() =>
                                <DashboardMeasures
                                    selectedArea={this.state.selectedArea}
                                    selectedAreaPath={this.state.selectedAreaPath}
                                    interval={this.state.interval}
                                    startDatePeriodTimestamp={this.state.startDatePeriodTimestamp}
                                    endDatePeriodTimestamp={this.state.endDatePeriodTimestamp}/>
                            }/>

                            <Route exact path={"/dashboard/operations/supervision"} render={() =>
                                <DashboardSupervision
                                    modacs={this.state.modacs}
                                    factories={this.state.factories}
                                    selectedArea={this.state.selectedArea}
                                    selectedAreaPath={this.state.selectedAreaPath}
                                    interval={this.state.interval}
                                    startDatePeriodTimestamp={this.state.startDatePeriodTimestamp}
                                    endDatePeriodTimestamp={this.state.endDatePeriodTimestamp}/>

                            }/>


                            <Route exact path={"/dashboard/operations/devices"} render={() =>
                                <DashboardDevices
                                    interval={this.state.interval}
                                    startDatePeriodTimestamp={this.state.startDatePeriodTimestamp}
                                    endDatePeriodTimestamp={this.state.endDatePeriodTimestamp}

                                    selectedArea={this.state.selectedArea}
                                    selectedAreaPath={this.state.selectedAreaPath}
                                />
                            }/>

                        </Switch>
                    }
                    <ReactTooltip  effect="solid" />
                </div>
            </>
        )
    }
}

export default DashboardOperations;
