import React, {Component} from 'react';
import classes from './Login.module.css'
import {login} from '../helpers/AuthHelper'

import "react-loader-spinner/dist/loader/css/react-spinner-loader.css"
import Loader from 'react-loader-spinner'
import {getI18n, Trans} from "react-i18next";

import AppIcon from "../assets/img/nucleot_icon.svg"

class Login extends Component {

    state = {
        username: null,
        password: null,
        error: null,

        isJwtTokenLoaded: false,
        requestInProgress: false
    }

    componentDidMount(){
        this.handleSubmit = this.handleSubmit.bind(this);
    }


    handleSubmit = async(event) => {
        event.preventDefault()
        this.setState({requestInProgress: true}, async() => {
            const loginResult = await login(this.state.username, this.state.password)
            this.setState({requestInProgress: false}, async() => {
                if(!loginResult.success){
                    this.setState({ error : loginResult.error })
                }else {
                    this.setState({
                        isJwtTokenLoaded : true,
                        error: null
                    })
                    window.location.replace("/")
                }
            });
        })
    }

    handleUsernameChanged = (event) => {
        this.setState({username: event.target.value})
    }

    handlePasswordChanged = (event) => {
        this.setState({password: event.target.value})
    }


    render() {
        // Display in case of loading and during redirection
        const validationSection = (this.state.requestInProgress || this.state.isJwtTokenLoaded) ?
            (<div className={classes.LoginSpinnerContainer}>
                <div className={classes.LoginSpinnerContent}>
                    <Loader
                        type="Oval"
                        color="#FFFFFF"
                        height={30}
                        width={100}
                    />

                </div>
            </div>)
            :
            <input className={classes.LoginValidateButton} type={"submit"} value={getI18n().t("LOGIN")}/>

        return (
            <div className={classes.LoginContainer}>
                <div style={{textAlign: "center", marginBottom: "20px", height: "80px"}}>
                    <img src={AppIcon} alt={"App icon"} style={{height: "100%"}}/>
                </div>
                {/*<h1><Trans>NUCLEOT_LOGIN</Trans></h1>*/}
                <form onSubmit={this.handleSubmit} className={classes.LoginForm}>
                    <label><Trans>EMAIL</Trans></label>
                    <input type="email" onChange={this.handleUsernameChanged} required/>

                    <label><Trans>PASSWORD</Trans></label>
                    <input type="password" onChange={this.handlePasswordChanged} required/>

                    {this.state.error != null ? <p style={{color:"red"}}>{this.state.error}</p> : null}

                    {validationSection}
                </form>
            </div>
        );
    }
}

export default Login
