import i18n from "../../../i18n"

export const getDataset = (dataParam) => {
    if(dataParam !== undefined && dataParam.data !== undefined && dataParam.data.aggregations !== undefined && dataParam.data.aggregations.date !== undefined && dataParam.data.aggregations.date.buckets !== undefined){

        const buckets = dataParam.data.aggregations.date.buckets
        const dataset = {
            backgroundColor: "rgba(255,220,3,0.71)",
            borderColor: "rgb(255,222,3)",
            label: `${i18n.t("ControlsDuration")} (sec)`,
            data: [],
            categoryPercentage: 1.0,
            barPercentage: 0.90,
        }

        let dates = []
        buckets.forEach(it => {

            if(it.key_as_string !== undefined && it.average_duration !== undefined && it.average_duration.value !== undefined){
                const date = new Date(it.key_as_string)
                dates.push(date)

                dataset.data.push({
                    x: date,
                    y: Math.round((it.average_duration.value + Number.EPSILON) * 100) / 100

                })
            }
        })
        return {
            datasets: [dataset],
            dates: dates
        }
    }

    return {
        error: "invalid input to generate dataset"
    }
}
