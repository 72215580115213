import {config} from "../config";
import { postRequest } from "./Request";

export const AssociatedDevicesParameter = {
    NOT_ASSOCIATED: 0,
    ASSOCIATED: 1,
    ALL: 2
}

export const StatusDevicesParameter = {
    ACTIVE: "ACTIVE",
    INACTIVE: "INACTIVE",
    IN_STOCK: "IN_STOCK",
    ARCHIVE: "ARCHIVE"
}

export const LogtypeParameter = {
    REALTIME: "REALTIME",
    OFFLINE: "OFFLINE"
}



/***
 * Get all organization nodes details
 * @returns {Promise<any|{error: *}>} promise with error key or organization nodes object
 */
export const getOrganization = async() => {
    return await postRequest(config.API_URL + "/getorga")
}

/***
 * Get all organization types used to get node type information (picto, label, level...)
 * @param orgaTypeUUID organization type unique identifier used to bind nodeType parameter to its type.
 * Retrieved from root node of getorga endpoint
 * @returns {Promise<any|{error: *}>} promise with error key or an array of modacs
 */
export const getOrgaTypes = async(orgaTypeUUID) => {
    const payload = JSON.stringify({
        "orgtypeUUID": orgaTypeUUID
    })

    return await postRequest(config.API_URL + "/getorgtype", payload)
}

/***
 * Get all modacs
 * @returns {Promise<any|{error: *}>} promise with error key or an array of modacs
 */
export const getModacs = async() => {
    return await postRequest(config.API_URL + "/getmodacs")
}

/***
 * Get all mediaspots
 * @returns {Promise<any|{error: *}>} promise with error key or an array of mediaspots
 */
export const getMediaspots = async() => {
    return await postRequest(config.API_URL + "/getmediaspots")
}

/***
 * Get list of requested devices filtered with differents options in request
 * @param serial serial of device to filter (can be undefined to ignore)
 * @param reference reference of device to filter (can be undefined to ignore)
 * @param associated integer indicating if all devices have to be retrieved, or just associated, or just non-associated
 * @param types array of type string of types to get
 * (can be "NodeType.Mediaspot", "NodeType.Modac", "NodeType.Radiameter, NodeType.Probe"
 * @param clientUUID unique client identifier (retrieved from jwt token)
 * @returns {Promise<any|{error: *}>}
 */
export const getDevices = async(serial, reference, associated, types, clientUUID) => {
    const body = JSON.stringify({
        types: types,
        isDeployed: associated,
        serial: serial === undefined ? "" : serial,
        reference: reference === undefined ? "" : reference,
        clientUUID: clientUUID
    })
    return await postRequest(config.API_URL + "/getdevices", body)
}


/***
 * Create a new area node on organization with specific type, specific label on a specific node
 * @param label new node text label to describe this node
 * @param nodeType type of node (type is retrieved from getorgtype endpoint)
 * @param path node path where new node will be added as children
 * @param reserve boolean indicating if this new node
 * @param orgTypeUUID organization type unique identifier used to bind nodeType parameter to its type
 * @returns {Promise<any|{error: *}>}
 */
export const addNode = async(label, nodeType, path, reserve, orgTypeUUID) => {
    const body = JSON.stringify({
        label: label,
        type: nodeType,
        parentNode: path,
        store: reserve,
        orgtypeUUID: orgTypeUUID
    })

    return await postRequest(config.API_URL + "/addnode", body)
}

/***
 * Edit label or type of an area node
 * @param nodePath node path to edit ("xxxxxxxxxxxxx|yyyyyyyyyyyyyy|....)
 * @param label new node text label to describe this node
 * @param nodeType type of node (type is retrieved from getorgtype endpoint)
 * @param orgTypeUUID organization type unique identifier used to bind nodeType parameter to its type
 * @returns {Promise<any|{error: *}>} promise with error key or object response
 */
export const editNode = async(nodePath, label, nodeType, orgTypeUUID) => {
    const body = JSON.stringify({
        node: nodePath,
        label: label,
        type: nodeType,
        orgtypeUUID: orgTypeUUID
    })

    return await postRequest(config.API_URL + "/editnode", body)
}


/***
 * Associate device to another device
 * @param deviceId device primary key to associate
 * @param parentId device primary key of parent to associate device
 * @param clientUUID unique client identifier (retrieved from jwt token)
 * @returns {Promise<any|{error: *}>} promise with error key or object response
 */
export const associateDevice = async(deviceId, parentId, clientUUID) => {
    const body = JSON.stringify({
        device_pk: deviceId,
        parent_pk: parentId,
        clientUUID: clientUUID
    })

    return await postRequest(config.API_URL + "/associatedevice", body)
}

/***
 * Associate mediaspot to an area
 * @param mediaspotId primary key of mediaspot to associate
 * @param path node path to associate mediaspot
 * @param clientUUID unique client identifier (retrieved from jwt token)
 * @returns {Promise<any|{error: *}>} promise with error key or object response
 */
export const associateMediaspot = async(mediaspotId, path, clientUUID) => {
    const body = JSON.stringify({
        mediaspot_pk: mediaspotId,
        dest_node: path,
        clientUUID: clientUUID
    })

    return await postRequest(config.API_URL + "/associatemediaspot", body)
}

/***
 * Dissociate a device from its parent (node or device)
 * @param deviceId device primary key of device to dissociate
 * @param sourceNodePath node path of device to dissociate
 * @param targetNodePath node path of reserve to move device
 * @param clientUUID unique client identifier (retrieved from jwt token)
 * @returns {Promise<any|{error: *}>} returns promise with error key or object response
 */
export const dissociateDevice = async(deviceId, sourceNodePath, targetNodePath, clientUUID) => {
    const body = JSON.stringify({
        device_pk: deviceId,
        targetNode: targetNodePath,
        sourceNode: sourceNodePath,
        clientUUID: clientUUID
    })

    return await postRequest(config.API_URL + "/dissociatedevice", body)
}

/***
 * Delete a organization node
 * @param path node path to delete (this path should not contains child node or associated devices)
 * @param orgTypeUUID organization type unique identifier used to bind nodeType parameter to its type
 * @returns {Promise<any|{error: *}>} returns promise with error key or object response
 */
export const deleteNode = async(path, orgTypeUUID) => {
    const body = JSON.stringify({
        node: path,
        orgtypeUUID: orgTypeUUID
    })

    return await postRequest(config.API_URL + "/deletenode", body)
}

/***
 * Delete an inactive device
 * @param parentNodePath node path of device
 * @param deviceId device primary key of device to dissociate
 * @param clientUUID unique client identifier (retrieved from jwt token)
 * @returns {Promise<any|{error: *}>} returns promise with error key or object response
 */
export const deleteDevice = async(parentNodePath, deviceId, clientUUID) => {
    const body = JSON.stringify({
        parentNode: parentNodePath,
        device_pk: deviceId,
        clientUUID: clientUUID
    })

    return await postRequest(config.API_URL + "/deletedevice", body)
}

/***
 * Get list of requested devices filtered with differents options in request
 * @param serial serial of device to filter (can be undefined to ignore)
 * @param reference reference of device to filter (can be undefined to ignore)
 * @param associated integer indicating if all devices have to be retrieved, or just associated, or just non-associated
 * @param statuses array of String ("ACTIVE", "INACTIVE", "ARCHIVE", "IN_STOCK") representing device statuses
 * @param types array of type string of types to get
 * (can be "NodeType.Mediaspot", "NodeType.Modac", "NodeType.Radiameter, NodeType.Probe"
 * @param logTypes list of string representing logtypes to request (can be "OFFLINE", "REALTIME")
 * @param clientUUID unique client identifier (retrieved from jwt token)
 * @returns {Promise<any|{error: *}>}
 */
export const getInventory = async(serial, reference, associated, statuses, types, logTypes, clientUUID) => {
    const body = JSON.stringify({
        isDeployed: parseInt(associated),
        types: types,
        serial: serial === undefined ? "" : serial,
        reference: reference === undefined ? "" : reference,
        clientUUID: clientUUID,
        status: statuses,
        log_type: logTypes
    })
    return await postRequest(config.API_URL + "/getinventory", body)
}