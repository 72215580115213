import nucleotApi, {aviorDefaultElasticSearchHeader} from "../axios";
import {config} from "../../config";
import {adaptQuery} from "../../helpers/elasticSearchQueryHelper";

const modacsCountBody = (optionalStartDateTimestamp, optionalEndDateTimestamp, optionalDeviceSerial, nodePath) => {
    let body = {
        "aggs" : {
            "modacs" : {
                "terms" : { "field" : "modac_macaddr.keyword" }
            }
        },
        "size": 0,
        "query": {
            "bool": {
                "must": [
                    {
                        "match_phrase": {
                            "modac_rs485_status": {
                                "query": "1"
                            }
                        }
                    }
                ]
            }
        }
    }
    adaptQuery(body, undefined, optionalStartDateTimestamp, optionalEndDateTimestamp, nodePath, undefined, optionalDeviceSerial)

    return body;
}


export const getActiveModacsCount = async(optionalStartDateTimestamp, optionalEndDateTimestamp, optionalDeviceSerial, nodePath) => {
    if(nodePath === undefined){
        return {
            error: "node path has to be specified"
        }
    }
    
    try{
        const query = modacsCountBody(optionalStartDateTimestamp, optionalEndDateTimestamp, optionalDeviceSerial, nodePath)
        const response = await nucleotApi.post(config.API_URL + config.ELASTIC_SEARCH_ENDPOINT_PATH, query, {
            headers: aviorDefaultElasticSearchHeader
        })
        if(response.data !== undefined){
            return {
                error: undefined,
                data: response.data.aggregations.modacs.buckets.length
            }

        }
        return {
            error: new Error("no data in response")

        }

    } catch (e) {
        return {
            error: e
        }
    }
}
