import React, {Component} from "react";
import classes from "../Dashboard.module.css";
import Graph, {GraphType} from "../graphs/GraphComponent";


import {Responsive, WidthProvider} from "react-grid-layout";
import {Trans} from 'react-i18next'

import ModacsUpdatesTime from "./UpdatesTimeModac"
import BadRadiationTypes from "./BadRadiationTypes"
import BadAviorsAvailability from "./BadAviorsAvailability"
import AlarmsAndBadMeasures from "./AlarmsAndBadMeasures";
import {getPathString} from "../../helpers/FactoriesHelper";
import {NodeType} from "../../admin/admin_areas/NodeType";

const ResponsiveReactGridLayout = WidthProvider(Responsive);


class DashboardSupervision extends Component {

    constructor(props) {
        super(props)
        const originalLayouts = getFromLS("layouts") || {};

        this.state = {
            layouts: JSON.parse(JSON.stringify(originalLayouts)),
            measuresUnit: undefined
        }
    }


    onLayoutChange = (layout, layouts) => {
        saveToLS("layouts", layouts);
        this.setState({ layouts });
    }


    render() {
        let deviceSerial = undefined;
        if(this.props.selectedArea !== undefined && this.props.selectedArea.mediaspot !== undefined){
            deviceSerial = this.props.selectedArea.mediaspot.serial
        }

        return (
            <div style={{marginTop: "50px"}}>
                <ResponsiveReactGridLayout
                    rowHeight={100}
                    cols={{ lg: 12, md: 12, sm: 6, xs: 4, xxs: 2 }}
                    layouts={this.state.layouts}
                    onLayoutChange={this.onLayoutChange}
                    onBreakpointChange={this.onBreakpointChange}>

                    <div className={classes.ChartContainer} key={"repartition_graph"} data-grid={{ w: 12, h: 3, x: 0, y: 0, static: true }}>
                        <label><Trans>ControlsDurationRepartition</Trans> (%)</label>
                        <div className={classes.ChartTitleSeparator}/>
                        <div className={classes.ChartGraphContainer}>

                            <Graph graphType={GraphType.RADIOACTIVITY_REPARTITION}
                                   deviceSerial={deviceSerial}
                                   modacs={this.props.modacs}
                                   nodePath={this.props.selectedAreaPath}
                                   interval={this.props.interval}
                                   preventUpdateAnimations={true}
                                   startDatePeriodTimestamp={this.props.startDatePeriodTimestamp}
                                   endDatePeriodTimestamp={this.props.endDatePeriodTimestamp}
                                   modacMacAddr={(this.props.selectedArea !== undefined && this.props.selectedArea.levelType === NodeType.MODAC) ? this.props.selectedArea.id : undefined}/>
                        </div>
                    </div>

                    <div style={{overflow: "auto"}} className={classes.ChartContainer} key={"data_date_update"} data-grid={{ w: 6, h: 3, x: 0, y: 3, static: true }}>
                        <label><Trans>DataDateUpdate</Trans></label>
                        <label className={classes.DashboardDevicesSubtitle}>{getPathString(this.props.factories, this.props.selectedAreaPath, undefined, true, ">")}</label>
                        <div className={classes.DashboardDevicesTitleSeparator}/>

                        <div className={classes.DashboardDevicesGroupContent}>
                            {this.props.modacs !== undefined && this.props.factories !== undefined ? <ModacsUpdatesTime nodePath={this.props.selectedAreaPath} modacs={this.props.modacs} factories={this.props.factories} /> : undefined}
                        </div>
                    </div>


                    <div style={{overflow: "auto"}} className={classes.ChartContainer} key={"bad_channel_probes_array"} data-grid={{ w: 6, h: 3, x: 6, y: 3, static: true }}>
                        <label><Trans>ExpectedRadiationType</Trans></label>
                        <label className={classes.DashboardDevicesSubtitle}>{getPathString(this.props.factories, this.props.selectedAreaPath, undefined, true, ">")}</label>
                        <div className={classes.DashboardDevicesTitleSeparator}/>
                        <div className={classes.DashboardDevicesGroupContent}>
                            {this.props.modacs !== undefined && this.props.factories !== undefined ? <BadRadiationTypes nodePath={this.props.selectedAreaPath} factories={this.props.factories} modacs={this.props.modacs}/> : undefined}
                        </div>
                    </div>






                    <div style={{overflow: "auto"}} className={classes.ChartContainer} key={"average_measure_alarms_count_array"} data-grid={{ w: 6, h: 3, x: 0, y: 6, static: true }}>
                        <label><Trans>AverageMeasuresAndNumberOfAlarmsPerSas</Trans></label>
                        {/*<label className={classes.DashboardDevicesSubtitle}><span style={{color: "#FF9100"}}><Trans>MeasureGreatherThan30cs</Trans></span>&emsp;<span style={{color: "#FF0500"}}><Trans>MeasureGreatherThan70cs</Trans></span></label>*/}
                        <label className={classes.DashboardDevicesSubtitle}>{getPathString(this.props.factories, this.props.selectedAreaPath, undefined, true, ">")}</label>
                        <div className={classes.DashboardDevicesTitleSeparator}/>
                        <div className={classes.DashboardDevicesGroupContent}>
                            {this.props.modacs !== undefined && this.props.factories !== undefined ? <AlarmsAndBadMeasures startDatePeriodTimestamp={this.props.startDatePeriodTimestamp} endDatePeriodTimestamp={this.props.endDatePeriodTimestamp} nodePath={this.props.selectedAreaPath} factories={this.props.factories} modacs={this.props.modacs}/> : undefined}
                        </div>
                    </div>


                    <div style={{overflow: "auto"}} className={classes.ChartContainer} key={"availability_array"} data-grid={{ w: 6, h: 3, x: 6, y: 6, static: true }}>

                        <label><Trans>MeasureDeviceAvailabilities</Trans> (%)</label>
                        {/*<label className={classes.DashboardDevicesSubtitle}><span style={{color: "#FF9100"}}><Trans>Availability</Trans> &lt; 80% </span>&emsp;<span style={{color: "#FF0500"}}><Trans>Availability</Trans> &lt; 50%</span></label>*/}
                        <label className={classes.DashboardDevicesSubtitle}>{getPathString(this.props.factories, this.props.selectedAreaPath, undefined, true, ">")}</label>
                        <div className={classes.DashboardDevicesTitleSeparator}/>

                        <div className={classes.DashboardDevicesGroupContent}>
                            <BadAviorsAvailability
                                startDatePeriodTimestamp={this.props.startDatePeriodTimestamp}
                                endDatePeriodTimestamp={this.props.endDatePeriodTimestamp}
                                factories={this.props.factories}
                                nodePath={this.props.selectedAreaPath}/>
                        </div>
                    </div>

                </ResponsiveReactGridLayout>

            </div>
        )
    }
}

const saveToLS = (key, value) => {
    if (localStorage) {
        localStorage.setItem("rgl-supervision",
            JSON.stringify({
                [key]: value
            })
        );
    }
}

const getFromLS = (key) => {
    let ls = {};
    if (localStorage) {
        try {
            ls = JSON.parse(localStorage.getItem("rgl-supervision")) || {};
        } catch (e) {
            /*Ignore*/
        }
    }
    return ls[key];
}

export default DashboardSupervision
