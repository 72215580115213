import 'chartjs-plugin-zoom'
import timeGraphZoomDefaultProps from "./TimeGraphZoomDefaultProps";
import xAxeTimeDefaultProps from "./XAxeTimeDefaultProps";


export const graphProps = (dates, interval, startDate, endDate) => {
    let minDate = undefined;
    let maxDate = undefined;
    if(dates !== undefined && dates.length > 0 && dates[0].getTime() !== undefined){
        minDate = dates[0].getTime();
        maxDate = dates[dates.length-1].getTime();
    }

    return {
        type: 'line',
        data: {},
        labels: dates,
        options: {
            plugins:{
                zoom: false
            },
            responsive: true,
            maintainAspectRatio: false,
            legend: {
                display: false
            },
            pan: {
                enabled: true,
                mode: 'x',
            },
            zoom: timeGraphZoomDefaultProps(maxDate, interval),
            scales: {
                xAxes: [
                    xAxeTimeDefaultProps(minDate, maxDate, interval, startDate, endDate)
                ],
                yAxes: [{
                    ticks: {
                        max: 0,
                        min: -120,
                        stepSize: 20,
                    }
                }]
            }
        }
    }
}
