import React, {Component} from "react"
import Tree from "../../dashboard/tree/Tree";
import classes from "./AdminAreas.module.css"
import DeviceSearch from "./DeviceSearch"
import {Trans} from "react-i18next";
import Settings from "../../settings/Settings";

class AdminAreas extends Component {

    state = {
        isDragging: false,
        hideVisibleNodes: !Settings.shouldShowNoVisibleNodes(),
        hideStoreNodes: !Settings.shouldShowStoreNodes(),
        hideArchiveDevices: !Settings.shouldShowArchiveDevices(),

        deviceToFocus: undefined
    }

    componentDidMount() {
        document.addEventListener("dragstart", this.onDragStateUpdate)
        document.addEventListener("dragStart", this.onDragStateUpdate)
        document.addEventListener("dragend", this.onDragStateUpdate)
    }

    componentWillUnmount() {
        document.removeEventListener('dragstart', this.onDragStateUpdate);
        document.removeEventListener('dragend', this.onDragStateUpdate);
    }

    onDragStateUpdate = (event) => {
        console.log(event)
        this.setState({isDragging: event.type === "dragstart"})
    }

    onDevicesDissociated = (dissociatedNodes) => {
        this.setState({toDissociateNodes: dissociatedNodes})
    }

    onDissociationFinished = () => {
        this.setState({toDissociateNodes: undefined})
    }

    onShowDeviceRequest = async(deviceToFocus) => {
        if(this.state.timeoutId !== undefined){
            clearInterval(this.state.timeoutId)
        }
        const timeoutId = setTimeout(() => {
            this.setState({deviceToFocus: undefined})
        }, 2000)
        this.setState({deviceToFocus: deviceToFocus, timeoutId: timeoutId})

    }

    render(){
        return (
            <div>
                <div className={classes.AdminAreasContainer}>
                    <div className={classes.AdminAreasColumns}>
                        <div className={classes.AdminAreasTreeContainer} style={this.state.isDragging === true ? {border: "2px dashed black", padding: "6px"} : null}>
                            <div className={classes.AdminAreasColumnsHeader}>
                                <label className={classes.AdminAreasHeaderLabel}><Trans>Areas</Trans></label>
                                {/*<button className={classes.AdminAreasHideVisibleNodeButton} onClick={() => this.setState({hideVisibleNodes: !this.state.hideVisibleNodes})}><FontAwesomeIcon icon={this.state.hideVisibleNodes === true ? faEyeSlash : faEye} /> <Trans>ShowHideVisibleAreas</Trans></button>*/}
                            </div>

                            <div className={classes.AdminAreasTreeContent}>
                                <Tree focusedDevice={this.state.deviceToFocus} onDevicesDissociated={this.onDevicesDissociated} autoExpandAllAreas={true} hideArchiveDevices={this.state.hideArchiveDevices} hideVisibleNodes={this.state.hideVisibleNodes} hideStoreNodes={this.state.hideStoreNodes} enableEdition={true} isAreaManagement={true} onSelect={()=>{}} onSelectModac={()=>{}}/>
                            </div>
                        </div>

                        <div className={classes.AdminDevicesTreeContainer} style={this.state.isDragging === true ? {background: "rgba(0, 0, 0, 0.05"} : null}>
                            <div className={classes.AdminAreasColumnsHeader}>
                                <label className={classes.AdminAreasHeaderLabel}><Trans>Equipment</Trans></label>
                            </div>
                            <DeviceSearch onShowDeviceRequest={this.onShowDeviceRequest} toDissociateNodes={this.state.toDissociateNodes} onDissociationFinished={this.onDissociationFinished}/>
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}

export default AdminAreas;
