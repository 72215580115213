import React, {Component} from 'react';
import PropTypes from 'prop-types';
import Loader from 'react-loader-spinner'

import classes from "./Tree.module.css"
import TreeNode from './TreeNode';
import {
    AssociatedDevicesParameter,
    getDevices,
    getMediaspots,
    getModacs,
    getOrganization,
    getOrgaTypes
} from "../../requests/FactoriesRequests";
import {
    findPathOfParentKey,
    getAllFlatedNodes,
    getAllStoreNodes,
    getChildKeys,
    getVarPath
} from "../../helpers/FactoriesHelper";
import * as portalConfig from "../../portal_config"
import {NodeType} from "../../admin/admin_areas/NodeType";
import DissociationModal from "../../admin/admin_areas/DissociationModal";
import {Trans} from "react-i18next";
import {getClientUUID, isAdmin} from "../../helpers/AuthHelper";


export default class Tree extends Component {


    state = {
        orgaTypeUUID: undefined,
        types: undefined,

        factories: undefined,
        factoriesLoading: true,

        loadingError: undefined,

        storeNodes:[],

        // When a new node is adding via form, this field contains parent node where new node will be added
        newNodeParentId: undefined,

        editingNodeId: undefined,


        allDevices: [],
        dissociationNode: undefined,
        dissociationDevicePath: undefined,
        dissociationInProgress: false,

        deviceToBlink: undefined

    }

    static getTypeFromString = (level, label) => {
        if(level === undefined || label === undefined){
            return undefined
        }

        if(label === level.nodeType){
            return level
        }
        if(level.child !== undefined){
            return this.getTypeFromString(level.child, label)
        }
        return undefined;
    }

    async componentDidMount() {

        if(this.props.isAreaManagement === undefined || this.props.isAreaManagement === false){
            setInterval(this.reloadConfig, portalConfig.SUPERVISION_REFRESH_TICK_MILLISECONDS)
        }

        let responses 
        if(isAdmin() === true){
            responses = await Promise.all([
                getOrganization(),
                getDevices(undefined, undefined, AssociatedDevicesParameter.ALL,[NodeType.MEDIASPOT, NodeType.MODAC, NodeType.RADIAMETER, NodeType.PROBE], getClientUUID())
            ])    
        }else {
            responses = await Promise.all([
                getOrganization(),
                getMediaspots(),
                getModacs()
            ])
        }
        

        // Handle getOrga response

        const factoriesResponse = responses[0]
        if(factoriesResponse.error !== undefined){
            this.setState({
                factoriesLoading: false,
                loadingError: factoriesResponse.error
            })
            return
        }

        factoriesResponse[Object.keys(factoriesResponse)[0]]["isOpen"] = true
        let currentNode = factoriesResponse[Object.keys(factoriesResponse)[0]]
        let currentPath = Object.keys(factoriesResponse)[0]
        let currentNodeId = Object.keys(factoriesResponse)[0]
        // If root node is not allowed, searching from all child node first allowed node
        if(factoriesResponse[Object.keys(factoriesResponse)[0]]["isAllowed"] === false){

            while(currentNode !== undefined && currentNode["isAllowed"] === false){
                currentNodeId = getChildKeys(currentNode)[0]
                currentPath += ("|" + currentNodeId)

                currentNode = currentNode[currentNodeId]
                currentNode["isOpen"] = true
            }

        }

        // Handle orgtypes

        const orgTypeUUID = factoriesResponse[Object.keys(factoriesResponse)[0]]["orgTypeUUID"]
        const typesResponse = await getOrgaTypes(orgTypeUUID)

        if(typesResponse.error !== undefined){
            this.setState({
                factories: undefined, // In case of error, store factory now to don't reload it later, else store when all is loaded
                loadingError: typesResponse.error
            })
            return
        }

        if(this.props.autoExpandAllAreas === true){
            this.openChilds(factoriesResponse[Object.keys(factoriesResponse)[0]])
        }

        const storeNodes = getAllStoreNodes(factoriesResponse[Object.keys(factoriesResponse)[0]], Object.keys(factoriesResponse)[0])


        let modacsResponse;
        let mediaspotsResponse;
        let radiametersResponse;
        let probesResponse;

        if(isAdmin() === true){
            const devicesResponse = responses[1]
            if(devicesResponse.error !== undefined){
                this.setState({
                    factories: factoriesResponse, // In case of error, store factory now to don't reload it later, else store when all is loaded
                    loadingError: devicesResponse.error
                })
                return;
            }
            console.log(devicesResponse)
    
            modacsResponse = devicesResponse["modac"]["Items"]
            mediaspotsResponse = devicesResponse["mediaspot"]["Items"]
            radiametersResponse = devicesResponse["radiameter"]["Items"]
            probesResponse = devicesResponse["probe"]["Items"]
    
        }else {
            mediaspotsResponse = responses[1]
            modacsResponse = responses[2]
            if(mediaspotsResponse.error !== undefined){
                this.setState({
                    factories: factoriesResponse, // In case of error, store factory now to don't reload it later, else store when all is loaded
                    loadingError: mediaspotsResponse.error
                })
                return;
            }

            if(modacsResponse.error !== undefined){
                this.setState({
                    factories: factoriesResponse, // In case of error, store factory now to don't reload it later, else store when all is loaded
                    loadingError: modacsResponse.error
                })
                return;
            }

            
        }
        
        const allDevices = [...modacsResponse, ...mediaspotsResponse, radiametersResponse ? {...radiametersResponse} : undefined, probesResponse !== undefined ? {...probesResponse} : undefined]

        

        if(this.props.onModacFetched !== undefined){
            this.props.onModacFetched(modacsResponse)
        }

        if(this.props.isAreaManagement === false){
            for(let i = 0 ; i < modacsResponse.length ; i++){
                let modacPath = modacsResponse[i].gsi1sk
                const originalObject = getVarPath(factoriesResponse, modacPath)

                if(originalObject["modacs"] === undefined){
                    originalObject["modacs"] = [];
                }

                let existingModacInTree = originalObject["modacs"].find(it => it.macaddr === modacsResponse[i].macaddr)
                if(existingModacInTree !== undefined){
                    existingModacInTree = modacsResponse[i]
                }else {
                    originalObject["modacs"].push(modacsResponse[i])
                }
            }
            for(let i = 0 ; i < mediaspotsResponse.length ; i++){
                let modacPath = mediaspotsResponse[i].gsi1sk
                const originalObject = getVarPath(factoriesResponse, modacPath)
                originalObject["mediaspot"] = mediaspotsResponse[i]
            }
        }else {
            // If area management
            // Loop over all mediaspot from response add add them to current tree
            for(let i = 0 ; i < mediaspotsResponse.length ; i++){
                let modacPath = mediaspotsResponse[i].gsi1sk
                const originalObject = getVarPath(factoriesResponse, modacPath)
                if(originalObject.isAllowed === true){
                    originalObject["mediaspot"] = mediaspotsResponse[i]
                    const mediaspotKey = mediaspotsResponse[i].pk.replace("|", "_@_")

                    originalObject[mediaspotKey] = {
                        type: NodeType.MEDIASPOT,
                        val: mediaspotsResponse[i].reference,
                        id: mediaspotsResponse[i].pk,
                        nodePath: mediaspotsResponse[i].gsi1sk,
                        isArchiveDevice: mediaspotsResponse[i].status === "ARCHIVE"
                    }
                }
            }

            this.addDeviceToParent(modacsResponse, NodeType.MODAC, factoriesResponse)
            this.addDeviceToParent(radiametersResponse, NodeType.RADIAMETER, factoriesResponse)
            this.addDeviceToParent(probesResponse, NodeType.PROBE, factoriesResponse)
        }

        this.setState({allDevices: allDevices, orgaTypeUUID: orgTypeUUID, types: typesResponse.org, factories: factoriesResponse, loadingError: undefined, storeNodes: storeNodes}, () => {
            this.onNodeSelect(currentNode, currentNodeId, currentPath)
            if(this.props.onFactoriesLoaded !== undefined){
                this.props.onFactoriesLoaded({...this.state.factories})
            }
        })

    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        // If new device to focus
        if(prevProps.focusedDevice !== this.props.focusedDevice && this.props.focusedDevice !== undefined){
           this.showDevice(this.props.focusedDevice.gsi1sk, this.props.focusedDevice.pk)
        }

        if(prevProps.focusedDevice !== undefined && this.props.focusedDevice === undefined){
            this.setState({deviceToBlink: undefined})
        }
    }

    addDeviceToParent = (devicesToAdd, deviceType, factories) => {
        // Loop over all modac from response
        for(let i = 0 ; i < devicesToAdd.length ; i++){
            let parentNode = undefined;
            // get path of node where modac is binded
            let nodePath = devicesToAdd[i].gsi1sk
            // get node object matching with modac node path
            let originalObject = getVarPath(factories, nodePath)

            // In case of in stock device, any device is associated, so they'll be added direcly in stock node
            if(devicesToAdd[i].status === "IN_STOCK"){
                parentNode = getVarPath(factories, nodePath)
            }else{
                // If parent device is not found is tree, continue
                if(devicesToAdd[i].parent_key === undefined){
                    continue;
                }
                const parentPath = findPathOfParentKey(originalObject, nodePath, devicesToAdd[i].parent_key.replace("|", "_@_"))
                if(parentPath === undefined){
                    continue
                }
                parentNode = getVarPath(factories, parentPath)
            }

            parentNode[devicesToAdd[i].pk.replace("|", "_@_")] = {
                type: deviceType,
                val: devicesToAdd[i].reference,
                id: devicesToAdd[i].pk,
                nodePath: devicesToAdd[i].gsi1sk,
                isArchiveDevice: devicesToAdd[i].status === "ARCHIVE",
                maxNbProbes: devicesToAdd[i].maxNbProbes
            }
        }
    }

    showDevice = (nodePath, deviceId) => {
        console.log(nodePath)
        console.log(deviceId)

        const pathComponents = nodePath.split("|")
        let currentPath = pathComponents[0]
        // Open all nodes of area path node by node
        for(let i = 1 ; i <= pathComponents.length ; i++){
            const node = getVarPath(this.state.factories, currentPath)
            node["isOpen"] = true
            currentPath += `|${pathComponents[i]}`
        }

        // Get final destination node (area)
        let originalObject = getVarPath(this.state.factories, nodePath)

        let deviceToFind = this.state.allDevices.find(device => device.pk === deviceId)

        if(deviceToFind === undefined || deviceToFind.parent_key === undefined){
            this.setState({deviceToBlink: deviceToFind})
            return
        }

        let deviceIdToFound = deviceToFind.parent_key.replace("|", "_@_")
        let parentPath = findPathOfParentKey(originalObject, nodePath, deviceIdToFound)

        let currentDevice = {...deviceToFind}
        while(parentPath !== undefined ){
            let object = getVarPath(this.state.factories, parentPath)
            object["isOpen"] = true

            currentDevice = this.state.allDevices.find(device => device.pk === object.id)
            if(currentDevice !== undefined && currentDevice.parent_key !== undefined){
                deviceIdToFound = currentDevice.parent_key.replace("|", "_@_")
                parentPath = findPathOfParentKey(originalObject, nodePath, deviceIdToFound)
            }else {
                parentPath = undefined
            }
        }
        this.setState({deviceToBlink: deviceToFind})
    }

    openChilds = (currentNode) => {
        const childKeys = getChildKeys(currentNode)
        childKeys.forEach(it => {
            if(currentNode[it].hasMediaspot !== true){
                currentNode[it]["isOpen"] = true
                this.openChilds(currentNode[it])

            }
        })
    }

    reloadConfig = async() => {
        const response = await Promise.all([
            this.loadMediaspots(),
            this.loadModacs()
        ]);
        this.forceUpdate()

        if(this.state.loadingError !== undefined && response[0] !== undefined && response[0].error === undefined && response[1].error === undefined){
            this.setState({loadingError: undefined})
        }
    }



    loadModacs = async() => {
        const modacsResponse = await getModacs()

        if(modacsResponse.error !== undefined){
            return {
                error: modacsResponse.error
            }
        }

        if(this.props.onModacFetched !== undefined){
            this.props.onModacFetched(modacsResponse)
        }

        for(let i = 0 ; i < modacsResponse.length ; i++){
            let modacPath = modacsResponse[i].gsi1sk
            const originalObject = getVarPath(this.state.factories, modacPath)

            if(originalObject["modacs"] === undefined && originalObject !== ''){
                originalObject["modacs"] = [];
            }

            if(originalObject["modacs"] === undefined){
                return
            }

            let existingModacInTree = originalObject["modacs"].find(it => it.macaddr === modacsResponse[i].macaddr)
            if(existingModacInTree !== undefined){
                Object.assign(existingModacInTree, modacsResponse[i])
            }else {
                originalObject["modacs"].push(modacsResponse[i])
            }
        }
    }

    loadMediaspots = async() => {
        const mediaspotsResponse = await getMediaspots()
        if(mediaspotsResponse.error !== undefined){
            return {
                error: mediaspotsResponse.error
            }
        }

        for(let i = 0 ; i < mediaspotsResponse.length ; i++){
            let path = mediaspotsResponse[i].gsi1sk
            const originalObject = getVarPath(this.state.factories, path)

            if(originalObject["mediaspot"] !== undefined){
                Object.assign(originalObject["mediaspot"], mediaspotsResponse[i])
            }else if(originalObject !== ''){
                originalObject["mediaspot"] = mediaspotsResponse[i]
            }
        }
    }

    getChildNodes = (node) => {
        let nodes = getChildKeys(node).map(nodeKey => {
            return {id: nodeKey, node: node[nodeKey]}
        })

        // If node are not already created, it'll be added dynamilly here
        if(this.props.isAreaManagement === false){
            if(node.mediaspot !== undefined && (this.props.isAreaManagement === true)){
                nodes.push({
                    id: node.mediaspot.pk,
                    node:{
                        type: "mediaspot",
                        val: node.mediaspot.serial,
                    }
                })

            }

            if(node.modacs !== undefined && node.modacs.length > 0){
                node.modacs.forEach(modacInfo => {

                    nodes.push({
                        id: modacInfo.pk,
                        node: {
                            type: "modac",
                            val: modacInfo.reference,
                            ...modacInfo

                        }
                    })
                })
            }
        }

        //Remove node of type reserve if preventState
        if(this.props.isAreaManagement !== true){
            nodes = nodes.filter(it => {
                if(it.node !== undefined){
                    return it.node.isStore !== true
                }
                return true
            })
        }

        return nodes.sort((nodeA, nodeB) => {
            if(nodeA.node.type === NodeType.MEDIASPOT && nodeB.node.type !== NodeType.MEDIASPOT){ return -1 }
            if(nodeB.node.type === NodeType.MEDIASPOT && nodeA.node.type !== NodeType.MEDIASPOT){ return 1 }
            if(nodeA.node.isStore === true && nodeB.node.isStore !== true){ return -1 }
            if(nodeB.node.isStore === true && nodeA.node.isStore !== true){ return 1 }
            if(nodeB.node.isStore === true && nodeA.node.isStore !== true){ return 1 }

            if(nodeA.node.type === NodeType.MODAC && nodeB.node.type === NodeType.MODAC && this.isGoodMediaspotConfigured(nodeA.node) === false){return -1}
            if(nodeA.node.type === NodeType.MODAC && nodeB.node.type === NodeType.MODAC && this.isGoodMediaspotConfigured(nodeA.node) === true){return 1}

            return nodeA.node.val > nodeB.node.val ? 1 : -1
        })
    }

    isGoodMediaspotConfigured = (node) => {
        console.log(node)
        if(node.detectedMediaspotSerial !== undefined && node.parent_key !== undefined && node.parent_key.includes("mediaspot-")){
            const extractedSerial = node.parent_key.replace("mediaspot-", "").split("|")[0]
            if(extractedSerial !== node.detectedMediaspotSerial){
                console.log("bad config detected")
                return false
            }
        }
        return true
    }

    onToggle = (node) => {
        if(node["isOpen"] === undefined){
            node["isOpen"] = true
        }else {
            node["isOpen"] = !node["isOpen"]
        }
    }

    onNodeSelect = (node, nodeId, nodePath) => {
        const { onSelect } = this.props;
        onSelect(node, nodeId, nodePath);
    }

    onNewNodeCreated = (id, name, levelType, path, isStore) => {
        const originalObject = getVarPath(this.state.factories, path)
        originalObject[id] = {
            hasMediaspot: false,
            isAllowed: true,
            isOpen: false,
            type: levelType.nodeType,
            val: name,
            isStore: isStore
        }

        this.setState({newNodeParentId: undefined})
    }

    onNodeAddingCancelled = () => {
        this.setState({newNodeParentId: undefined})
    }

    onNodeEditionCancelled = () => {
        this.setState({editingNodeId: undefined})
    }

    onDeleteNode = (nodeId, path) => {
        const parentPathComponents = path.split('|');
        if(parentPathComponents.length > 1){
            const toRemoved = parentPathComponents.pop()
            if(toRemoved === nodeId){
                const parentPath = parentPathComponents.reduce((it, val) => it + "|" + val)
                const originalObject = getVarPath(this.state.factories, parentPath)
                delete originalObject[nodeId]

                this.forceUpdate()
            }
        }
    }

    onDeleteDevice = (deviceId, path) =>{
        const parentPathComponents = path.split('|');
        if(parentPathComponents.length > 1){
            const toRemovedDeviceId = parentPathComponents.pop()
            if(toRemovedDeviceId === deviceId){
                const parentPath = parentPathComponents.reduce((it, val) => it + "|" + val)
                const originalObject = getVarPath(this.state.factories, parentPath)
                delete originalObject[deviceId]

                this.forceUpdate()
            }
        }
    }


    onNodeEdited = (path, label, type) => {
        const originalObject = getVarPath(this.state.factories, path)
        originalObject["val"] = label
        originalObject["type"] = type.nodeType

        this.setState({editingNodeId: undefined})
    }

    onDeviceDropped = (path, fullObject, nodeType, nodePath) => {
        const originalObject = getVarPath(this.state.factories, path)
        originalObject["isOpen"] = true
        if(nodeType === NodeType.MEDIASPOT){
            originalObject["hasMediaspot"] = true
        }
        const key = fullObject.pk.replace("|", "_@_")
        originalObject[key] = {
            type: nodeType,
            val: fullObject.reference,
            id: fullObject.pk,
            nodePath: nodePath,
            maxNbProbes: fullObject.maxNbProbes
        }

        const storeNodePath = fullObject.gsi1sk
        const storeNodeOriginalObject = getVarPath(this.state.factories, storeNodePath)

        if(storeNodeOriginalObject !== undefined){
            delete storeNodeOriginalObject[key]
        }
        this.forceUpdate()
    }

    onDissociateRequest = (node, path) => {
        this.setState({dissociationInProgress: true, dissociationNode: node, dissociationDevicePath: path })
    }

    onDissociationModalClosed = () => {
        this.setState({dissociationInProgress: false, dissociationNode: undefined, dissociationDevicePath: undefined })
    }

    onDissociationSuccess = (storeNodeDestinationPath) => {

        let nodePathComponents = this.state.dissociationDevicePath.split("|")
        const nodeIdToDelete = nodePathComponents.pop()
        let parentNodePath = nodePathComponents.reduce((it1, it2) => `${it1}|${it2}`)

        const originalObject = getVarPath(this.state.factories, parentNodePath)

        // If deleted object is a mediaspot, parent node "hasMediaspot" attribute has to be reset to false
        const objectToDelete = getVarPath(this.state.factories, this.state.dissociationDevicePath)

        if(objectToDelete.type === NodeType.MEDIASPOT){
            originalObject["hasMediaspot"] = false
        }

        // Make a copy of object to delete
        const copyOfDeletedObject = {...originalObject[nodeIdToDelete]}
        delete originalObject[nodeIdToDelete]

        // Get store node destination object
        const storeNodeDestination = getVarPath(this.state.factories, storeNodeDestinationPath)

        // Get all nodes and (sub-nodes) of deleted node to notify ids after path edition
        let flatNodes = getAllFlatedNodes(copyOfDeletedObject, true)

        // Get all keys child to keep other keys
        //storeNodeDestination[nodeIdToDelete] = copyOfDeletedObject
        flatNodes.forEach(node => {
            node.nodePath = storeNodeDestinationPath
            storeNodeDestination[node.id.replace("|", "_@_")] = node
        })
        getChildKeys(copyOfDeletedObject).forEach(keyToDelete => delete copyOfDeletedObject[keyToDelete])

        let originalNodeToDelete = {...copyOfDeletedObject}
        const keysToDelete = getChildKeys(originalNodeToDelete)
        keysToDelete.forEach(keyToDelete => {
            delete originalNodeToDelete[keyToDelete]
        })

        let devicesToDissociated = flatNodes.map(node => {
            return this.state.allDevices.find(it => node.id === it.pk && it.status !== "ARCHIVE")
        }).map(devices => {
            // After device dissociation, its original object has to be updated (status, path with store path and its deployment status)
            devices.gsi1sk = storeNodeDestinationPath
            devices.status = "IN_STOCK"
            devices.isDeployed = false
            return devices
        })

        this.props.onDevicesDissociated(devicesToDissociated)

        this.setState({dissociationInProgress: false, dissociationNode: undefined, dissociationDevicePath: undefined})
    }


    render() {
        if(this.state.factories === undefined && this.state.loadingError === undefined){
            return <div className={classes.TreeLoadingContainer}>
                <Loader type="Oval" color="#24A6E1" height={50} width={50}/>
            </div>
        }

        if(this.state.loadingError !== undefined){
            return <div>
                <label id={"tree_loading_error_label"}><Trans>AnErrorOccurredPleaseReload</Trans></label>
            </div>
        }

        return Object.keys(this.state.factories).map(nodeKey => {
            return {
                id: nodeKey,
                val: this.state.factories[nodeKey]
            }
        }).map(node => {
            return (
                <div key={node.id}>
                    <TreeNode
                        key={node.id}
                        alreadySelectedNodeId={this.props.alreadySelectedNodeId}
                        onSelectModac={this.props.onSelectModac}
                        levelIndex={0}
                        levelType={NodeType.AREA}
                        node={node.val}
                        getChildNodes={this.getChildNodes}
                        onToggle={this.onToggle}
                        onNodeSelect={this.onNodeSelect}
                        nodeId={node.id}
                        path={node.id}
                        isAreaManagement={this.props.isAreaManagement}
                        enableEdition={this.props.enableEdition}
                        onAddClick={(parentNodeId) => { this.setState({newNodeParentId: parentNodeId})}}
                        onEditClick={(nodeId) => {this.setState({editingNodeId: nodeId})}}
                        newNodeAdding={this.state.newNodeParentId}
                        editingNodeId={this.state.editingNodeId}
                        isEditing={this.state.editingNodeId !== undefined && node.id === this.state.editingNodeId}

                        onNewNodeCreated={this.onNewNodeCreated}
                        onNodeAddingCancelled={this.onNodeAddingCancelled}
                        onNodeEdited={this.onNodeEdited}
                        onNodeEditionCancelled={this.onNodeEditionCancelled}
                        onNodeDeleted={this.onDeleteNode}

                        hideVisibleNodes={this.props.hideVisibleNodes}
                        hideStoreNodes={this.props.hideStoreNodes}
                        hideArchiveDevices={this.props.hideArchiveDevices}
                        types={this.state.types}

                        onMediaspotDropped={(path, fullObject, nodePath) => this.onDeviceDropped(path, fullObject, NodeType.MEDIASPOT, nodePath)}
                        onModacDropped={(path, fullObject, nodePath) => this.onDeviceDropped(path, fullObject, NodeType.MODAC, nodePath)}
                        onRadiameterDropped={(path, fullObject, nodePath) => this.onDeviceDropped(path, fullObject, NodeType.RADIAMETER, nodePath)}
                        onProbeDropped={(path, fullObject, nodePath) => this.onDeviceDropped(path, fullObject, NodeType.PROBE, nodePath)}

                        onDissociateDevice={this.onDissociateRequest}
                        orgTypeUUID={this.state.orgaTypeUUID}

                        deviceToBlink={this.state.deviceToBlink}

                    />

                    {this.state.dissociationInProgress === true ? <DissociationModal onDissociationSuccess={this.onDissociationSuccess} dissociationNode={this.state.dissociationNode} storeNodes={this.state.storeNodes} onDismissModal={this.onDissociationModalClosed}/> : undefined}


                </div>
            )
        })
    }
}

Tree.propTypes = {
    onSelect: PropTypes.func.isRequired,
    onSelectModac: PropTypes.func.isRequired,
    alreadySelectedNodeId: PropTypes.any,
    maxLevel: PropTypes.any,

    onFactoriesLoaded: PropTypes.func,
    onModacFetched: PropTypes.func,

    isAreaManagement: PropTypes.bool,
    enableEdition: PropTypes.bool,

    hideVisibleNodes: PropTypes.bool,
    hideStoreNodes: PropTypes.bool,

    autoExpandAllAreas: PropTypes.bool,
    onDevicesDissociated: PropTypes.func,

    focusedDevice: PropTypes.any

};
