import timeGraphZoomDefaultProps from "./TimeGraphZoomDefaultProps";
import xAxeTimeDefaultProps from "./XAxeTimeDefaultProps";

export const graphProps = (dates, interval, startDatePeriodTimestamp, endDatePeriodTimestamp) => {
    let minDate = undefined;
    let maxDate = undefined;
    if(dates !== undefined && dates.length > 0 && dates[0].getTime() !== undefined){
        minDate = dates[0].getTime();
        maxDate = dates[dates.length-1].getTime();
    }

    return {
        type: 'bar',
        data: {},
        labels: dates,
        options: {
            plugins:{
                zoom: false
            },
            responsive: true,
            maintainAspectRatio: false,
            legend: {
                display: false
            },
            pan: {
                enabled: true,
                mode: 'x',
            },
            zoom: timeGraphZoomDefaultProps(maxDate, interval),
            scales: {

                xAxes: [
                    xAxeTimeDefaultProps(minDate, maxDate, interval, startDatePeriodTimestamp, endDatePeriodTimestamp),
                ],
                yAxes: [{
                    ticks: {
                        max: 180, // 3min
                        min: 0,
                        stepSize: 30,
                    }
                }]
            }
        }
    }
}
