import nucleotApi, {aviorDefaultElasticSearchHeader} from "../axios";
import {config} from "../../config";
import {adaptQuery} from "../../helpers/elasticSearchQueryHelper";

const evolutionOfHitsBody = (interval, optionalStartDateTimestamp, optionalEndDateTimestamp, modacMacAddr, optionalDeviceSerial, nodePath) => {
    let body = {
        "aggs": {
            "date": {
                "date_histogram": {
                    "field": "logdate",
                    "interval": "1d",
                    "time_zone": "Europe/Berlin",
                    "min_doc_count": 0
                },
                "aggs": {
                    "services": {
                        "terms": {
                            "field": "service.keyword",
                            "min_doc_count": 0
                        }
                    }
                }
            }
        },
        "size": 0,
        "query": {
            "bool": {
                "must": [],
                "filter": [],
                "should": [],
                "must_not": []
            }
        }
    }

    adaptQuery(body, interval, optionalStartDateTimestamp, optionalEndDateTimestamp, nodePath, modacMacAddr, optionalDeviceSerial)

    return body;
}

export const getEvolutionOfHits = async(interval, optionalStartDateTimestamp, optionalEndDateTimestamp, modacMacAddr, optionalDeviceSerial, nodePath) => {
    try{
        const query = evolutionOfHitsBody(interval, optionalStartDateTimestamp, optionalEndDateTimestamp, modacMacAddr, optionalDeviceSerial, nodePath)
        const response = await nucleotApi.post(config.API_URL + config.ELASTIC_SEARCH_ENDPOINT_PATH, query, {
            headers: aviorDefaultElasticSearchHeader
        })
        return response.data
    } catch (e) {
        return {
            error: e
        }
    }
}
