import nucleotApi, {aviorDefaultElasticSearchHeader} from "../axios";
import {config} from "../../config";
import {adaptQuery} from "../../helpers/elasticSearchQueryHelper";

const alarmsPercentBody = (optionalStartDateTimestamp, optionalEndDateTimestamp, optionalModacMacAddr, optionalDeviceSerial, nodePath) => {
    let body = {
        "aggs": {
            "alarms": {
                "terms": {
                    "field": "p1_ch1_alarm.keyword",
                    "order": {
                        "_count": "desc"
                    }
                }
            }
        },
        "size": 0,
        "query": {
            "bool": {
                "must": [
                    // { "match_phrase": { "p1_ch1_trans": { "query": "BETA" } } }
                ]
            }
        }
    }
    adaptQuery(body, undefined, optionalStartDateTimestamp, optionalEndDateTimestamp, nodePath, optionalModacMacAddr, optionalDeviceSerial)

    return body;
}

export const getAlarmsPercent = async(optionalStartDateTimestamp, optionalEndDateTimestamp, optionalClientKey, optionalMediaspotName, optionalModacMacAddr, optionalDeviceSerial, nodePath) => {
    if(optionalMediaspotName !== undefined && optionalClientKey === undefined){
        return {
            error: "mediaspot name can only be specified if clientkey is specified"
        }
    }
    if(nodePath === undefined){
        return {
            error: "node path has to be specified"
        }
    }
    try{
        const query = alarmsPercentBody(optionalStartDateTimestamp, optionalEndDateTimestamp, optionalClientKey, optionalMediaspotName, optionalModacMacAddr, optionalDeviceSerial, nodePath)
        const response = await nucleotApi.post(config.API_URL + config.ELASTIC_SEARCH_ENDPOINT_PATH, query, {
            headers: aviorDefaultElasticSearchHeader
        })

        if(response.data !== undefined && response.data.hits !== undefined && response.data.hits.total !== undefined && response.data.hits.total.value !== undefined && response.data.hits.total.value > 0){
            const numberOfControls = response.data.hits.total.value
            const noAlarmObj = response.data.aggregations.alarms.buckets.find(it => { return it.key === "NONE"})
            if(noAlarmObj === undefined){
                return {
                    error: "Unexpected response"
                }
            }
            const numberOfControlsWithoutAlarm = noAlarmObj.doc_count
            const numberOfControlsWithAlarm = numberOfControls - numberOfControlsWithoutAlarm
            return {
                error: undefined,
                data: (numberOfControlsWithAlarm * 100) / numberOfControls
            }

        }
        return {
            error: new Error("no data in response")

        }

    } catch (e) {
        return {
            error: e
        }
    }
}
